import { Link } from "react-router-dom";
import "./Image.scss";

const ImageTemplate = ({ image, style, className, link }) => {
  const CustomImage = () => {
    return (
      <img
        loading="lazy"
        src={image?.name ?? ""}
        alt={image?.alt ?? ""}
        style={{ ...style, objectFit: "cover" }}
        className={`image-template center ${className}`}
      />
    );
  };
  
  return (
    <div className="h-100">
      {link ? (
        <Link to={link}>
          <CustomImage />
        </Link>
      ) : (
        <CustomImage />
      )}
    </div>
  );
};

export default ImageTemplate;
