import { Button, Col, Row } from "react-bootstrap";
import ImageCard2 from "../../../components/image/ImageCard2";
import Atag from "../../../components/misc/Atag";
import "./ProductComponents.scss";

const ProductTypes = ({ product, type, systemDescription, setData }) => {
  return (
    <>
      <div className="product-component">
        <div className="sidebar-section">
          <div className="sidebar-section-box" />
          <div className="sidebar-section-content">
            <div className="heading-2 fw-bold header">{type[0].toUpperCase() + type.substr(1)}</div>
            <div className="">{systemDescription}</div>
            <Col className="pt-5">
              {/* <Row className="m-0">
                <Button
                  className="d-flex justify-content-between align-items-center"
                  style={{ borderRadius: "0", border: "none", background: "#3C342C" }}
                >
                  View All
                  <i className="fa fa-arrow-right" />
                </Button>
              </Row> */}
              <Row className="m-0">
                <Button
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    borderRadius: "5px",
                    border: "none",
                    textDecoration: "none",
                    borderBottom: "none",
                    background: "#22201d",
                    color: "white",
                  }}
                >
                  Configure Yours
                  <i className="fa fa-arrow-right" />
                </Button>
                <hr />
              </Row>
              <Row className="m-0"></Row>
            </Col>
          </div>
        </div>
        <div className="products-section products-display w-100">
          {product.components[type]
            ? product.components[type].map((component) => (
              <div
                key={component.id}
                className="product-image"
                onClick={() => setData(component)}
              >
                <Atag href={`${type}/${component.heading}`}>
                  <ImageCard2
                    heading={component.heading}
                    subHeading={component.subHeading}
                    details={component.details}
                    image={component?.images[0] ?? {}}
                  />
                </Atag>
              </div>
            ))
            : null}
        </div>
      </div>
    </>
  );
};

export default ProductTypes;
