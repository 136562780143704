import { Col } from "react-bootstrap";
import estimateImage from "../../../images/Glydea-white-2.jpeg";
import "./DesignSpecifying.scss";

const DesginSpecify = () => {
  return (
    <>
      <div className="parent-estimate">
        {/* Hero */}
        <div className="hero-section">
          <div>
            <p id="estimate-title">DESIGN / SPECIFYING</p>
            <div className="estimate-description">
              <p>
                Blineco founder, Sam Kodsi, has an engineering and interior design background, with
                this in mind Form and Function work together in harmony in the advice we provide.
              </p>
              <p>
                We can professionally and effectively collaborate with designers to provide the
                right solutions for their clients projects.
              </p>
            </div>
          </div>
          <div>
            <img
              src={estimateImage}
              style={{ height: "35em", width: "40em", objectFit: "cover" }}
              alt="bineco"
            ></img>
          </div>
        </div>

        {/* description col */}
        <div className="ds-description-col d-flex">
          <Col>
            <p className="estimate-description">
              <span className="fw-bold">With over 20 years of experience,</span> we have the
              confidence and knowledge to provide a solution. We take pride in the advice and work
              we offer; and want to ensure that what we deliver meets both “Form and Function".
            </p>
          </Col>
          <Col>
            <p className="estimate-description">
              We care about function but want to ensure that aesthetics is front of mind. We want
              our products to last and look great for a long time.
            </p>
          </Col>
        </div>

        {/* meet our configurator */}
        <Col>
          <div className="col-md">
            <p id="ds-configurator">Meet our configurator</p>
            <p id="op-1" className="col-5 estimate-description mb-5">
              Designers are welcome and encouraged to use our configurator, it is designed to
              provide a visual and technical solution.
            </p>
            <a href="/" id="pg-bttn">
              Configure
            </a>
          </div>
          <div>
            <img
              src={estimateImage}
              style={{
                height: "50em",
                width: "100%",
                objectFit: "cover",
                marginTop: "5em",
              }}
              alt="blineco"
            ></img>
          </div>
        </Col>

        <div className="ds-description-col d-flex">
          <Col className="estimate-description">
            <p>
              It shows the product chosen and explains its function and technical characteristics.
              Using the Blineco configurator, the system will provide specific information about the
              product that a designer requires to confidentially specify a product.
            </p>
          </Col>
          <Col className="estimate-description">
            <p>
              Designers who use our configurator and register with us will be provided with further
              support and the latest information about all our products.
            </p>
            <p>
              The registration process means you can be provided with an online portal that provides
              the most up to date information about our products.
            </p>
          </Col>
        </div>
      </div>
    </>
  );
};

export default DesginSpecify;
