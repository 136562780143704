import "./Onboarding.scss";

const ServicesComponents = () => {
  return (
    <>
      <div className="services-components">
        <div className="services-list d-flex flex-column">
          <div className="self list-items d-flex">
            <a href="estimating">Estimating</a>
            <div id="arrow-container">
              <a href="estimating" className="bi bi-chevron-right">
                {" "}
              </a>
            </div>
          </div>

          <div className="list-items d-flex">
            <a href="design-specify">Design / Specifying</a>
            <div id="arrow-container">
              <a href="design-specify" className="bi bi-chevron-right">
                {" "}
              </a>
            </div>
          </div>

          <div className="list-items d-flex">
            <a href="installation-delivery">Installation / Delivery</a>
            <div id="arrow-container">
              <a href="installation-delivery" className="bi bi-chevron-right">
                {" "}
              </a>
            </div>
          </div>

          <div className="list-items d-flex">
            <a href="warranty-defects">Warranty / Defect Liability</a>
            <div id="arrow-container">
              <a href="warranty-defects" className="bi bi-chevron-right">
                {" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesComponents;
