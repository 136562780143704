import { useParams } from "react-router-dom";
import Carousel, { CarouselItem } from "../../../components/image/Carousel";
import ProductsCatalogue from "../product-components/ProductsCatalogue";

const SectionOne = () => {
  const params = useParams();

  const data = ProductsCatalogue.find((m) => m.type === params.productType)["components"][
    "accessories"
  ].find((f) => f.heading === params.productSubType);

  // const style = {
  //   button: {
  //     borderRadius: "0",
  //     border: "none",
  //     background: "#3C342C",
  //     width: "12.5em",
  //     height: "3.125em",
  //   },
  //   downloadButton: {
  //     borderRadius: "0",
  //     border: "1px dashed gray",
  //     background: "none",
  //     width: "12.5em",
  //     color: "#3C342C",
  //   },
  // };

  return (
    <div className="section-one">
      <div className="section-one-carousel">
        <Carousel autoSlide expandedView>
          {data && data["images"].map((m, i) => <CarouselItem key={i} src={m.name} />)}
        </Carousel>
      </div>
      <div className="section-one-information">
        <header>
          <div>{data.details}</div>
          <div className="heading-1">{params.productSubType}</div>
        </header>
        <div className="body-font">{data.descriptionOne}</div>
        <hr />
        {/*  <div className="d-flex flex-row justify-content-center gap-3">
          <div className="d-flex flex-column gap-3">
            <Button style={style.button}>Specifications</Button>
            <Button style={style.button}>Configure Yours</Button>
          </div>
          <div>
            <Button className="h-100" style={style.downloadButton}>
              <div>Download Specs</div> <i className="bi bi-download" />
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SectionOne;
