import { useRef } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ImageCard from "../../../components/image/ImageCard";
import ProductsCatalogue from "../product-components/ProductsCatalogue";

const SectionFive = () => {
  const scrollRef = useRef(null);
  const params = useParams();
  //Getting the first 4 elements of a random list of products
  const data = ProductsCatalogue.filter((m) => m.type !== params.productType)
    .sort(() => Math.random() - 0.5)
    .splice(0, 4);

  const handleScrollLeft = () => {
    const scrollContainer = scrollRef.current;
    const scrollAmount = scrollContainer.clientWidth;
    scrollContainer.scrollBy({
      left: -scrollAmount,
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    const scrollContainer = scrollRef.current;
    const scrollAmount = scrollContainer.clientWidth;
    scrollContainer.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <>
      <header className="heading-2">We Suggest Checking Out...</header>
      <div className="products-standard-display" ref={scrollRef}>
        {data.map((product) => (
          <ImageCard
            {...{
              heading: product.heading,
              details: product.details,
              image: product?.images[0],
            }}
          />
        ))}
      </div>
      <div className="d-flex w-100 justify-content-end">
        <Button
          style={{
            border: "none",
            background: "none",
            color: "black",
            WebkitTextStrokeWidth: "1px",
          }}
          className="bi bi-chevron-left"
          onClick={handleScrollLeft}
        />
        <Button
          style={{
            border: "none",
            background: "none",
            color: "black",
            WebkitTextStrokeWidth: "1px",
          }}
          className="bi bi-chevron-right"
          onClick={handleScrollRight}
        />
      </div>
    </>
  );
};

export default SectionFive;
