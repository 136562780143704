import { Button, Col, Row } from "react-bootstrap";
import ImageInfo from "../../components/image/ImageInfo";
import ImageTemplate from "../../components/image/ImageTemplate";
import Atag from "../../components/misc/Atag";
import ComDomButton from "../../components/misc/ComDomButton";
import rollerBlind3 from "../../images/rollerBlind3.jpeg";
import rollerBlind4 from "../../images/rollerBlind4.jpeg";

const AboutPageOurWork = () => {
  return (
    <div className="about-page-our-work" id={"project"}>
      <Row className="about-container">
        <Col>
          <div className="body-text">
            <header className="heading-1 fw-bold">OUR WORK</header>
            <span className="body-font width-increase">
              At Blineco, we have extensive experience providing window covering
              solutions for both residential and commercial projects. Our
              expertise in automated window coverings allows us to advise on the
              best way to integrate them into building management systems, and
              we are familiar with most systems used in both settings. We work
              closely with our clients, including builders, architects,
              developers, and consultants, to deliver cost-effective, timely,
              and eco-friendly solutions that meet their specific needs. We take
              pride in our attention to detail and are committed to delivering
              high standards and honoring our promises.
            </span>
          </div>
        </Col>
        <Col className=" desktop-version d-flex justify-content-end">
          <div>
            <ComDomButton>
              <Atag href={"/commercial"}>
                <Button>COMMERCIAL</Button>
              </Atag>
              <Atag href={"/residential"}>
                <Button>RESIDENTIAL</Button>
              </Atag>
            </ComDomButton>
          </div>
        </Col>
      </Row>
      <div className="about-container mobile-version our-work">
        <div>
          <ImageTemplate
            style={{ height: "40em", width: "100%" }}
            image={{ name: rollerBlind3, alt: "rollerBlind" }}
          />
          {ImageInfo("COMMERCIAL")}
        </div>
      </div>
      <div className="about-container desktop-version our-work">
        <div>
          <ImageTemplate
            image={{ name: rollerBlind3, alt: "rollerBlind" }}
            style={{ height: "40em", width: "35vw" }}
            link={"/commercial"}
          />
          {ImageInfo("COMMERCIAL")}
        </div>
        <div>
          <ImageTemplate
            image={{ name: rollerBlind4, alt: "rollerBlind" }}
            style={{ height: "40em", width: "35vw" }}
            link={"/residential"}
          />
          {ImageInfo("RESIDENTIAL")}
        </div>
      </div>
    </div>
  );
};

export default AboutPageOurWork;
