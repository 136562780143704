import "./ProductIndividualAccessories.scss";
import SectionFive from "./SectionFive";
import SectionFour from "./SectionFour";
import SectionOne from "./SectionOne";
import SectionThree from "./SectionThree";
import SectionTwo from "./SectionTwo";

const ProductIndividualAccessories = () => {
  return (
    <div>
      <SectionOne />
      <hr style={{ margin: "10em 0" }} />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </div>
  );
};

export default ProductIndividualAccessories;
