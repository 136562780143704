import { Col } from "react-bootstrap";
import "./Button.scss";

const ComDomButton = ({ children }) => {
  return (
    <Col className="com-dom-buttons d-flex">
      <div>{children}</div>
    </Col>
  );
};

export default ComDomButton;
