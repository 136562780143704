import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import NotFoundPage from "./pages/NotFoundPage";
import AboutPage from "./pages/about/AboutPage";
import Commercial from "./pages/com-res/commercial";
import Residential from "./pages/com-res/residential";
import ComingSoonPage from "./pages/coming-soon/ComingSoonPage";
import ConfiguratorPage from "./pages/configurator/ConfiguratorPage";
import ConfiguratorPage2 from "./pages/configurator/ConfiguratorPage2";

import ContactPage from "./pages/contact/ContactPage";
import HomePage from "./pages/home/HomePage";
import ProductsPage from "./pages/products/ProductsPage";
import ProductComponents from "./pages/products/product-components/ProductComponents";
import ServicesComponents from "./pages/services/ServicesComponents";
import ServicesOnboarding from "./pages/services/ServicesOnboarding";
import DesginSpecify from "./pages/services/design-specifying/DesignSpecifying";
import Estimate from "./pages/services/estimation/Estimation";
import InstallationDelivery from "./pages/services/installation-delivery/InstallationDelivery";
import WarrantyDefects from "./pages/services/warranty-defects/WarrantyDefects";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/configurator" element={<ConfiguratorPage />} />
      <Route path="configurator2" element={<ConfiguratorPage2 />} />
      <Route path="/" element={<App />}>
        <Route index element={<HomePage />} />
        <Route path="products" element={<ProductsPage />}>
          <Route index element={<ProductComponents />} />
          <Route path=":productType" element={<ProductComponents />}>
            <Route path=":type/:productSubType" element={<ProductComponents />} />
          </Route>
        </Route>
        <Route path="commercial" element={<Commercial />} />
        <Route path="residential" element={<Residential />} />
        <Route path="about-us" element={<AboutPage />} />
        <Route path="contact-us" element={<ContactPage />} />
        <Route path="services" element={<ServicesOnboarding />}>
          <Route index element={<ServicesComponents />} />
          <Route path="serviceType" element={<ProductComponents />} />
        </Route>
        <Route path="estimating" element={<Estimate />} />
        <Route path="design-specify" element={<DesginSpecify />} />
        <Route path="installation-delivery" element={<InstallationDelivery />} />
        <Route path="warranty-defects" element={<WarrantyDefects />} />
        <Route path="coming-soon" element={<ComingSoonPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
