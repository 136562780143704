import { Col, Row } from "react-bootstrap";
import featuredProject from "../../images/9001-black-Aluproff-2.jpg";
import "./NextProject.scss";
// For placeholder Purposes

const ProjectsPage = () => {
  return (
    <>
      <div className="project-page" id="project">
        <header className="heading-1">
          <div className="fw-bold">PROJECTS</div>
        </header>

        <div className="project-page-caption col-5 heading-2 pt-3 pb-3">
          Blineco has been around for many years and has successfully completed many projects around
          Australia, both large and small.
        </div>
        <div className="line" />

        <Row className="project-content">
          <Col className="body-font">
            {/* The content of each 'project-list-item' is arranged for each div to align
              adjacent to it's parent width. */}

            {/* Row 1 */}
            <Row className="project-list-item d-flex row justify-content-between">
              <div className="col-4 p-0">
                <div id="project-title">SALT Torquay</div>
                <div id="project-title-caption">
                  54 Townhouses, 400 Blinds with white aluminium facias.
                </div>
              </div>

              <div className="col-4">
                <div id="project-title">Bendigo Tafe</div>
                <div id="project-title-caption">
                  5 buildings, 500 Blinds and 50 suspended partition health curtains
                </div>
              </div>
            </Row>

            {/* Row 2 */}
            <Row className="project-list-item d-flex row justify-content-between">
              <div className="col-4 p-0">
                <div id="project-title">Victoria University</div>
                <div id="project-title-caption">
                  26 floors in total. 1700 blinds, manual and motorised, and 100 suspended partition
                  health curtains.
                </div>
              </div>

              <div className="col-4">
                <div id="project-title">Sheraton Hotel Melbourne</div>
                <div id="project-title-caption">
                  1200 curtains and 250 Roller Blinds, manual and motorized.
                </div>
              </div>
            </Row>

            {/* Row 3 */}
            <Row className="project-list-item d-flex row justify-content-between">
              <div className="col-4 p-0">
                <div id="project-title">Pearson Office,</div>
                <div id="project-title-caption">
                  Goods Shed Docklands, 300 motorised roller blinds installed 11m high to stop
                  glare, Metalo fabric.
                </div>
              </div>

              <div className="col-4">
                <div id="project-title">Victorian Building Authority</div>
                <div id="project-title-caption">
                  motorized roller blinds installed 11m high to stop glare, Metalo fabric.
                </div>
              </div>
            </Row>

            {/* Row 5 */}
            <Row className="project-list-item d-flex row justify-content-between">
              <div className="col-4 p-0">
                <div id="project-title">JPE Architects, Adelaide</div>
                <div id="project-title-caption">
                  54 motorized blinds installed with angled and 90 degree links.
                </div>
              </div>

              <div className="col-4">
                <div id="project-title">Building Commission</div>
                <div id="project-title-caption">
                  motorized blinds , upside down blinds, high level install.
                </div>
              </div>
            </Row>

            {/* Featured Section 1*/}
            <div className="featured-project-container">
              <div className="featured-project">
                <img
                  // Image is only for placeholder purposes.
                  style={{ height: "50em", width: "100%", objectFit: "cover" }}
                  src={featuredProject}
                  alt="featured project"
                />

                <div>FEATURED</div>
              </div>

              <Row className="featured-project-caption d-flex row justify-content-between align-items-center">
                <div id="title" className="col-7 p-0">
                  Adelaide Showground with Built Environs
                </div>
                {/* <div id="arrow-container" className="col-4 d-flex justify-content-end">
                  <a href="/" className="bi bi-chevron-right">
                    {" "}
                  </a>
                </div> */}
              </Row>
            </div>

            <div className="line" />

            {/* Row 6 */}
            <Row className="project-list-item d-flex row justify-content-between">
              <div className="col-4 p-0">
                <div id="project-title">Knight Frank Melbourne</div>
                <div id="project-title-caption">
                  54 motorized blinds installed with angled and 90 degree links.
                </div>
              </div>

              <div className="col-4 p-0">
                <div id="project-title">Department of Justice Victoria Box Hill and Carlton.</div>
                <div id="project-title-caption">Various manual blinds</div>
              </div>
            </Row>

            {/* Row 7
            <Row className="project-list-item d-flex row justify-content-between">
              <div className="col-4 p-0">
                <div id="project-title">Goods Shed for Equiset</div>
                <div id="project-title-caption">24 Motorized Skylights customized for site.</div>
              </div>
            </Row> */}

            {/* Row 4 */}
            <Row className="project-list-item d-flex row justify-content-between">
              <div className="col-4 p-0">
                <div id="project-title">Vic Urban (Places Victoria)</div>
                <div id="project-title-caption">
                  Head Office Collins Street Docklands, 240 motorized blinds installed 11m high to
                  stop glare, Metalo fabric.
                </div>
              </div>

              <div className="col-4">
                <div id="project-title">Building Commission</div>
                <div id="project-title-caption">
                  motorized blinds , upside down blinds, high level install.
                </div>
              </div>
            </Row>

            {/* Featured Section 2*/}
            <div className="featured-project-container">
              <div className="featured-project">
                <img
                  // Image is only for placeholder purposes.
                  style={{ height: "50em", width: "100%", objectFit: "cover" }}
                  src={featuredProject}
                  alt="featured project"
                />

                <div>FEATURED</div>
              </div>

              {/* <div className="pt-4 row-4 p-0">
                <div id="project-title">Plant Accelerator project, Adelaide</div>
                <div id="project-title-caption">
                    Built Environs Builders. Manual blinds and
                    external motorised Louvers.
                </div>
              </div> */}

              <Row className="featured-project-caption d-flex row justify-content-between align-items-center">
                <div id="title" className="col-7 p-0">
                  SA Water Head Office, Adelaide
                </div>
                {/* <div id="arrow-container" className="col-4 d-flex justify-content-end">
                  <a href="/about-us" className="bi bi-chevron-right">
                    {" "}
                  </a>
                </div> */}
              </Row>
            </div>

            <div className="line" />

            {/* Row 8 */}
            <Row className="project-list-item d-flex row justify-content-between">
              <div className="col-4 p-0">
                <div id="project-title">Kraft Headoffice South Wharf Melbourne</div>
                <div id="project-title-caption">160 Motorised blinds.</div>
              </div>

              <div className="col-4">
                <div id="project-title">Wave/Edge Project</div>
                <div id="project-title-caption">Hansen Yuncken Builders, 300 motorized blinds.</div>
              </div>
            </Row>

            {/* Row 9 */}
            <Row className="project-list-item d-flex row justify-content-between">
              <div className="col-4 p-0">
                <div id="project-title">Coles Dernacourt</div>
                <div id="project-title-caption">
                  Automated blinds system to reduce glare issues. Saville Builders.
                </div>
              </div>

              <div className="col-4 ">
                <div id="project-title">Media House Office, Melbourne</div>
                <div id="project-title-caption">Over 700 manual and motorized blinds.</div>
              </div>

              {/* <div className="pt-4 row-4 p-0">
                <div id="project-title">Plant Accelerator project, Adelaide</div>
                <div id="project-title-caption">
                    Built Environs Builders. Manual blinds and
                    external motorised Louvers.
                </div>
              </div> */}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProjectsPage;
