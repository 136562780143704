import { Button } from "react-bootstrap";
import { useCustomisation } from "../../pages/configurator/ConfiguratorPage2";
import ColorSelection from "./ColorSelection";

export const ConfiguratorBox = ({ children, style }) => (
  <div
    style={{
      background: "rgba(0, 0, 0, 0.10",
      padding: "1em",
      width: "15vw",
      borderRadius: "10px",
      ...style,
    }}
  >
    {children}
  </div>
);

const ColorConfigurator = () => {
  const { component, systems, system, setComponent, setColor, defaultColor } = useCustomisation();

  const filteredSystem = system && systems.filter((s) => s.id === system?.id);

  const colorOptions =
    component &&
    filteredSystem &&
    filteredSystem.map((fs) => fs.componentColors.find((comp) => component.includes(comp.name)))[0];

  return (
    <ConfiguratorBox>
      <div className="d-flex justify-content-center w-100 flex-column">
        {filteredSystem &&
          filteredSystem.map((comp, i) => (
            <div key={i}>
              {comp.componentColors.map((c, j) => (
                <div key={j}>
                  <Button
                    active={c.name === component}
                    variant="light"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setComponent(c.name);
                      setColor((prev) => [...prev]);
                    }}
                  >
                    {c.name}
                  </Button>
                </div>
              ))}
            </div>
          ))}
      </div>
      {colorOptions && (
        <>
          <h6 className="my-3">Color Options</h6>
          <div className="d-flex flex-wrap">
            {colorOptions.colors.map((color, i) => (
              <div key={i}>
                <ColorSelection color={color} />
              </div>
            ))}
            <ColorSelection color={defaultColor} />
          </div>
        </>
      )}
    </ConfiguratorBox>
  );
};

export default ColorConfigurator;
