const ImageCard4 = ({
  heading = "",
  setData,
  setOperation,
  details = "",
  image = [{ name: "", alt: "" }],
}) => {
  const afterOnClick = () => {
    if (heading === "Linked") {
      setOperation("Motorised");
    }
    setData(heading);
  };
  return (
    <>
      <div style={{ width: "50%", justifyContent: "center", margin: "1rem" }}>
        <div style={{ display: "flex", justifyContent: "center" }} className="heading-2">
          {heading}
        </div>
        <div className="image-container" style={{ display: "flex", justifyContent: "center" }}>
          <img
            onClick={() => afterOnClick()}
            src={image?.name ?? ""}
            alt={image?.alt ?? ""}
            style={{
              objectFit: "cover",
              borderRadius: "1rem",
              margin: "1rem 0 1rem 0 ",
            }}
            className={`image-template center image-template-size w-100`}
          />
        </div>
        <div className="d-flex justify-content-between">
          <span className="heading-3">{details}</span>
        </div>
      </div>
    </>
  );
};

export default ImageCard4;
