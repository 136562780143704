import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ImageCard2 from "../../components/image/ImageCard2";
import ImageCard4 from "../../components/image/ImageCard4";
import CurtainCeiling from "../../images/products/curtains/curtain_ceiling.jpeg";
import CurtainCurve from "../../images/products/curtains/curtain_curve.jpg";
import CurtainHealthCare from "../../images/products/curtains/curtain_healthcare.jpg";
import CurtainManual from "../../images/products/curtains/curtain_manual.jpg";
import CurtainMotorised from "../../images/products/curtains/curtain_motorised.jpg";
import CurtainStraight from "../../images/products/curtains/curtain_straight.jpg";
import CurtainWall from "../../images/products/curtains/curtain_wall.jpg";
import "./DIYBlindComponent.scss";
import ProductsCatalogue from "./product-components/ProductsCatalogue";
const DIYCurtainComponent = ({
  diy,
  setDiy,
  curtainType,
  installation,
  direction,
  setCurtainType,
  setInstallation,
  setDirection,
}) => {
  const params = useParams();

  const curtainProductSystems = ProductsCatalogue.filter((f) => f.type === "curtains")[0][
    "components"
  ]["systems"];

  const removeData = () => {
    setDiy(false);
    setCurtainType(null);
    setInstallation(null);
    setDirection(null);
  };
  return (
    <>
      <div>
        <div style={{ float: "right" }}>
          <Button onClick={() => removeData()} className="exit-button">
            X
          </Button>
        </div>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "1rem",
            paddingTop: "2rem",
            paddingBottom: "5rem",
          }}
        >
          What kind of Curtains are you thinking of?
        </h1>
      </div>

      {/* For Healthcare, Manual or Motorised*/}
      {diy && !curtainType && !installation && !direction && (
        <div
          className={`products-standard-display`}
          style={{
            display: "flex",
            justifyContent: "center",
            overflow: "auto",
          }}
        >
          <ImageCard4
            setData={setCurtainType}
            {...{
              heading: "Healthcare",
              details:
                "Healthcare curtains prioritize hygiene, offer privacy, and create separation in healthcare settings. Made with antimicrobial materials, they are easily cleaned and contribute to a safe environment for patients in hospitals, clinics, and medical offices.",
              image: { name: CurtainHealthCare, alt: "Single" },
            }}
          />
          <ImageCard4
            setData={setCurtainType}
            {...{
              heading: "Manual",
              details:
                "Manual curtains, operated by hand, are versatile and easy to adjust for light, privacy, and aesthetics. Mounted on tracks or rods, they can be pulled or slid open and closed. With a wide variety of styles, fabrics, and patterns, manual curtains provide functional and decorative advantages for homes and businesses.",
              image: { name: CurtainManual, alt: "Dual" },
            }}
          />
          <ImageCard4
            setData={setCurtainType}
            {...{
              heading: "Motorised",
              details:
                "Motorized curtains, also called motorized blinds, have automated systems for remote or programmable control. With an electric motor, they open or close at the push of a button, via a remote, or through smart home automation. Providing convenience and precise light and privacy control, motorized curtains are popular in modern homes, offices, and hospitality venues for their seamless and advanced window treatment solution.",
              image: { name: CurtainMotorised, alt: "Linked" },
            }}
          />
        </div>
      )}
      {/* For Ceiling or Wall */}
      {diy && curtainType && !installation && !direction && (
        <div
          className={`products-standard-display`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ImageCard4
            setData={setInstallation}
            {...{
              heading: "Wall mount",
              details:
                "Wall mounts for curtains are sturdy hardware systems that securely attach curtain rods or tracks to walls. They come in different styles and materials like brackets or clips to accommodate various curtain types. These mounts offer versatility, allowing easy installation and adjustment of curtains for optimal height and width, enhancing the functionality and aesthetic appeal of window treatments in homes, offices, and other spaces.",
              image: { name: CurtainWall, alt: "Blockout" },
            }}
          />
          <ImageCard4
            setData={setInstallation}
            {...{
              heading: "Ceiling mount",
              details:
                "Ceiling mounts for curtains offer a sleek and modern alternative by attaching rods or tracks directly to the ceiling. They create an elegant and seamless look, suitable for limited wall space, irregular windows, or a floor-to-ceiling curtain effect. These mounts allow flexible placement, enabling the creation of room dividers or covering larger openings. Ceiling mounts add sophistication and enhance the visual impact of window treatments.",
              image: { name: CurtainCeiling, alt: "Transparent" },
            }}
          />
        </div>
      )}

      {/* For Straight or Curved */}
      {diy && curtainType && installation && !direction && (
        <div
          className="products-standard-display"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ImageCard4
            setData={setDirection}
            {...{
              heading: "Straight",
              details:
                "Straight curtains have a clean and uniform design, with fabric hanging straight down from the rod or track. They offer a timeless look that complements various interior styles, easily customizable with different fabrics, patterns, and colors. Straight curtains provide functional light and privacy control while enhancing the ambiance of living spaces, offices, and other environments.",
              image: { name: CurtainStraight, alt: "Manual" },
            }}
          />
          <ImageCard4
            setData={setDirection}
            {...{
              heading: "Curved",
              details:
                "Curved curtains add visual interest with their unique arched shape, distinct from traditional straight curtains. Ideal for spaces with curved architectural features, they accentuate the design and create an elegant look. Custom-made to fit specific window shapes, curved curtains enhance the aesthetics of residential and commercial interiors, blending functionality with artistic appeal.",
              image: { name: CurtainCurve, alt: "Motorised" },
            }}
          />
        </div>
      )}

      {/* Suggestions */}
      {diy && curtainType && installation && direction && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4>
              <i>Here are some suggestions that match your criteria!</i>
            </h4>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem",
            }}
          >
            <i>Customize extra accessories and fabrics using our configurator</i>
            <Button style={{ margin: "1rem" }}>Send to Configurator</Button>
          </div>

          <div className="products-display" style={{ margin: "2rem" }}>
            {curtainProductSystems
              .filter(
                (f) =>
                  f["direction"].includes(direction) &&
                  f["curtainType"].includes(curtainType) &&
                  f["installation"].includes(installation)
              )
              .map((m, index) => (
                <Link
                  onClick={() => removeData()}
                  className="nav-link"
                  activeClassName="active"
                  key={index}
                  to={`/products/${params.productType}/systems/${m.heading}`}
                >
                  <ImageCard2
                    {...{
                      heading: m.heading,
                      details: m.details,
                      image: m?.images[0],
                    }}
                  />
                </Link>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default DIYCurtainComponent;
