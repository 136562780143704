import { Col, Row } from "react-bootstrap";
import estimateImage from "../../../images/Glydea-white-2.jpeg";
import "./InstallationDelivery.scss";

const InstallationDelivery = () => {
  return (
    <>
      <div className="parent-estimate">
        {/* Hero */}
        <div className="hero-section">
          <div>
            <p id="estimate-title">INSTALLATION / DELIVERY</p>
            <div className="estimate-description">
              <p>
                Small residential projects as well as substantial commercial projects can be
                finished by our team of experts. We are aware of compliance requirements for
                projects involving commerce and have the equipment and paperwork needed to complete
                the task.
              </p>
              <p>
                With the use of our delivery cost calculator, you can easily determine the shipping
                charges associated with your order. Our configurator will provide you a precise
                estimation of the shipping costs when you enter your location, the destination, and
                the weight of your shipment.
              </p>
            </div>
          </div>
          <div>
            <img
              src={estimateImage}
              style={{ height: "35em", width: "40em", objectFit: "cover" }}
              alt="blineco"
            ></img>
          </div>
        </div>
        {/* How it works*/}

        <div className="how-it-works">
          <p>HOW IT WORKS</p>
          <p className="col-5">
            We break down both procedures into simple steps so you can understand what to anticipate
            from utilizing our services.
          </p>
        </div>
        {/* Parent Control */}
        <div className="card-controller">
          <Row className="col-6 control-gap">
            <Col className="install-card p-0">
              <div className="install-card-tag d-flex">
                <p className="m-0">Installation</p>
              </div>
              <div className="install-first-card">
                {/* 01 */}
                <div className="inner-body">
                  <p>01</p>
                  <p>Configure your window furnishings.</p>
                  <a href="/coming-soon" id="pg-bttn">
                    Configure
                  </a>
                </div>
              </div>
              {/* 02 */}
              <div className="install-second-card">
                <div className="inner-body">
                  <p>02</p>
                  <p>Select your preferred Installation Method.</p>
                  <ul>
                    <li>D.I.Y</li>
                    <li>Professional</li>
                  </ul>
                  <a href="/coming-soon" id="pg-bttn">
                    Configure
                  </a>
                </div>
              </div>
            </Col>

            <Col className="install-card p-0">
              <div className="install-card-tag d-flex">
                <p className="m-0">Delivery</p>
              </div>
              <div className="install-first-card">
                {/* 01 */}
                <div className="inner-body">
                  <p>01</p>
                  <p>Configure your window furnishings.</p>
                  <a href="/coming-soon" id="pg-bttn">
                    Configure
                  </a>
                </div>
              </div>
              {/* 02 */}
              <div className="install-second-card">
                <div className="inner-body">
                  <p>02</p>
                  <p>Fill in your delivery address and we'll calculate the cost for you.</p>

                  <a href="/coming-soon" id="pg-bttn">
                    Configure
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Col className="install-card"></Col>
      </div>
    </>
  );
};

export default InstallationDelivery;
