import ImageCard3 from "../../components/image/ImageCard3";
import { commercial } from "../com-res-data/CommercialData";

const Commercial = () => {
  return (
    <div className="padding-increase" style={{ padding: "0% 9%" }}>
      {commercial.map((data) => (
        <div key={data.id}>
          <ImageCard3
            {...{
              image: data.coverImage,
              information: data.info,
            }}
          />
        </div>
      ))}
    </div>
  );
};
export default Commercial;
