import { Col, Row } from "react-bootstrap";
import "../../components/image/ImageCard3.scss";

const ImageCard3 = (sayed) => {
  const {
    image, //string
    information, //object
  } = sayed;

  return (
    <>
      <div className="body-controller">
        <Row className="hero-section">
          <Col className="hero-control col-4 p-0">
            <header className="heading-1">{information.hero_Title}</header>
            <p>{information.hero_Description1}</p>
            <p>{information.hero_Description2}</p>
          </Col>

          <Col className="hero-control col-4">
            <p>{information.hero_Description3}</p>
          </Col>
        </Row>

        <Col className="parent-container">
          <Row className="image-container-c1 m-0">
            <img
              src={image}
              style={{ width: "100%", height: "60em", objectFit: "cover" }}
              alt="blineco"
            />
          </Row>

          <Row className="m-0 projects-involved d-flex">
            <Col className="col-4">{information.projects_involved}</Col>
            <Col className="col-8">{information.description}</Col>
          </Row>

          <Row className="m-0 products-involved d-flex">
            <div className="PI-c col-4 p-0">
              <div>{information.products_involved}</div>
              <div>{information.products_description}</div>
            </div>

            {information.images.map((image, i) => (
              <div key={i} className="col-8 d-flex gap-5">
                <div style={{ width: "20em", height: "20em" }}>
                  <img
                    src={image}
                    style={{ width: "100%", height: "20em", objectFit: "cover" }}
                    alt="blineco"
                  ></img>
                </div>

                <div style={{ width: "20em", height: "20em" }}>
                  <img
                    src={image}
                    style={{ width: "100%", height: "20em", objectFit: "cover" }}
                    alt="blineco"
                  ></img>
                </div>

                <div style={{ width: "20em", height: "20em" }}>
                  <img
                    src={image}
                    style={{ width: "100%", height: "20em", objectFit: "cover" }}
                    alt="blineco"
                  ></img>
                </div>
              </div>
            ))}
          </Row>
        </Col>
      </div>
    </>
  );
};

export default ImageCard3;
