import ImageTemplate from "./ImageTemplate";

const ImageCard = ({
  heading = "",
  style,
  subHeading = "",
  details = "",
  image = [{ name: "", alt: "" }],
}) => {
  return (
    <div>
      <div className="heading-2">
        <strong>{heading}</strong>
      </div>
      <div className="heading-2">{subHeading}</div>
      <p className="body-font">{details}</p>
      <ImageTemplate className={"image-template-size  w-100"} image={image} style={style} />
    </div>
  );
};

export default ImageCard;
