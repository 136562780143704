import { Spinner } from "react-bootstrap";
import "./ComingSoonPage.scss";

const ComingSoonPage = () => {

  return (
    <div className="coming-soon-parent d-flex">
      <div
        className="coming-soon d-flex justify-content-center align-items-center gap-3"
        style={{ height: "500px", width: "50em", fontSize: "2em" }}
      >
        {/* Will be replaced with screenshot of our configurator */}
        <Spinner animation="grow" variant="secondary" size="sm">
          <span className="visually-hidden"></span>
        </Spinner>
        {"Coming Soon..."}
      </div>
    </div>
  );
};

export default ComingSoonPage;
