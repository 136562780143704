import ImageTemplate from "../../components/image/ImageTemplate";
import Slider from "../../components/slider/Slider";
import rollerBlind from "../../images/rollerBlind.jpeg";
import rollerBlind5 from "../../images/rollerBlind5.png";
import AboutPageOurWork from "../about/AboutPageOurWork";
import AboutPageWhoWeAre from "../about/AboutPageWhoWeAre";
import "./HomePage.scss";

const HomePage = () => {
  return (
    <>
      <div className="home-page padding-increase">
        <header className="heading-1">
          <span>
            <strong>Specialists in smart window furnishings.</strong>
          </span>
        </header>
        <ImageTemplate
          image={{ name: rollerBlind, alt: "rollerBlind" }}
          style={{ height: "42.6875em", width: "100%", marginTop: "4em" }}
        />

        <Slider
          length={3}
          placeholder={"PHOTOGRAPHS"}
          style={{ marginTop: "4em" }}
        />
        <AboutPageWhoWeAre />
        <ImageTemplate
          image={{ name: rollerBlind5, alt: "rollerBlind" }}
          style={{ height: "55.125em", width: "100%", marginBottom: "6em" }}
        />

        <div
          style={{
            height: "2px",
            background: "black",
          }}
        />
        <AboutPageOurWork />
        <Slider style={{ marginBottom: "4em" }} />
      </div>
    </>
  );
};

export default HomePage;
