import { OrbitControls, PresentationControls, Stage, useTexture } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useRef } from "react";
import * as THREE from "three";
import { Vector3 } from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useCustomisation } from "../../pages/configurator/ConfiguratorPage2";
import { Window } from "./Window";

const Scene = () => {
  const ANIM_SPEED = 12;
  const obj = useLoader(OBJLoader, "./blinds/Vertilux_Easydrive_1_02&1_03.obj");
  const { width, height, color, showEnv } = useCustomisation();
  const drop = useRef();
  const left = useRef();
  const right = useRef();
  const tube = useRef();
  const base = useRef();
  const group = useRef();
  const leftBracket = useRef();
  const rightBracket = useRef();

  const fabric = useTexture({
    map: "./blinds/textures/Fabric030_1K-JPG/Untitled.png",
  });
  const background = useTexture({
    map: "./blinds/textures/outside.jpg",
  });

  const amount = 4;

  const h =
    height >= 50
      ? amount - 0.5 + 0.01 * height
      : amount +
        0.5 +
        (height <= 5 ? -0.45 : height <= 10 ? -0.4 : height <= 20 ? -0.2 : 0) -
        (amount - 0.01 * height);
  const w =
    width >= 50
      ? amount - 0.5 + 0.01 * width
      : amount +
        0.5 +
        (width <= 5 ? -0.45 : width <= 10 ? -0.4 : width <= 20 ? -0.2 : 0) -
        (amount - 0.01 * width);

  useFrame((_state, delta) => {
    const tableWidthScale = width / 1000;
    const tableHeightScale = height / 1000;
    const targetScale = new Vector3(tableWidthScale, tableHeightScale * 2, 1);
    const tubes = new Vector3(tableWidthScale, 1, 1);
    const bases = new Vector3(tableWidthScale, 1, 1);

    drop.current.scale.lerp(targetScale, delta * ANIM_SPEED);
    tube.current.scale.lerp(tubes, delta * ANIM_SPEED);
    base.current.scale.lerp(bases, delta * ANIM_SPEED);

    const tubePosition = new Vector3(0, tableHeightScale * 1125 + 10, 20);
    tube.current.position.lerp(tubePosition, delta * ANIM_SPEED);

    const basePosition = new Vector3(0, -1130 - (tableHeightScale * 1130 - 1130));
    base.current.position.lerp(basePosition, delta * ANIM_SPEED);

    const targetLeftPosition = new Vector3(
      -(tableWidthScale * 1065 - 25),
      tableHeightScale * 1125 + 5,
      20
    );
    left.current?.position.lerp(targetLeftPosition, delta * ANIM_SPEED);

    const targetRightPosition = new Vector3(
      tableWidthScale * 1065 - 25,
      tableHeightScale * 1125 + 10,
      20
    );
    right.current?.position.lerp(targetRightPosition, delta * ANIM_SPEED);

    const targetRightBracket = new Vector3(29, 0, -8);
    rightBracket.current?.position.lerp(targetRightBracket, delta * ANIM_SPEED);

    const targetLeftBracket = new Vector3(-35, 3, -8);
    leftBracket.current?.position.lerp(targetLeftBracket, delta * ANIM_SPEED);
  });

  fabric.map.repeat.set(w, h);
  // fabric.displacementMap.repeat.set(w, h);
  // fabric.normalMap.repeat.set(w, h);
  // fabric.roughnessMap.repeat.set(w, h);
  // fabric.aoMap.repeat.set(w, h);

  fabric.map.wrapS = fabric.map.wrapT =
    // fabric.normalMap.wrapS =
    // fabric.normalMap.wrapT =
    // fabric.roughnessMap.wrapS =
    // fabric.roughnessMap.wrapT =
    // fabric.aoMap.wrapS =
    // fabric.aoMap.wrapT =
    // fabric.displacementMap.wrapS =
    // fabric.displacementMap.wrapT =
    THREE.RepeatWrapping;

  const determineComponent = (comp) => {
    const c = color.find((col) => col.name === comp);
    return c.color;
  };

  const geometry = new THREE.BoxGeometry(1900, 1650, 1);

  return (
    <>
      <group scale={0.003} ref={group}>
        {showEnv && (
          <group position={[0, 50, 0]}>
            <mesh geometry={geometry} position={[0, 0, -150]}>
              <meshStandardMaterial {...background} />
            </mesh>
            <group position={[2230, 0, -100]}>
              <Window />
            </group>
            <group position={[1300, 0, -100]}>
              <Window />
            </group>
          </group>
        )}
        {width >= 5 && (
          <group>
            <group ref={left}>
              <mesh geometry={obj.children[0].geometry.center()} ref={leftBracket}>
                <meshStandardMaterial color={determineComponent("Brackets")} />
                {/* left bracket */}
              </mesh>
              <mesh geometry={obj.children[2].geometry.center()}>
                <meshStandardMaterial color={determineComponent("Clutch")} />
                {/* clutch */}
              </mesh>
            </group>
            <group ref={right}>
              <mesh geometry={obj.children[1].geometry.center()} ref={rightBracket}>
                <meshStandardMaterial color={determineComponent("Brackets")} />
                {/* right bracket */}
              </mesh>
              <mesh geometry={obj.children[4].geometry.center()}>
                <meshStandardMaterial color="gray" />
                {/* pin */}
              </mesh>
            </group>
          </group>
        )}

        <mesh geometry={obj.children[3].geometry.center()} ref={tube}>
          {determineComponent("Fabric") !== "default" && (
            <meshStandardMaterial {...fabric} color={determineComponent("Fabric")} />
          )}
          {determineComponent("Fabric") === "default" && <meshStandardMaterial {...fabric} />}
          {/* tube */}
        </mesh>

        <group ref={base}>
          <mesh geometry={obj.children[5].geometry.center()}>
            <meshStandardMaterial color={determineComponent("Base Rail")} />
            {/* baserail */}
          </mesh>
          <mesh geometry={obj.children[6].geometry.center()} position={[0, -4, -9]}>
            <meshStandardMaterial color="gray" />
            {/* baserail bumper */}
          </mesh>
        </group>
        <mesh geometry={obj.children[7].geometry.center()} ref={drop}>
          {/* drop */}
          {determineComponent("Fabric") !== "default" && (
            <meshStandardMaterial
              {...fabric}
              transparent
              opacity={0.8}
              color={determineComponent("Fabric")}
            />
          )}
          {determineComponent("Fabric") === "default" && (
            <meshStandardMaterial {...fabric} transparent opacity={0.8} />
          )}
        </mesh>
      </group>
    </>
  );
};

const Experience = () => {
  return (
    <PresentationControls global>
      <OrbitControls rotateSpeed={0.11} enableRotate={false} zoomSpeed={1} />
      <Stage environment={""} intensity={0.11} shadows={false} adjustCamera={false} center={true}>
        <Scene />
      </Stage>
    </PresentationControls>
  );
};

export default Experience;
