import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import Navigation from "./components/navigation/Navigation";
import Footer from "./pages/footer/Footer";

const App = () => {
  return (
    <div className="App">
      <Navigation />
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Outlet />
      <Footer />
    </div>
  );
};

export default App;
