import { forwardRef } from "react";
import { Offcanvas } from "react-bootstrap";
import Atag from "../misc/Atag";
import "./OffCanvasComponent.scss";

const OffCanvasComponent = forwardRef(({ show, close, children, ...props }, ref) => {
  return (
    <>
      <div className="me-2">{children}</div>
      <Offcanvas show={show.show} onHide={close} {...props} className="canvas-width">
        <Offcanvas.Header closeButton>
          {/* <Form className="search-bar">
              <Form.Control
                type="text"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                ref={ref}
              ></Form.Control>
              <div className="fa fa-search" />
            </Form> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="options" onClick={close}>
            <div className="primary-options">
              <Atag href="/products">Products</Atag>
              <Atag href="/coming-soon">Configurator</Atag>
              <Atag href="/services">Services</Atag>
            </div>
            <hr></hr>
            <div className="secondary-options">
              <Atag href="/commercial">Commercial</Atag>
              <Atag href="/residential">Residential</Atag>
              <Atag href="/about-us">About Us</Atag>
              <Atag href="/contact-us">Support</Atag>
            </div>
          </div>

          {/* Disabled Social Media Links until further notice  */}
          {/* <div className="d-flex flex-column flex-grow justify-content-end h-100">
            <div className="options">{Atag("/coming-soon", "SOCIAL MEDIA")}</div>
            <div className="social-media-icons-options">
              <SocialMediaIcons icon={"fa-brands fa-instagram"} />
              <SocialMediaIcons icon={"fa-brands fa-pinterest"} />
              <SocialMediaIcons icon={"fa-brands fa-facebook"} />
            </div>
          </div> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
});

export default OffCanvasComponent;
