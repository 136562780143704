import { createContext, useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import Atag from "../../components/misc/Atag";
import ScrollToTop from "../../components/misc/scrollToTop";
import useScrollToElement from "../../hooks/useScrollToElement";
import DIYBlindComponent from "./DIYBlindComponent";
import DIYCurtainComponent from "./DIYCurtainComponent";
import "./ProductsPage.scss";

export const ProductContext = createContext({
  system: {},
  setSystem: () => {},
  display: {},
  setDisplay: () => {},
});

const ProductWrapper = ({ children }) => {
  const [system, setSystem] = useState({ manual: true, motorised: true });
  const [display, setDisplay] = useState({ vertilux: true, grid: true });

  return (
    <ProductContext.Provider value={{ system, setSystem, display, setDisplay }}>
      {children}
    </ProductContext.Provider>
  );
};

const Heading = () => {
  //Blinds
  const params = useParams();
  const { system, setSystem, display, setDisplay } = useContext(ProductContext);
  const [rollType, setRollType] = useState(null);
  const [opacity, setOpacity] = useState(null);
  const [operation, setOperation] = useState(null);

  //Curtains
  const [curtainType, setCurtainType] = useState(null);
  const [installation, setInstallation] = useState(null);
  const [direction, setDirection] = useState(null);

  const [diy, setDiy] = useState(false);

  const scrollTo = useScrollToElement();

  const style = { background: "#3a3a3a", color: "white" };

  const productComponent = () => (
    <>
      <div className="d-flex justify-content-between">
        <Atag href={"/products"}>
          <i className="fa-solid fa-arrow-left" />
          {` ${params.productType.toUpperCase()}`}
        </Atag>

        {params.productType === "Roller Blinds" && (
          <Modal size="xl" show={diy} handleClose={setDiy}>
            <DIYBlindComponent
              diy={diy}
              setDiy={setDiy}
              rollType={rollType}
              opacity={opacity}
              operation={operation}
              setRollType={setRollType}
              setOpacity={setOpacity}
              setOperation={setOperation}
            />
          </Modal>
        )}
        {params.productType === "curtains" && (
          <Modal size="xl" show={diy} handleClose={setDiy}>
            <DIYCurtainComponent
              diy={diy}
              setDiy={setDiy}
              curtainType={curtainType}
              installation={installation}
              direction={direction}
              setCurtainType={setCurtainType}
              setInstallation={setInstallation}
              setDirection={setDirection}
            />
          </Modal>
        )}
        <Button style={{ margin: "2rem" }} onClick={() => setDiy(true)}>
          Help me make my {params.productType}
        </Button>
        <span className="product-component-buttons">
          {/* <div style={{ fontSize: "1rem" }}>
            <label htmlFor="window-length">Window Width (cm): </label>
            <input
              style={{ width: "3rem", margin: "1rem" }}
              type="text"
              id="window-width"
              value={system.windowWidth}
              onChange={(e) => setDisplay((prev) => ({ ...prev, windowWidth: e.target.value }))}
            />
          </div>
          <div style={{ fontSize: "1rem" }}>
            <label htmlFor="window-length">Window Length (cm): </label>
            <input
              style={{ width: "3rem", margin: "1rem" }}
              type="text"
              id="window-length"
              value={system.windowLength}
              onChange={(e) => setDisplay((prev) => ({ ...prev, windowLength: e.target.value }))}
            />
          </div> */}

          <Button
            id={"systems"}
            onClick={() => {
              setSystem((prev) => ({ ...prev, manual: !system.systems }));
              scrollTo("systems-section");
            }}
          >
            Systems
          </Button>
          <Button
            id={"fabrics"}
            onClick={() => {
              setSystem((prev) => ({ ...prev, motorised: !system.fabrics }));
              scrollTo("fabrics-section");
            }}
          >
            Fabrics
          </Button>
          <Button
            style={display.vertilux ? style : {}}
            onClick={() => {
              setDisplay((prev) => ({ ...prev, vertilux: !display.vertilux }));
              scrollTo("accessories-section");
            }}
          >
            Accessories
          </Button>
        </span>
      </div>
      <hr />
    </>
  );

  if (!params.productType) return "PRODUCTS";
  else if (params.productType && !params.productSubType) return productComponent();
  else if (params.productType && params.productSubType)
    return (
      <Atag href={`/products/${params.productType}`}>
        <i className="fa-solid fa-arrow-left" />
        {` ${params.productType.toUpperCase()} ${params.type.toUpperCase()}`}
      </Atag>
    );
};

const ProductsPage = () => {
  return (
    <ProductWrapper>
      <div className="w-100">
        <ScrollToTop />
        <div className="products-page ">
          <header className="heading-1">{<Heading />}</header>
          <Outlet />
        </div>
      </div>
    </ProductWrapper>
  );
};

export default ProductsPage;
