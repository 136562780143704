import { Unity, useUnityContext } from "react-unity-webgl";

const ConfiguratorPage = () => {
  const { unityProvider } = useUnityContext({
    loaderUrl: "/unity/build/Simulation Test.loader.js",
    dataUrl: "/unity/build/Simulation Test.data",
    frameworkUrl: "/unity/build/Simulation Test.framework.js",
    codeUrl: "/unity/build/Simulation Test.wasm",
  });

  return <Unity style={{ height: "100%", width: "100%" }} unityProvider={unityProvider} />;
};

export default ConfiguratorPage;
