//import CurtainImage from "../../../images/0520_Gold_3.JPG";

import NoImage from "../../../images/noImg.jpg";
import PanelGlideImage from "../../../images/panel-glide2.jpg";
import PleatedImage from "../../../images/pleated.jpg";
import {
  default as OneZeroOneSpringOperated,
  default as RollerBlindOnePointZeroOneImageOne,
} from "../../../images/products/roller-blinds/1.01-a.jpg";
//Blinds Systems
import RollerBlindOnePointZeroOneImageTwo from "../../../images/products/roller-blinds/1.01-b.jpeg";
import RollerBlindOnePointZeroOneImageThree from "../../../images/products/roller-blinds/1.01-c.png";
import RollerBlindOnePointZeroTwoImageOne from "../../../images/products/roller-blinds/1.02a-a.jpg";
import RollerBlindOnePointZeroTwoImageTwo from "../../../images/products/roller-blinds/1.02a-b.jpg";
import RollerBlindOnePointZeroTwoImageThree from "../../../images/products/roller-blinds/1.02a-c.jpg";
import RollerBlindOnePointZeroTwoImageFour from "../../../images/products/roller-blinds/1.02a-d.jpg";
import RollerBlindOnePointZeroTwoImageFive from "../../../images/products/roller-blinds/1.02a-e.jpg";
import RollerBlindOnePointZeroTwoImageSix from "../../../images/products/roller-blinds/1.02a-f.jpg";
import RollerBlindOnePointZeroThreeImageOne from "../../../images/products/roller-blinds/1.03a-a.jpeg";
import RollerBlindOnePointZeroThreeImageTwo from "../../../images/products/roller-blinds/1.03a-b.jpeg";
import RollerBlindOnePointZeroThreeImageThree from "../../../images/products/roller-blinds/1.03a-c.png";
import RollerBlindOnePointZeroFourImageOne from "../../../images/products/roller-blinds/1.04a-a.jpg";
import RollerBlindOnePointZeroFourImageTwo from "../../../images/products/roller-blinds/1.04a-b.png";
import RollerBlindOnePointZeroFiveImageOne from "../../../images/products/roller-blinds/1.05-a.jpg";
import RollerBlindOnePointZeroFiveImageTwo from "../../../images/products/roller-blinds/1.05-b.jpeg";
import RollerBlindOnePointZeroFiveImageThree from "../../../images/products/roller-blinds/1.05-c.jpeg";
import RollerBlindOnePointZeroFiveImageFour from "../../../images/products/roller-blinds/1.05-d.jpeg";
import RollerBlindOnePointZeroFiveImageFive from "../../../images/products/roller-blinds/1.05-e.jpeg";
import RollerBlindOnePointZeroFiveImageSix from "../../../images/products/roller-blinds/1.05-f.jpg";
import RollerBlindOnePointZeroSixImageOne from "../../../images/products/roller-blinds/1.06-a.jpg";
import RollerBlindOnePointZeroSixImageTwo from "../../../images/products/roller-blinds/1.06-b.jpg";
import RollerBlindOnePointZeroSixImageThree from "../../../images/products/roller-blinds/1.06-c.jpg";
import RollerBlindOnePointZeroSixImageFour from "../../../images/products/roller-blinds/1.06-d.jpg";
import RollerBlindOnePointZeroSevenImageOne from "../../../images/products/roller-blinds/1.07-a.jpeg";
import RollerBlindOnePointZeroSevenImageTwo from "../../../images/products/roller-blinds/1.07-b.jpeg";
import RollerBlindOnePointZeroSevenImageThree from "../../../images/products/roller-blinds/1.07-c.jpeg";
import RollerBlindOnePointZeroSevenImageFour from "../../../images/products/roller-blinds/1.07-d.jpeg";
import RollerBlindOnePointZeroEightImageOne from "../../../images/products/roller-blinds/1.08-a.jpeg";
import RollerBlindOnePointZeroEightImageTwo from "../../../images/products/roller-blinds/1.08-b.jpeg";
import RollerBlindOnePointZeroNineImageOne from "../../../images/products/roller-blinds/1.09-a.jpeg";
import RollerBlindOnePointZeroNineImageTwo from "../../../images/products/roller-blinds/1.09-b.jpeg";
import RollerBlindOnePointZeroNineImageThree from "../../../images/products/roller-blinds/1.09-c.jpeg";
import RollerBlindOnePointZeroTenImageOne from "../../../images/products/roller-blinds/1.10-a.jpeg";
import RollerBlindOnePointZeroTenImageTwo from "../../../images/products/roller-blinds/1.10-b.jpeg";
import RollerBlindOnePointZeroElevenImageOne from "../../../images/products/roller-blinds/1.11a-a.jpg";
import RollerBlindOnePointZeroElevenImageTwo from "../../../images/products/roller-blinds/1.11a-b.jpg";
import RollerBlindOnePointZeroElevenImageThree from "../../../images/products/roller-blinds/1.11a-c.jpg";
import RollerBlindOnePointZeroElevenImageFour from "../../../images/products/roller-blinds/1.11a-d.jpeg";
import RollerBlindOnePointZeroElevenImageFive from "../../../images/products/roller-blinds/1.11b-a.jpeg";
import RollerBlindOnePointZeroElevenImageSix from "../../../images/products/roller-blinds/1.11b-b.jpeg";
import RollerBlindOnePointZeroElevenImageSeven from "../../../images/products/roller-blinds/1.11b-c.jpeg";
import RollerBlindOnePointZeroElevenImageEight from "../../../images/products/roller-blinds/1.11c-a.jpeg";
import RollerBlindOnePointZeroTwelveImageOne from "../../../images/products/roller-blinds/1.12a-a.jpg";
import RollerBlindOnePointZeroTwelveImageTwo from "../../../images/products/roller-blinds/1.12a-b.jpeg";
import RollerBlindOnePointZeroTwelveImageThree from "../../../images/products/roller-blinds/1.12a-c.jpeg";
import RollerBlindOnePointZeroTwelveImageFour from "../../../images/products/roller-blinds/1.12a-d.jpeg";
import RollerBlindOnePointZeroTwelveImageFive from "../../../images/products/roller-blinds/1.12b-a.jpg";
import RollerBlindOnePointZeroTwelveImageSix from "../../../images/products/roller-blinds/1.12b-b.png";
import RollerBlindOnePointZeroThirteenImageOne from "../../../images/products/roller-blinds/1.13a-a.jpg";
import RollerBlindOnePointZeroThirteenImageTwo from "../../../images/products/roller-blinds/1.13a-b.jpg";
import RollerBlindOnePointZeroThirteenImageThree from "../../../images/products/roller-blinds/1.13a-c.jpg";
import RollerBlindOnePointZeroThirteenImageFour from "../../../images/products/roller-blinds/1.13b-a.jpg";
import RollerBlindOnePointZeroThirteenImageFive from "../../../images/products/roller-blinds/1.13b-b.jpg";
import RollerBlindOnePointZeroThirteenImageSix from "../../../images/products/roller-blinds/1.13c-a.jpg";
import RollerBlindOnePointZeroThirteenImageSeven from "../../../images/products/roller-blinds/1.13c-b.jpg";
import RollerBlindOnePointZeroThirteenImageEight from "../../../images/products/roller-blinds/1.13d-a.png";
import RollerBlindOnePointZeroFourteenImageOne from "../../../images/products/roller-blinds/1.14-a.jpeg";
import RollerBlindOnePointZeroFourteenImageTwo from "../../../images/products/roller-blinds/1.14-b.jpeg";
import RollerBlindOnePointZeroFourteenImageThree from "../../../images/products/roller-blinds/1.14-c.jpeg";
import RollerBlindOnePointZeroSixteenImageOne from "../../../images/products/roller-blinds/1.16-a.jpeg";
import RollerBlindOnePointZeroSixteenImageTwo from "../../../images/products/roller-blinds/1.16-b.jpeg";
import RollerBlindOnePointZeroSixteenImageThree from "../../../images/products/roller-blinds/1.16-c.jpeg";
import RollerBlindOnePointZeroSeventeenImageOne from "../../../images/products/roller-blinds/1.17-a.jpeg";
import RollerBlindOnePointZeroSeventeenImageTwo from "../../../images/products/roller-blinds/1.17-b.jpeg";
import RollerBlindOnePointZeroSeventeenImageThree from "../../../images/products/roller-blinds/1.17-c.jpeg";
import RollerBlindOnePointZeroTwentyOneImageOne from "../../../images/products/roller-blinds/1.21-a.png";
import RollerBlindOnePointZeroTwentyOneImageTwo from "../../../images/products/roller-blinds/1.21-b.png";
//Blinds Fabrics
import ChanellTranslucentCHARCOAL from "../../../images/products/roller-blinds/chanell_translucent_charcoal.jpg";
import ChanellTranslucentEBONY1 from "../../../images/products/roller-blinds/chanell_translucent_ebony1.jpg";
import ChanellTranslucentEcoALLURE from "../../../images/products/roller-blinds/chanell_translucent_eco_allure.jpg";
import ChanellTranslucentEcoCHANCE from "../../../images/products/roller-blinds/chanell_translucent_eco_chance.jpg";
import ChanellTranslucentEcoEGOISTE from "../../../images/products/roller-blinds/chanell_translucent_eco_egoiste.jpg";
import ChanellTranslucentEcoMADEMOISELLE from "../../../images/products/roller-blinds/chanell_translucent_eco_mademoiselle.jpg";
import ChanellTranslucentEcoMAIN from "../../../images/products/roller-blinds/chanell_translucent_eco_main.png";
import ChanellTranslucentEcoOBSESSION from "../../../images/products/roller-blinds/chanell_translucent_eco_obsession.jpg";
import ChanellTranslucentEcoPLATINUM from "../../../images/products/roller-blinds/chanell_translucent_eco_platinum.png";
import ChanellTranslucentLIMESTONE from "../../../images/products/roller-blinds/chanell_translucent_limestone.jpg";
import ChanellTranslucentLUSTRE from "../../../images/products/roller-blinds/chanell_translucent_lustre.jpg";
import ChanellTranslucentMAIN from "../../../images/products/roller-blinds/chanell_translucent_main.png";
import ChanellTranslucentPALLADIUM from "../../../images/products/roller-blinds/chanell_translucent_palladium.png";
import ChanellTranslucentWHITE from "../../../images/products/roller-blinds/chanell_translucent_white.jpg";
import EloquentRoomDarkeningCLOUDWHITE from "../../../images/products/roller-blinds/eloquent_room_darkening_cloudwhite.jpg";
import EloquentRoomDarkeningMAIN from "../../../images/products/roller-blinds/eloquent_room_darkening_main.png";
import EloquentRoomDarkeningMETROPOLIS from "../../../images/products/roller-blinds/eloquent_room_darkening_metropolis.jpg";
import EloquentRoomDarkeningPHANTOM from "../../../images/products/roller-blinds/eloquent_room_darkening_phantom.jpg";
import EloquentRoomDarkeningREFLECTION from "../../../images/products/roller-blinds/eloquent_room_darkening_reflection.jpg";
import EloquentRoomDarkeningSTONNINGTON from "../../../images/products/roller-blinds/eloquent_room_darkening_stonnington.jpg";
import EloquentRoomDarkeningURBAN from "../../../images/products/roller-blinds/eloquent_room_darkening_urban.jpg";
import EloquentRoomDarkeningVINTAGE from "../../../images/products/roller-blinds/eloquent_room_darkening_vintage.jpg";
import EuroBlocRoomDarkeningCARAMEL from "../../../images/products/roller-blinds/euro_bloc_room_darkening_caramel.jpg";
import EuroBlocRoomDarkeningCINNAMON from "../../../images/products/roller-blinds/euro_bloc_room_darkening_cinnamon.jpg";
import EuroBlocRoomDarkeningHAZE from "../../../images/products/roller-blinds/euro_bloc_room_darkening_haze.jpg";
import EuroBlocRoomDarkeningICE from "../../../images/products/roller-blinds/euro_bloc_room_darkening_ice.jpg";
import EuroBlocRoomDarkeningIRONSTONE from "../../../images/products/roller-blinds/euro_bloc_room_darkening_ironstone.jpg";
import EuroBlocRoomDarkeningMAGNESIUM from "../../../images/products/roller-blinds/euro_bloc_room_darkening_magnesium.jpg";
import EuroBlocRoomDarkeningMAIN from "../../../images/products/roller-blinds/euro_bloc_room_darkening_main.png";
import EuroBlocRoomDarkeningNOIR from "../../../images/products/roller-blinds/euro_bloc_room_darkening_noir.jpg";
import EuroBlocRoomDarkeningSILK from "../../../images/products/roller-blinds/euro_bloc_room_darkening_silk.jpg";
import EuroScreenEcoMetallisedTransparentCHICSHADOW from "../../../images/products/roller-blinds/euroscreen_eco_metallised_transparent_chicshadow.jpg";
import EuroScreenEcoMetallisedTransparentCOVE from "../../../images/products/roller-blinds/euroscreen_eco_metallised_transparent_cove.jpg";
import EuroScreenEcoMetallisedTransparentHORIZON from "../../../images/products/roller-blinds/euroscreen_eco_metallised_transparent_horizon.jpg";
import EuroScreenEcoMetallisedTransparentMAIN from "../../../images/products/roller-blinds/euroscreen_eco_metallised_transparent_main.png";
import EuroScreenEcoMetallisedTransparentMIDNIGHT from "../../../images/products/roller-blinds/euroscreen_eco_metallised_transparent_midnight.jpg";
import EuroScreenEcoMetallisedTransparentSNOWBANK from "../../../images/products/roller-blinds/euroscreen_eco_metallised_transparent_snowbank.jpg";
import EuroScreenEcoMetallisedTransparentWHITEWISP from "../../../images/products/roller-blinds/euroscreen_eco_metallised_transparent_whitewisp.jpg";
import EuroScreenEcoTransparentAMMO1 from "../../../images/products/roller-blinds/euroscreen_eco_transparent_ammo1.jpg";
import EuroScreenEcoTransparentBLANC from "../../../images/products/roller-blinds/euroscreen_eco_transparent_blanc.jpg";
import EuroScreenEcoTransparentGUNMETAL1 from "../../../images/products/roller-blinds/euroscreen_eco_transparent_gunmetal1.jpg";
import EuroScreenEcoTransparentMAIN from "../../../images/products/roller-blinds/euroscreen_eco_transparent_main.png";
import EuroScreenEcoTransparentNERO from "../../../images/products/roller-blinds/euroscreen_eco_transparent_nero.jpg";
import EuroScreenEcoTransparentPEBBLE from "../../../images/products/roller-blinds/euroscreen_eco_transparent_pebble.jpg";
import EuroScreenEcoTransparentRIVERSTONE from "../../../images/products/roller-blinds/euroscreen_eco_transparent_riverstone.jpg";
import EuroScreenEcoTransparentSMOKE from "../../../images/products/roller-blinds/euroscreen_eco_transparent_smoke.jpg";
import EuroScreenEcoTransparentWHISPER from "../../../images/products/roller-blinds/euroscreen_eco_transparent_whisper.jpg";
import EuroScreenMetallisedTransparentANTIQUE from "../../../images/products/roller-blinds/euroscreen_metallised_transparent_antique.jpg";
import EuroScreenMetallisedTransparentBLACK2 from "../../../images/products/roller-blinds/euroscreen_metallised_transparent_black2.jpg";
import EuroScreenMetallisedTransparentGRAVEL from "../../../images/products/roller-blinds/euroscreen_metallised_transparent_gravel.jpg";
import EuroScreenMetallisedTransparentMAIN from "../../../images/products/roller-blinds/euroscreen_metallised_transparent_main.png";
import EuroScreenMetallisedTransparentOXIDE from "../../../images/products/roller-blinds/euroscreen_metallised_transparent_oxide.jpg";
import EuroScreenMetallisedTransparentPEARL from "../../../images/products/roller-blinds/euroscreen_metallised_transparent_pearl.jpg";
import EuroScreenMetallisedTransparentPOTTERY from "../../../images/products/roller-blinds/euroscreen_metallised_transparent_pottery.jpg";
import EuroScreenTransparentAMMO1 from "../../../images/products/roller-blinds/euroscreen_transparent_ammo1.jpg";
import EuroScreenTransparentBLANC from "../../../images/products/roller-blinds/euroscreen_transparent_blanc.jpg";
import EuroScreenTransparentGUNMETAL1 from "../../../images/products/roller-blinds/euroscreen_transparent_gunmetal1.jpg";
import EuroScreenTransparentMAIN from "../../../images/products/roller-blinds/euroscreen_transparent_main.png";
import EuroScreenTransparentNERO from "../../../images/products/roller-blinds/euroscreen_transparent_nero.jpg";
import EuroScreenTransparentPEBBLE from "../../../images/products/roller-blinds/euroscreen_transparent_pebble.jpg";
import EuroScreenTransparentRIVERSTONE from "../../../images/products/roller-blinds/euroscreen_transparent_riverstone.jpg";
import EuroScreenTransparentSMOKE from "../../../images/products/roller-blinds/euroscreen_transparent_smoke.jpg";
import EuroScreenTransparentWHISPER from "../../../images/products/roller-blinds/euroscreen_transparent_whisper.jpg";
import EuroViewTransparentCLAY from "../../../images/products/roller-blinds/euroview_transparent_clay.jpg";
import EuroViewTransparentCOAL from "../../../images/products/roller-blinds/euroview_transparent_coal.jpg";
import EuroViewTransparentDOVE from "../../../images/products/roller-blinds/euroview_transparent_dove.jpg";
import EuroViewTransparentMAIN from "../../../images/products/roller-blinds/euroview_transparent_main.png";
import EuroViewTransparentRICE from "../../../images/products/roller-blinds/euroview_transparent_rice.jpg";
import EuroViewTransparentSPACE from "../../../images/products/roller-blinds/euroview_transparent_space.jpg";
import EuroViewTransparentWHITE from "../../../images/products/roller-blinds/euroview_transparent_white.jpg";
import EuroVisionMetallisedTransparentHELSINKI from "../../../images/products/roller-blinds/eurovision_metallised_transparent_helsinki.jpg";
import EuroVisionMetallisedTransparentISTANBUL from "../../../images/products/roller-blinds/eurovision_metallised_transparent_istanbul.jpg";
import EuroVisionMetallisedTransparentKIEV from "../../../images/products/roller-blinds/eurovision_metallised_transparent_kiev.jpg";
import EuroVisionMetallisedTransparentMAIN from "../../../images/products/roller-blinds/eurovision_metallised_transparent_main.png";
import EuroVisionMetallisedTransparentZAGREB from "../../../images/products/roller-blinds/eurovision_metallised_transparent_zagreb.jpg";
import EuroVisionTransparentDUBLIN from "../../../images/products/roller-blinds/eurovision_transparent_dublin.jpg";
import EuroVisionTransparentEDINBURGH from "../../../images/products/roller-blinds/eurovision_transparent_edinburgh.jpg";
import EuroVisionTransparentMAIN from "../../../images/products/roller-blinds/eurovision_transparent_main.png";
import EuroVisionTransparentMUNICH from "../../../images/products/roller-blinds/eurovision_transparent_munich.jpg";
import EuroVisionTransparentOSLO from "../../../images/products/roller-blinds/eurovision_transparent_oslo.jpg";
import EvoscreenTransparentAZZURE from "../../../images/products/roller-blinds/evoscreen_transparent_azzure.jpg";
import EvoscreenTransparentINSPIRON from "../../../images/products/roller-blinds/evoscreen_transparent_inspiron.jpg";
import EvoscreenTransparentLUMINARE from "../../../images/products/roller-blinds/evoscreen_transparent_luminare.jpg";
import EvoscreenTransparentMAIN from "../../../images/products/roller-blinds/evoscreen_transparent_main.png";
import EvoscreenTransparentMOMENTUM from "../../../images/products/roller-blinds/evoscreen_transparent_momentum.jpg";
import EvoscreenTransparentPHANTOM from "../../../images/products/roller-blinds/evoscreen_transparent_phantom.jpg";
import EvoscreenTransparentSPECTRE from "../../../images/products/roller-blinds/evoscreen_transparent_spectre.jpg";
import FlamingoRoomDarkeningASH from "../../../images/products/roller-blinds/flamingo_room_darkening_ash.jpg";
import FlamingoRoomDarkeningBLACK from "../../../images/products/roller-blinds/flamingo_room_darkening_black.jpg";
import FlamingoRoomDarkeningCREAM from "../../../images/products/roller-blinds/flamingo_room_darkening_cream.jpg";
import FlamingoRoomDarkeningMAIN from "../../../images/products/roller-blinds/flamingo_room_darkening_main.jpg";
import FlamingoRoomDarkeningSILVER from "../../../images/products/roller-blinds/flamingo_room_darkening_silver.jpg";
import FlamingoRoomDarkeningSNOW from "../../../images/products/roller-blinds/flamingo_room_darkening_snow.jpg";
import FlamingoRoomDarkeningSTEEL from "../../../images/products/roller-blinds/flamingo_room_darkening_steel.jpg";
import FlamingoRoomDarkeningSTONE from "../../../images/products/roller-blinds/flamingo_room_darkening_stone.jpg";
import FlamingoRoomDarkeningWHITE from "../../../images/products/roller-blinds/flamingo_room_darkening_white.jpg";
import FlamingoTranslucentASH from "../../../images/products/roller-blinds/flamingo_translucent_ash.jpg";
import FlamingoTranslucentBLACK from "../../../images/products/roller-blinds/flamingo_translucent_black.jpg";
import FlamingoTranslucentCREAM from "../../../images/products/roller-blinds/flamingo_translucent_cream.jpg";
import FlamingoTranslucentMAIN from "../../../images/products/roller-blinds/flamingo_translucent_main.jpg";
import FlamingoTranslucentSILVER from "../../../images/products/roller-blinds/flamingo_translucent_silver.jpg";
import FlamingoTranslucentSNOW from "../../../images/products/roller-blinds/flamingo_translucent_snow.jpg";
import FlamingoTranslucentSTEEL from "../../../images/products/roller-blinds/flamingo_translucent_steel.jpg";
import FlamingoTranslucentSTONE from "../../../images/products/roller-blinds/flamingo_translucent_stone.jpg";
import FlamingoTranslucentWHITE from "../../../images/products/roller-blinds/flamingo_translucent_white.jpg";
import HamiltonRoomDarkeningALABASTER from "../../../images/products/roller-blinds/hamilton_room_darkening_alabaster.jpg";
import HamiltonRoomDarkeningANTHRACITE from "../../../images/products/roller-blinds/hamilton_room_darkening_anthracite.jpg";
import HamiltonRoomDarkeningCADMIUM from "../../../images/products/roller-blinds/hamilton_room_darkening_cadmium.jpg";
import HamiltonRoomDarkeningECRU from "../../../images/products/roller-blinds/hamilton_room_darkening_ecru.jpg";
import HamiltonRoomDarkeningGARDENIA from "../../../images/products/roller-blinds/hamilton_room_darkening_gardenia.jpg";
import HamiltonRoomDarkeningINK from "../../../images/products/roller-blinds/hamilton_room_darkening_ink.jpg";
import HamiltonRoomDarkeningMAIN from "../../../images/products/roller-blinds/hamilton_room_darkening_main.jpg";
import HamiltonRoomDarkeningMORNING from "../../../images/products/roller-blinds/hamilton_room_darkening_morning.jpg";
import HamiltonRoomDarkeningMOUSE from "../../../images/products/roller-blinds/hamilton_room_darkening_mouse.jpg";
import HamiltonRoomDarkeningNUTMEG from "../../../images/products/roller-blinds/hamilton_room_darkening_nutmeg.jpg";
import HamiltonRoomDarkeningRATTAN from "../../../images/products/roller-blinds/hamilton_room_darkening_rattan.jpg";
import JewellRoomDarkeningADVENTURINE from "../../../images/products/roller-blinds/jewell_room_darkening_adventurine.jpg";
import JewellRoomDarkeningHEMATITE from "../../../images/products/roller-blinds/jewell_room_darkening_hematite.jpg";
import JewellRoomDarkeningMAIN from "../../../images/products/roller-blinds/jewell_room_darkening_main.png";
import JewellRoomDarkeningTOPAZ from "../../../images/products/roller-blinds/jewell_room_darkening_topaz.jpg";
import JourneyRoomDarkeningASHMISTY from "../../../images/products/roller-blinds/journey_room_darkening_ashmisty.jpg";
import JourneyRoomDarkeningBAUXITEGRIT from "../../../images/products/roller-blinds/journey_room_darkening_bauxitegrit.jpg";
import JourneyRoomDarkeningBLEACHSNOWWHITE from "../../../images/products/roller-blinds/journey_room_darkening_bleachsnowwhite.jpg";
import JourneyRoomDarkeningGLACIERPAPER from "../../../images/products/roller-blinds/journey_room_darkening_glacierpaper.jpg";
import JourneyRoomDarkeningHESSIANPEAT from "../../../images/products/roller-blinds/journey_room_darkening_hessianpeat.jpg";
import JourneyRoomDarkeningMAIN from "../../../images/products/roller-blinds/journey_room_darkening_main.jpg";
import JourneyRoomDarkeningMALTSEED from "../../../images/products/roller-blinds/journey_room_darkening_maltseed.jpg";
import JourneyRoomDarkeningRAWCHALKSTONE from "../../../images/products/roller-blinds/journey_room_darkening_rawchalkstone.jpg";
import JourneyRoomDarkeningSTORMCARIBOU from "../../../images/products/roller-blinds/journey_room_darkening_stormcaribou.jpg";
import LexingtonRoomDarkeningGRAVEL from "../../../images/products/roller-blinds/lexington_room_darkening_gravel.jpg";
import LexingtonRoomDarkeningHAVANA from "../../../images/products/roller-blinds/lexington_room_darkening_havana.jpg";
import LexingtonRoomDarkeningJET from "../../../images/products/roller-blinds/lexington_room_darkening_jet.jpg";
import LexingtonRoomDarkeningMAIN from "../../../images/products/roller-blinds/lexington_room_darkening_main.jpg";
import LexingtonRoomDarkeningMIST from "../../../images/products/roller-blinds/lexington_room_darkening_mist.jpg";
import LexingtonRoomDarkeningPEWTER from "../../../images/products/roller-blinds/lexington_room_darkening_pewter.jpg";
import LexingtonRoomDarkeningPOLAR from "../../../images/products/roller-blinds/lexington_room_darkening_polar.jpg";
import LexingtonRoomDarkeningROSETTA from "../../../images/products/roller-blinds/lexington_room_darkening_rosetta.jpg";
import LexingtonRoomDarkeningSHALE from "../../../images/products/roller-blinds/lexington_room_darkening_shale.jpg";
import MadisonTwoRoomDarkeningALPACHA from "../../../images/products/roller-blinds/madison_two_room_darkening_alpacha.jpg";
import MadisonTwoRoomDarkeningBLUESTONE from "../../../images/products/roller-blinds/madison_two_room_darkening_bluestone.jpg";
import MadisonTwoRoomDarkeningCHAMPAGNE from "../../../images/products/roller-blinds/madison_two_room_darkening_champagne.jpg";
import MadisonTwoRoomDarkeningCONCRETE from "../../../images/products/roller-blinds/madison_two_room_darkening_concrete.jpg";
import MadisonTwoRoomDarkeningGHOST from "../../../images/products/roller-blinds/madison_two_room_darkening_ghost.jpg";
import MadisonTwoRoomDarkeningKNIGHT from "../../../images/products/roller-blinds/madison_two_room_darkening_knight.jpg";
import MadisonTwoRoomDarkeningLICORICE from "../../../images/products/roller-blinds/madison_two_room_darkening_licorice.jpg";
import MadisonTwoRoomDarkeningMAIN from "../../../images/products/roller-blinds/madison_two_room_darkening_main.png";
import MadisonTwoRoomDarkeningPLATINUM from "../../../images/products/roller-blinds/madison_two_room_darkening_platinum.jpg";
import MetaloMetallisedTransparentAMMO from "../../../images/products/roller-blinds/metalo_metallised_transparent_ammo.jpg";
import MetaloMetallisedTransparentGREY from "../../../images/products/roller-blinds/metalo_metallised_transparent_grey.jpg";
import MetaloMetallisedTransparentGUNMETAL from "../../../images/products/roller-blinds/metalo_metallised_transparent_gunmetal.jpg";
import MetaloMetallisedTransparentMAIN from "../../../images/products/roller-blinds/metalo_metallised_transparent_main.jpg";
import SilverstoneRoomDarkeningBALSASAMPLE from "../../../images/products/roller-blinds/silverstone_room_darkening_balsasample.jpg";
import SilverstoneRoomDarkeningCHINASAMPLE from "../../../images/products/roller-blinds/silverstone_room_darkening_chinasample.jpg";
import SilverstoneRoomDarkeningLINENSAMPLE from "../../../images/products/roller-blinds/silverstone_room_darkening_linensample.jpg";
import SilverstoneRoomDarkeningMAIN from "../../../images/products/roller-blinds/silverstone_room_darkening_main.jpg";
import SilverstoneRoomDarkeningSHALESSAMPLE from "../../../images/products/roller-blinds/silverstone_room_darkening_shalessample.jpg";
import SilverstoneRoomDarkeningTUXEDO from "../../../images/products/roller-blinds/silverstone_room_darkening_tuxedo.jpg";
import SorrentoRoomDarkeningBLAZER from "../../../images/products/roller-blinds/sorrento_room_darkening_blazer.jpg";
import SorrentoRoomDarkeningCHIFFON from "../../../images/products/roller-blinds/sorrento_room_darkening_chiffon.jpg";
import SorrentoRoomDarkeningCHROME from "../../../images/products/roller-blinds/sorrento_room_darkening_chrome.jpg";
import SorrentoRoomDarkeningEARLGREY from "../../../images/products/roller-blinds/sorrento_room_darkening_earlgrey.jpg";
import SorrentoRoomDarkeningMAIN from "../../../images/products/roller-blinds/sorrento_room_darkening_main.jpg";
import SorrentoRoomDarkeningOYSTER from "../../../images/products/roller-blinds/sorrento_room_darkening_oyster.jpg";
import SorrentoRoomDarkeningPORCELAIN from "../../../images/products/roller-blinds/sorrento_room_darkening_porcelain.jpg";
import SorrentoRoomDarkeningRAVEN from "../../../images/products/roller-blinds/sorrento_room_darkening_raven.jpg";
import SorrentoRoomDarkeningSAIL from "../../../images/products/roller-blinds/sorrento_room_darkening_sail.jpg";
import SorrentoRoomDarkeningSWAN from "../../../images/products/roller-blinds/sorrento_room_darkening_swan.jpg";
import SorrentoTranslucentAMALFIT from "../../../images/products/roller-blinds/sorrento_translucent_amalfit.jpg";
import SorrentoTranslucentBASILICATAT from "../../../images/products/roller-blinds/sorrento_translucent_basilicatat.jpg";
import SorrentoTranslucentCORSOT from "../../../images/products/roller-blinds/sorrento_translucent_corsot.jpg";
import SorrentoTranslucentGROTTOT from "../../../images/products/roller-blinds/sorrento_translucent_grottot.jpg";
import SorrentoTranslucentLUCANIAT from "../../../images/products/roller-blinds/sorrento_translucent_lucaniat.jpg";
import SorrentoTranslucentMAIN from "../../../images/products/roller-blinds/sorrento_translucent_main.jpg";
import SorrentoTranslucentPIAZZAT from "../../../images/products/roller-blinds/sorrento_translucent_piazzat.jpg";
import SorrentoTranslucentPOSITANOT from "../../../images/products/roller-blinds/sorrento_translucent_positanot.jpg";
import SorrentoTranslucentPOTENZAT from "../../../images/products/roller-blinds/sorrento_translucent_potenzat.jpg";
import SorrentoTranslucentRAVELLOT from "../../../images/products/roller-blinds/sorrento_translucent_ravellot.jpg";
import StatusFiveTransparentANCHOR from "../../../images/products/roller-blinds/status_five_transparent_anchor.png";
import StatusFiveTransparentARGENT from "../../../images/products/roller-blinds/status_five_transparent_argent.jpg";
import StatusFiveTransparentBIRCHER from "../../../images/products/roller-blinds/status_five_transparent_bircher.jpg";
import StatusFiveTransparentDAISY from "../../../images/products/roller-blinds/status_five_transparent_daisy.jpg";
import StatusFiveTransparentGRANITE from "../../../images/products/roller-blinds/status_five_transparent_granite.jpg";
import StatusFiveTransparentGRAPHITE from "../../../images/products/roller-blinds/status_five_transparent_graphite.jpg";
import StatusFiveTransparentHAZE from "../../../images/products/roller-blinds/status_five_transparent_haze.png";
import StatusFiveTransparentKERNAL from "../../../images/products/roller-blinds/status_five_transparent_kernal.jpg";
import StatusFiveTransparentLEAD from "../../../images/products/roller-blinds/status_five_transparent_lead.jpg";
import StatusFiveTransparentMAIN from "../../../images/products/roller-blinds/status_five_transparent_main.png";
import StatusFiveTransparentPORPHYRY from "../../../images/products/roller-blinds/status_five_transparent_porphyry.jpg";
import StatusFiveTransparentQUARTZ from "../../../images/products/roller-blinds/status_five_transparent_quartz.jpg";
import StatusFiveTransparentRICHCOPPER from "../../../images/products/roller-blinds/status_five_transparent_richcopper.jpg";
import StatusFiveTransparentSNOW from "../../../images/products/roller-blinds/status_five_transparent_snow.jpg";
import StatusTenTransparentBASALT from "../../../images/products/roller-blinds/status_ten_transparent_basalt.jpg";
import StatusTenTransparentCARBON from "../../../images/products/roller-blinds/status_ten_transparent_carbon.jpg";
import StatusTenTransparentCOPPER from "../../../images/products/roller-blinds/status_ten_transparent_copper.jpg";
import StatusTenTransparentCORNSILK from "../../../images/products/roller-blinds/status_ten_transparent_cornsilk.jpg";
import StatusTenTransparentFOG from "../../../images/products/roller-blinds/status_ten_transparent_fog.jpg";
import StatusTenTransparentGLACIER from "../../../images/products/roller-blinds/status_ten_transparent_glacier.jpg";
import StatusTenTransparentLIMESTONE from "../../../images/products/roller-blinds/status_ten_transparent_limestone.jpg";
import StatusTenTransparentMAGNOLIA from "../../../images/products/roller-blinds/status_ten_transparent_magnolia.jpg";
import StatusTenTransparentMAIN from "../../../images/products/roller-blinds/status_ten_transparent_main.png";
import StatusTenTransparentMARBLE from "../../../images/products/roller-blinds/status_ten_transparent_marble.jpg";
import StatusTenTransparentOATMEAL from "../../../images/products/roller-blinds/status_ten_transparent_oatmeal.jpg";
import StatusTenTransparentPITCH from "../../../images/products/roller-blinds/status_ten_transparent_pitch.jpg";
import StatusTenTransparentSTATUSGRAPNEL from "../../../images/products/roller-blinds/status_ten_transparent_statusgrapnel.png";
import StatusTenTransparentSTATUSMISTRAL from "../../../images/products/roller-blinds/status_ten_transparent_statusmistral.png";
import YangRoomDarkeningMAIN from "../../../images/products/roller-blinds/yang_room_darkening_main.jpg";
import YangRoomDarkeningYANGBOUNTY from "../../../images/products/roller-blinds/yang_room_darkening_yangbounty.png";
import YangRoomDarkeningYANGCASHMERE from "../../../images/products/roller-blinds/yang_room_darkening_yangcashmere.png";
import YangRoomDarkeningYANGCHALKWHITE from "../../../images/products/roller-blinds/yang_room_darkening_yangchalkwhite.png";
import YangRoomDarkeningYANGCHI from "../../../images/products/roller-blinds/yang_room_darkening_yangchi.png";
import YangRoomDarkeningYANGCOCOA from "../../../images/products/roller-blinds/yang_room_darkening_yangcocoa.png";
import YangRoomDarkeningYANGELEMENT from "../../../images/products/roller-blinds/yang_room_darkening_yangelement.png";
import YangRoomDarkeningYANGEMBRACE from "../../../images/products/roller-blinds/yang_room_darkening_yangembrace.png";
import YangRoomDarkeningYANGGAZING from "../../../images/products/roller-blinds/yang_room_darkening_yanggazing.png";
import YangRoomDarkeningYANGILLUSION from "../../../images/products/roller-blinds/yang_room_darkening_yangillusion.png";
import YangRoomDarkeningYANGIMPRESSION from "../../../images/products/roller-blinds/yang_room_darkening_yangimpression.png";
import YangRoomDarkeningYANGMARIO from "../../../images/products/roller-blinds/yang_room_darkening_yangmario.png";
import YangRoomDarkeningYANGMARTINI from "../../../images/products/roller-blinds/yang_room_darkening_yangmartini.png";
import YangRoomDarkeningYANGMINERAL from "../../../images/products/roller-blinds/yang_room_darkening_yangmineral.png";
import YangRoomDarkeningYANGMOUNTAINMIST from "../../../images/products/roller-blinds/yang_room_darkening_yangmountainmist.png";
import YangRoomDarkeningYANGSAHARA from "../../../images/products/roller-blinds/yang_room_darkening_yangsahara.png";
import YangRoomDarkeningYANGSTEALTH from "../../../images/products/roller-blinds/yang_room_darkening_yangstealth.png";
import YangRoomDarkeningYANGWHITEDOVE from "../../../images/products/roller-blinds/yang_room_darkening_yangwhitedove.png";
import YangRoomDarkeningYANGYEN from "../../../images/products/roller-blinds/yang_room_darkening_yangyen.png";
import YangRoomDarkeningYANGZELDA from "../../../images/products/roller-blinds/yang_room_darkening_yangzelda.png";
import ZingRoomDarkeningALBATROSS from "../../../images/products/roller-blinds/zing_room_darkening_albatross.jpg";
import ZingRoomDarkeningBISQU from "../../../images/products/roller-blinds/zing_room_darkening_bisqu.jpg";
import ZingRoomDarkeningDOVER from "../../../images/products/roller-blinds/zing_room_darkening_dover.jpg";
import ZingRoomDarkeningFLAX from "../../../images/products/roller-blinds/zing_room_darkening_flax.jpg";
import ZingRoomDarkeningLUNARWHITE from "../../../images/products/roller-blinds/zing_room_darkening_lunarwhite.jpg";
import ZingRoomDarkeningMACAW from "../../../images/products/roller-blinds/zing_room_darkening_macaw.jpg";
import ZingRoomDarkeningMAIN from "../../../images/products/roller-blinds/zing_room_darkening_main.jpg";
import ZingRoomDarkeningNIGHTHAWK from "../../../images/products/roller-blinds/zing_room_darkening_nighthawk.jpg";
import ZingRoomDarkeningSHADOW from "../../../images/products/roller-blinds/zing_room_darkening_shadow.jpg";
import ZingRoomDarkeningTWILIGHT from "../../../images/products/roller-blinds/zing_room_darkening_twilight.jpg";
import ZingTranslucentALBATROSS from "../../../images/products/roller-blinds/zing_translucent_albatross.jpg";
import ZingTranslucentBISQU from "../../../images/products/roller-blinds/zing_translucent_bisqu.jpg";
import ZingTranslucentDOVER from "../../../images/products/roller-blinds/zing_translucent_dover.jpg";
import ZingTranslucentFLAX from "../../../images/products/roller-blinds/zing_translucent_flax.jpg";
import ZingTranslucentLUNARWHITE from "../../../images/products/roller-blinds/zing_translucent_lunarwhite.jpg";
import ZingTranslucentMACAW from "../../../images/products/roller-blinds/zing_translucent_macaw.jpg";
import ZingTranslucentMAIN from "../../../images/products/roller-blinds/zing_translucent_main.jpg";
import ZingTranslucentNIGHTHAWK from "../../../images/products/roller-blinds/zing_translucent_nighthawk.jpg";
import ZingTranslucentSHADOW from "../../../images/products/roller-blinds/zing_translucent_shadow.jpg";
import ZingTranslucentTWILIGHT from "../../../images/products/roller-blinds/zing_translucent_twilight.jpg";

//Curtains Systems
import CurtainImage from "../../../images/curtains.jpg";
import Arco490a from "../../../images/products/curtains/arco_490_a.jpeg";
import Arco490b from "../../../images/products/curtains/arco_490_b.jpg";
import Ego480a from "../../../images/products/curtains/ego_480_a.jpg";
import Ego480b from "../../../images/products/curtains/ego_480_b.jpg";
import Ego480c from "../../../images/products/curtains/ego_480_c.jpg";
import Ego480d from "../../../images/products/curtains/ego_480_d.jpg";
import Lunaa from "../../../images/products/curtains/luna_a.jpg";
import Lunab from "../../../images/products/curtains/luna_b.jpg";
import Multiplo412a from "../../../images/products/curtains/multiplo_412_a.jpeg";
import Multiplo412b from "../../../images/products/curtains/multiplo_412_b.jpg";
import Power541a from "../../../images/products/curtains/power_541_a.jpg";
import Power541b from "../../../images/products/curtains/power_541_b.jpg";
import Power541c from "../../../images/products/curtains/power_541_c.jpg";
import Power571a from "../../../images/products/curtains/power_571_a.jpg";
import Power571b from "../../../images/products/curtains/power_571_b.jpg";
import Power571c from "../../../images/products/curtains/power_571_c.jpg";
import Power571d from "../../../images/products/curtains/power_571_d.jpg";
import Power571e from "../../../images/products/curtains/power_571_e.jpg";
import Separe458a from "../../../images/products/curtains/separe_458_a.jpg";
import Separe458b from "../../../images/products/curtains/separe_458_b.jpg";
import Separe458c from "../../../images/products/curtains/separe_458_c.jpg";
import Separe458d from "../../../images/products/curtains/separe_458_d.jpg";

//Curtains Fabrics
import DelosMAIN from "../../../images/products/curtains/delos_main.jpg";
import EtoileSheerMAIN from "../../../images/products/curtains/etoile_sheer_main.jpg";
import EuroniteFR1123_01A from "../../../images/products/curtains/euronite_fr_1123_01a.png";
import EuroniteFR1123_01B from "../../../images/products/curtains/euronite_fr_1123_01b.jpg";
import EuroniteFR1123_02A from "../../../images/products/curtains/euronite_fr_1123_02a.jpg";
import EuroniteFR1123_02B from "../../../images/products/curtains/euronite_fr_1123_02b.jpg";
import EuroniteFR1123_03A from "../../../images/products/curtains/euronite_fr_1123_03a.jpg";
import EuroniteFR1123_03B from "../../../images/products/curtains/euronite_fr_1123_03b.jpg";
import EuroniteFR1123_04A from "../../../images/products/curtains/euronite_fr_1123_04a.png";
import EuroniteFR1123_04B from "../../../images/products/curtains/euronite_fr_1123_04b.jpg";
import EuroniteFR1123_05A from "../../../images/products/curtains/euronite_fr_1123_05a.jpg";
import EuroniteFR1123_05B from "../../../images/products/curtains/euronite_fr_1123_05b.jpg";
import EuroniteFR1123_06A from "../../../images/products/curtains/euronite_fr_1123_06a.jpg";
import EuroniteFR1123_06B from "../../../images/products/curtains/euronite_fr_1123_06b.jpg";
import EuroniteFR1123_07A from "../../../images/products/curtains/euronite_fr_1123_07a.jpg";
import EuroniteFR1123_07B from "../../../images/products/curtains/euronite_fr_1123_07b.jpg";
import EuroniteFR1123_08A from "../../../images/products/curtains/euronite_fr_1123_08a.jpg";
import EuroniteFR1123_08B from "../../../images/products/curtains/euronite_fr_1123_08b.jpg";
import EuroniteFR1123_09A from "../../../images/products/curtains/euronite_fr_1123_09a.jpg";
import EuroniteFR1123_09B from "../../../images/products/curtains/euronite_fr_1123_09b.jpg";
import EuroniteFR1123_10A from "../../../images/products/curtains/euronite_fr_1123_10a.jpg";
import EuroniteFR1123_10B from "../../../images/products/curtains/euronite_fr_1123_10b.jpg";
import EuroniteFR1123_11A from "../../../images/products/curtains/euronite_fr_1123_11a.jpg";
import EuroniteFR1123_11B from "../../../images/products/curtains/euronite_fr_1123_11b.jpg";
import EuroniteFR1123_12A from "../../../images/products/curtains/euronite_fr_1123_12a.jpg";
import EuroniteFR1123_12B from "../../../images/products/curtains/euronite_fr_1123_12b.png";
import EuroniteFR1123_13A from "../../../images/products/curtains/euronite_fr_1123_13a.png";
import EuroniteFR1123_13B from "../../../images/products/curtains/euronite_fr_1123_13b.png";
import EuroniteFR1123_14A from "../../../images/products/curtains/euronite_fr_1123_14a.jpg";
import EuroniteFR1123_14B from "../../../images/products/curtains/euronite_fr_1123_14b.png";
import EuroniteFR1123_15A from "../../../images/products/curtains/euronite_fr_1123_15a.jpg";
import EuroniteFR1123_15B from "../../../images/products/curtains/euronite_fr_1123_15b.jpg";
import EuroniteFR1123_16A from "../../../images/products/curtains/euronite_fr_1123_16a.jpg";
import EuroniteFR1123_16B from "../../../images/products/curtains/euronite_fr_1123_16b.jpg";
import EuroniteFR1123_17A from "../../../images/products/curtains/euronite_fr_1123_17a.jpg";
import EuroniteFR1123_17B from "../../../images/products/curtains/euronite_fr_1123_17b.jpg";
import EuroniteFR1123_18A from "../../../images/products/curtains/euronite_fr_1123_18a.png";
import EuroniteFR1123_18B from "../../../images/products/curtains/euronite_fr_1123_18b.jpg";
import EuroniteFRMAIN from "../../../images/products/curtains/euronite_fr_main.jpg";
import ExcelsiorMAIN from "../../../images/products/curtains/excelsior_main.jpg";
import LustreSheerCHAMPAGNE from "../../../images/products/curtains/lustre_sheer_champagne.jpg";
import LustreSheerGLACIER from "../../../images/products/curtains/lustre_sheer_glacier.jpg";
import LustreSheerIVORY from "../../../images/products/curtains/lustre_sheer_ivory.jpg";
import LustreSheerLIMESTONE from "../../../images/products/curtains/lustre_sheer_limestone.jpg";
import LustreSheerMAIN from "../../../images/products/curtains/lustre_sheer_main.jpg";
import LustreSheerMINERALASH from "../../../images/products/curtains/lustre_sheer_mineralash.jpg";
import LustreSheerMOONSHINE from "../../../images/products/curtains/lustre_sheer_moonshine.jpg";
import LustreSheerONYX from "../../../images/products/curtains/lustre_sheer_onyx.jpg";
import LustreSheerPEARL from "../../../images/products/curtains/lustre_sheer_pearl.png";
import LustreSheerRUSTIQUE from "../../../images/products/curtains/lustre_sheer_rustique.jpg";
import LustreSheerSMOKYQUARTZ from "../../../images/products/curtains/lustre_sheer_smokyquartz.jpg";
import LustreSheerSNOWFLAKE from "../../../images/products/curtains/lustre_sheer_snowflake.png";
import LustreSheerTITANIUM from "../../../images/products/curtains/lustre_sheer_titanium.jpg";
import MetalliqueAZTECSILVER from "../../../images/products/curtains/metallique_aztecsilver.jpg";
import MetalliqueGALAXY from "../../../images/products/curtains/metallique_galaxy.jpg";
import MetalliqueMAIN from "../../../images/products/curtains/metallique_main.jpg";
import MetalliqueMAYANGOLD from "../../../images/products/curtains/metallique_mayangold.jpg";
import MetalliqueSTARDUST from "../../../images/products/curtains/metallique_stardust.jpg";
import MotturaMasterOne3102 from "../../../images/products/curtains/mottura_master_one_3102.jpg";
import MotturaMasterOne3103 from "../../../images/products/curtains/mottura_master_one_3103.jpg";
import MotturaMasterOne3104 from "../../../images/products/curtains/mottura_master_one_3104.jpg";
import MotturaMasterOne3105 from "../../../images/products/curtains/mottura_master_one_3105.jpg";
import MotturaMasterOne3106 from "../../../images/products/curtains/mottura_master_one_3106.jpg";
import MotturaMasterOne3107 from "../../../images/products/curtains/mottura_master_one_3107.jpg";
import MotturaMasterOne3108 from "../../../images/products/curtains/mottura_master_one_3108.jpg";
import MotturaMasterOne3109 from "../../../images/products/curtains/mottura_master_one_3109.jpg";
import MotturaMasterOne3110 from "../../../images/products/curtains/mottura_master_one_3110.jpg";
import MotturaMasterOne3111 from "../../../images/products/curtains/mottura_master_one_3111.jpg";
import MotturaMasterOne3113 from "../../../images/products/curtains/mottura_master_one_3113.jpg";
import MotturaMasterOne3123 from "../../../images/products/curtains/mottura_master_one_3123.jpg";
import MotturaMasterOne3124 from "../../../images/products/curtains/mottura_master_one_3124.jpg";
import MotturaMasterOne3125 from "../../../images/products/curtains/mottura_master_one_3125.jpg";
import MotturaMasterOne3127 from "../../../images/products/curtains/mottura_master_one_3127.jpg";
import MotturaMasterOne3138 from "../../../images/products/curtains/mottura_master_one_3138.jpg";
import MotturaMasterOne3141 from "../../../images/products/curtains/mottura_master_one_3141.jpg";
import MotturaMasterOne3143 from "../../../images/products/curtains/mottura_master_one_3143.jpg";
import MotturaMasterOne3146 from "../../../images/products/curtains/mottura_master_one_3146.jpg";
import MotturaMasterOne3151 from "../../../images/products/curtains/mottura_master_one_3151.jpg";
import MotturaMasterOne3155 from "../../../images/products/curtains/mottura_master_one_3155.jpg";
import MotturaMasterOne3156 from "../../../images/products/curtains/mottura_master_one_3156.jpg";
import MotturaMasterOne3157 from "../../../images/products/curtains/mottura_master_one_3157.jpg";
import MotturaMasterOne3159 from "../../../images/products/curtains/mottura_master_one_3159.jpg";
import MotturaMasterOne3161 from "../../../images/products/curtains/mottura_master_one_3161.jpg";
import MotturaMasterOne3164 from "../../../images/products/curtains/mottura_master_one_3164.jpg";
import MotturaMasterOne3165 from "../../../images/products/curtains/mottura_master_one_3165.jpg";
import MotturaMasterOne3166 from "../../../images/products/curtains/mottura_master_one_3166.jpg";
import MotturaMasterOne3167 from "../../../images/products/curtains/mottura_master_one_3167.jpg";
import MotturaMasterOne3168 from "../../../images/products/curtains/mottura_master_one_3168.jpg";
import MotturaMasterOne3169 from "../../../images/products/curtains/mottura_master_one_3169.jpg";
import MotturaMasterOne3170 from "../../../images/products/curtains/mottura_master_one_3170.jpg";
import MotturaMasterOne3173 from "../../../images/products/curtains/mottura_master_one_3173.jpg";
import MotturaMasterOne3174 from "../../../images/products/curtains/mottura_master_one_3174.jpg";
import MotturaMasterOne3182 from "../../../images/products/curtains/mottura_master_one_3182.jpg";
import MotturaMasterOneMAIN from "../../../images/products/curtains/mottura_master_one_main.jpg";
import MotturaMasterTwo3002 from "../../../images/products/curtains/mottura_master_two_3002.jpg";
import MotturaMasterTwo3003 from "../../../images/products/curtains/mottura_master_two_3003.jpg";
import MotturaMasterTwo3004 from "../../../images/products/curtains/mottura_master_two_3004.jpg";
import MotturaMasterTwo3005 from "../../../images/products/curtains/mottura_master_two_3005.jpg";
import MotturaMasterTwo3006 from "../../../images/products/curtains/mottura_master_two_3006.jpg";
import MotturaMasterTwo3007 from "../../../images/products/curtains/mottura_master_two_3007.jpg";
import MotturaMasterTwo3008 from "../../../images/products/curtains/mottura_master_two_3008.jpg";
import MotturaMasterTwo3009 from "../../../images/products/curtains/mottura_master_two_3009.jpg";
import MotturaMasterTwo3010 from "../../../images/products/curtains/mottura_master_two_3010.jpg";
import MotturaMasterTwo3011 from "../../../images/products/curtains/mottura_master_two_3011.jpg";
import MotturaMasterTwo3013 from "../../../images/products/curtains/mottura_master_two_3013.jpg";
import MotturaMasterTwo3023 from "../../../images/products/curtains/mottura_master_two_3023.jpg";
import MotturaMasterTwo3024 from "../../../images/products/curtains/mottura_master_two_3024.jpg";
import MotturaMasterTwo3025 from "../../../images/products/curtains/mottura_master_two_3025.jpg";
import MotturaMasterTwo3027 from "../../../images/products/curtains/mottura_master_two_3027.jpg";
import MotturaMasterTwo3038 from "../../../images/products/curtains/mottura_master_two_3038.jpg";
import MotturaMasterTwo3041 from "../../../images/products/curtains/mottura_master_two_3041.jpg";
import MotturaMasterTwo3043 from "../../../images/products/curtains/mottura_master_two_3043.jpg";
import MotturaMasterTwo3046 from "../../../images/products/curtains/mottura_master_two_3046.jpg";
import MotturaMasterTwo3051 from "../../../images/products/curtains/mottura_master_two_3051.jpg";
import MotturaMasterTwo3055 from "../../../images/products/curtains/mottura_master_two_3055.jpg";
import MotturaMasterTwo3056 from "../../../images/products/curtains/mottura_master_two_3056.jpg";
import MotturaMasterTwo3057 from "../../../images/products/curtains/mottura_master_two_3057.jpg";
import MotturaMasterTwo3059 from "../../../images/products/curtains/mottura_master_two_3059.jpg";
import MotturaMasterTwo3061 from "../../../images/products/curtains/mottura_master_two_3061.jpg";
import MotturaMasterTwo3064 from "../../../images/products/curtains/mottura_master_two_3064.jpg";
import MotturaMasterTwo3065 from "../../../images/products/curtains/mottura_master_two_3065.jpg";
import MotturaMasterTwo3066 from "../../../images/products/curtains/mottura_master_two_3066.jpg";
import MotturaMasterTwo3067 from "../../../images/products/curtains/mottura_master_two_3067.jpg";
import MotturaMasterTwo3068 from "../../../images/products/curtains/mottura_master_two_3068.jpg";
import MotturaMasterTwo3069 from "../../../images/products/curtains/mottura_master_two_3069.jpg";
import MotturaMasterTwo3070 from "../../../images/products/curtains/mottura_master_two_3070.jpg";
import MotturaMasterTwo3073 from "../../../images/products/curtains/mottura_master_two_3073.jpg";
import MotturaMasterTwo3074 from "../../../images/products/curtains/mottura_master_two_3074.jpg";
import MotturaMasterTwo3082 from "../../../images/products/curtains/mottura_master_two_3082.jpg";
import MotturaMasterTwoMAIN from "../../../images/products/curtains/mottura_master_two_main.jpg";
import NotteOneFrMAIN from "../../../images/products/curtains/notte_one_fr_main.jpg";

import RegioFRMAIN from "../../../images/products/curtains/regio_fr_main.jpg";
import SavannahMAIN from "../../../images/products/curtains/savannah_main.jpg";

//Venetians
import CedarlineVenetiansa from "../../../images/products/venetians/cedarline_venetians_a.jpg";
import CedarlineVenetiansb from "../../../images/products/venetians/cedarline_venetians_b.jpeg";
import CedarlineVenetiansc from "../../../images/products/venetians/cedarline_venetians_c.jpg";
import CedarlineVenetiansd from "../../../images/products/venetians/cedarline_venetians_d.jpg";
import CedarlineVenetianse from "../../../images/products/venetians/cedarline_venetians_e.jpg";
import DoubleGlazedVenetiansa from "../../../images/products/venetians/double_glazed_venetians_a.jpeg";
import DoubleGlazedVenetiansb from "../../../images/products/venetians/double_glazed_venetians_b.jpeg";
import DoubleGlazedVenetiansc from "../../../images/products/venetians/double_glazed_venetians_c.jpeg";
import DoubleGlazedVenetiansd from "../../../images/products/venetians/double_glazed_venetians_d.png";
import DoubleGlazedVenetianse from "../../../images/products/venetians/double_glazed_venetians_e.jpg";
import DoubleGlazedVenetiansf from "../../../images/products/venetians/double_glazed_venetians_f.jpg";
import DoubleGlazedVenetiansg from "../../../images/products/venetians/double_glazed_venetians_g.jpg";
import DoubleGlazedVenetiansh from "../../../images/products/venetians/double_glazed_venetians_h.jpg";
import Easytilta from "../../../images/products/venetians/easytilt_a.jpg";
import Easytiltb from "../../../images/products/venetians/easytilt_b.jpg";
import Easytiltc from "../../../images/products/venetians/easytilt_c.jpg";
import Easytiltd from "../../../images/products/venetians/easytilt_d.jpg";
import Easytilte from "../../../images/products/venetians/easytilt_e.jpg";
import EasytiltMidnightera from "../../../images/products/venetians/easytilt_midnighter_a.jpg";
import EasytiltMidnighterb from "../../../images/products/venetians/easytilt_midnighter_b.jpg";
import Monocommanda from "../../../images/products/venetians/monocommand_a.jpg";
import Monocommandb from "../../../images/products/venetians/monocommand_b.jpg";
import Monocommandc from "../../../images/products/venetians/monocommand_c.jpg";
import MotorisedVenetiansa from "../../../images/products/venetians/motorised_venetians_a.jpg";
import MotorisedVenetiansb from "../../../images/products/venetians/motorised_venetians_b.jpg";
import MotorisedVenetiansc from "../../../images/products/venetians/motorised_venetians_c.jpg";
import MotorisedVenetiansd from "../../../images/products/venetians/motorised_venetians_d.jpg";
import MotorisedVenetianse from "../../../images/products/venetians/motorised_venetians_e.jpg";
import MotorisedVenetiansf from "../../../images/products/venetians/motorised_venetians_f.jpg";
import ShapesControlVenetiansa from "../../../images/products/venetians/shapes_control_venetians_a.jpeg";
import ShapesControlVenetiansb from "../../../images/products/venetians/shapes_control_venetians_b.jpeg";
import ShapesControlVenetiansc from "../../../images/products/venetians/shapes_control_venetians_c.jpeg";
import ShapesControlVenetiansd from "../../../images/products/venetians/shapes_control_venetians_d.png";
import UltrawoodVenetiansa from "../../../images/products/venetians/ultrawood_venetians_a.jpeg";
import UltrawoodVenetiansb from "../../../images/products/venetians/ultrawood_venetians_b.jpeg";
import UltrawoodVenetiansc from "../../../images/products/venetians/ultrawood_venetians_c.jpeg";
import UltrawoodVenetiansd from "../../../images/products/venetians/ultrawood_venetians_d.jpeg";
import UltrawoodVenetianse from "../../../images/products/venetians/ultrawood_venetians_e.png";
import UltrawoodVenetiansf from "../../../images/products/venetians/ultrawood_venetians_f.png";
import UltrawoodVenetiansg from "../../../images/products/venetians/ultrawood_venetians_g.png";
import Widelinea from "../../../images/products/venetians/wideline_a.jpg";
import Widelineb from "../../../images/products/venetians/wideline_b.jpeg";
import VenetiansImage from "../../../images/venetians.jpg";

//Panel Glides
import PanelGlidesa from "../../../images/products/panel-glides/panel_glides_a.jpg";
import PanelGlidesb from "../../../images/products/panel-glides/panel_glides_b.jpg";
import PanelGlidesc from "../../../images/products/panel-glides/panel_glides_c.jpg";
import PanelGlidesd from "../../../images/products/panel-glides/panel_glides_d.jpg";
import PanelGlidese from "../../../images/products/panel-glides/panel_glides_e.jpg";
import PanelGlidesf from "../../../images/products/panel-glides/panel_glides_f.jpg";
import PanelGlidesg from "../../../images/products/panel-glides/panel_glides_g.jpg";

//Verticals
import Verticalsa from "../../../images/products/verticals/verticals_a.jpg";
import Verticalsb from "../../../images/products/verticals/verticals_b.jpg";
import Verticalsc from "../../../images/products/verticals/verticals_c.jpg";
import Verticalsd from "../../../images/products/verticals/verticals_d.jpg";
import Verticalse from "../../../images/products/verticals/verticals_e.jpg";
import Verticalsf from "../../../images/products/verticals/verticals_f.jpg";
import Verticalsg from "../../../images/products/verticals/verticals_g.jpg";
import Verticalsh from "../../../images/products/verticals/verticals_h.jpg";
import Verticalsi from "../../../images/products/verticals/verticals_i.jpg";

//Romans
import AvantiRomansa from "../../../images/products/romans/avanti_romans_a.jpg";
import AvantiRomansb from "../../../images/products/romans/avanti_romans_b.jpg";
import AvantiRomansc from "../../../images/products/romans/avanti_romans_c.jpg";
import AvantiRomansd from "../../../images/products/romans/avanti_romans_d.jpg";
import ContemporaryRomansa from "../../../images/products/romans/contemporary_romans_a.jpg";
import ContemporaryRomansb from "../../../images/products/romans/contemporary_romans_b.jpg";
import ContemporaryRomansc from "../../../images/products/romans/contemporary_romans_c.jpg";
import ContemporaryRomansd from "../../../images/products/romans/contemporary_romans_d.jpg";
import ContemporaryRomanse from "../../../images/products/romans/contemporary_romans_e.jpg";
import EasyriseRomansa from "../../../images/products/romans/easyrise_romans_a.jpg";
import EasyriseRomansb from "../../../images/products/romans/easyrise_romans_b.jpg";
import EasyriseRomansc from "../../../images/products/romans/easyrise_romans_c.jpg";
import MotorisedRomansa from "../../../images/products/romans/motorised_romans_a.jpg";
import TraditionalRomansa from "../../../images/products/romans/traditional_romans_a.jpg";

//Pleated Blinds
import DuopleatPleateda from "../../../images/products/pleated-blinds/duopleat_pleated_a.jpg";
import DuopleatPleatedb from "../../../images/products/pleated-blinds/duopleat_pleated_b.jpg";
import DuopleatPleatedc from "../../../images/products/pleated-blinds/duopleat_pleated_c.jpg";
import DuopleatPleatedd from "../../../images/products/pleated-blinds/duopleat_pleated_d.jpg";
import DuopleatPleatede from "../../../images/products/pleated-blinds/duopleat_pleated_e.jpg";
import DuopleatPleatedf from "../../../images/products/pleated-blinds/duopleat_pleated_f.jpg";
import MotorisedPleateda from "../../../images/products/pleated-blinds/motorised_pleated_a.jpg";
import MotorisedPleatedb from "../../../images/products/pleated-blinds/motorised_pleated_b.jpg";
import MotorisedPleatedc from "../../../images/products/pleated-blinds/motorised_pleated_c.jpg";
import MotorisedPleatedd from "../../../images/products/pleated-blinds/motorised_pleated_d.jpg";
import MotorisedPleatede from "../../../images/products/pleated-blinds/motorised_pleated_e.jpg";
import MotorisedPleatedf from "../../../images/products/pleated-blinds/motorised_pleated_f.jpg";
import MotorisedPleatedg from "../../../images/products/pleated-blinds/motorised_pleated_g.jpg";
import MotorisedPleatedh from "../../../images/products/pleated-blinds/motorised_pleated_h.jpg";
import MotorisedPleatedi from "../../../images/products/pleated-blinds/motorised_pleated_i.jpg";
import MotorisedPleatedj from "../../../images/products/pleated-blinds/motorised_pleated_j.jpg";

//ZZZ
import RomansImage from "../../../images/romans.jpg";
import ShuttersImage from "../../../images/shutters.jpg";
import SkylightImage from "../../../images/skylight.jpg";
import VerticalImage from "../../../images/vertical.jpg";

import CurtainSystemFive from "../../../images/products/aluproff/28.jpg";
import CurtainSystemSix from "../../../images/products/aluproff/42.jpg";
import CurtainSystemOne from "../../../images/products/aluproff/5.jpg";
import CurtainSystemTwo from "../../../images/products/aluproff/7.jpg";
import CurtainSystemThree from "../../../images/products/aluproff/8.jpg";
import CurtainSystemFour from "../../../images/products/aluproff/9.jpg";

const ProductsCatalogue = [
  {
    id: "1",
    type: "Roller Blinds",
    heading: "Roller Blinds",
    images: [{ name: RollerBlindOnePointZeroOneImageOne, alt: "Roller Blind" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "Spring Operated Roller Blind 1.01",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Single",
          width: { min: 430, max: 2400 },
          drop: { min: 500, max: 3050 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Spring Operated Roller System is simple and timeless. Easy to operate, these roller blinds use an internal spring system to lock them in place under tension. ",
          descriptionTwo:
            "Classic yet contemporary, spring operated roller blinds complement most rooms and architectural styles, making them a versatile choice of window covering. Made to Vertilux’s high standards, Spring Operated Roller Blinds include a four stop mechanism for finer control of blind stopping positions. ",
          descriptionThree:
            "They are engineered to be long wearing and resistant against spring failure.",
          descriptionFour:
            "Controls are custom crafted in tassels, crocheted, metal or timber rings, to your selection.",
          images: [
            { name: RollerBlindOnePointZeroOneImageOne, alt: "1.01 Spring Operated" },
            { name: RollerBlindOnePointZeroOneImageThree, alt: "1.01 Spring Operated" },
          ],
          standAloneImg: { name: RollerBlindOnePointZeroOneImageTwo, alt: "1.01 Spring Operated" },
          techSpecs: [
            {
              name: "Spring",
              description:
                "Active end plug including a torque shaft and flag pawl, incorporating twoorthogonal stop positions for multiple blind stopping positions. The drive spring will be of hard drawn oil tempered steel wire to AS1472.",
            },
            {
              name: "Idle end plug",
              description: "Moulded zinc plated plastic, case hardened steel location pin.",
            },
            {
              name: "Tube",
              description:
                "Aluminium alloy 6060 tube manufactured to ISO9001 with multiple cloth fixing.40mm diameter external.",
            },
            {
              name: "Brackets",
              description:
                "Pressed zinc coated steel with integral jump stop and multi purpose fit, to caterfor recess or face fixing. Adjustable rubber grommet to accommodate fixing.",
            },
            {
              name: "Base rail",
              description:
                "Extruded aluminium manufactured to ISO9001. Vertilux design available invarious colours.",
            },
          ],
          downloads: [
            { id: "1", name: "Vertilux_1_01_Spring_Operated_Roller_Blind_System_V1_0816.pdf" },
          ],
        },
        {
          id: "2",
          heading: "Easydrive BGS™ Roller Blind System 1.02a - 15 Year Warranty!",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Single",
          width: { min: 430, max: 2400 },
          drop: { min: 500, max: 3050 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Invented by and exclusive to Vertilux, the Easydrive BGS™ roller system is specifically designed to suit large span windows. ",
          descriptionTwo:
            "Durable, elegant and easy to operate, the EasydriveBGS™  roller system enables easy lowering or lifting of large blinds with half the exertion of comparable blinds fitted with a traditional friction brake system. The system ensures the blind holds its desired position exactly, is simple to install and longwearing, with virtually no maintenance. ",
          descriptionThree:
            "A wide range of colour coodinated accessories and durable powder coated base rails are available to further enhance the look and function of the Easydrive BGS™ Roller Blind.",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroTwoImageOne, alt: "1.02 Spring Operated" },
            { name: RollerBlindOnePointZeroTwoImageTwo, alt: "1.02 Spring Operated" },
            { name: RollerBlindOnePointZeroTwoImageThree, alt: "1.02 Spring Operated" },
            { name: RollerBlindOnePointZeroTwoImageFour, alt: "1.02 Spring Operated" },
            { name: RollerBlindOnePointZeroTwoImageSix, alt: "1.02 Spring Operated" },
          ],
          standAloneImg: { name: RollerBlindOnePointZeroTwoImageFive, alt: "1.02 Spring Operated" },
          techSpecs: [
            {
              name: "Clutch",
              description:
                "Winder with direct action dual reverse clutchwound springs.U.V. stabilised nylon with high resistance to ultraviolet light. ",
            },
            {
              name: "Idle end plug",
              description:
                "Quick turn spring release for easy pin insertion into bracket.U.V. stabilised nylon with high resistance to ultraviolet light. ",
            },
            {
              name: "Tube",
              description:
                "Aluminium alloy tube manufactured to ISO9001 with multiple cloth fixing.40mm standard for widths up to 2199mm / 47mm for widths over 2200mm.",
            },
            {
              name: "Brackets",
              description: "Colour coordinated pressed powdercoated steel. ",
            },
            {
              name: "Base rail",
              description:
                "Extruded aluminium manufactured to ISO9001. Vertilux design available invarious colours.",
            },
            {
              name: "Chain",
              description: "No.10 chain in either nylon bead chain or stainless steel ball chain",
            },
          ],
          downloads: [
            { id: "1", name: "Vertilux_1_02_Easydrive_BGS_Roller_Blind_System_V1_0816.pdf" },
          ],
        },
        {
          id: "3",
          heading: "Easydrive BGS™ Roller Blind with Spring Assist 1.03a - 15 Year Warranty!",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Single",
          width: { min: 600, max: 3400 },
          drop: { min: 500, max: 4500 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "This system adds a unique spring assist boost to all the features of the Easydrive BGS™ manual system.",
          descriptionTwo:
            "The spring assist boost means only minimal effort is required to lift or lower very large or heavy blinds. As the best system in its class, blinds weighing up to 12.5 kgs or up to 15.3 m2 can be operated effortlessly and smoothly. ",
          descriptionThree:
            "Spring Assist would also work well in circumstances where the operator is frail or aged.",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroThreeImageOne, alt: "1.03a Spring Operated" },
            { name: RollerBlindOnePointZeroThreeImageThree, alt: "1.03a Spring Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroThreeImageTwo,
            alt: "1.03a Spring Operated",
          },
          techSpecs: [
            {
              name: "Clutch",
              description:
                "Winder with direct action dual reverse clutchwound springs. U.V. stabilised nylon with high resistance to ultraviolet light ",
            },
            {
              name: "Booster",
              description:
                "High tensity wound steel wire coiled spring booster, with high ratio winding. Vertilux patent no 760547. ",
            },
            {
              name: "Idle end plug",
              description:
                "Quick turn spring release for easy pin insertion into bracket.U.V. stabilised nylon.",
            },
            {
              name: "Tube",
              description:
                "40mm standard tube - widths from 600mm to 2199mm.47mm tube - widths from 2200mm - 3299mm.52mm tube - widths from 3300mm - 3400mm. ",
            },
            {
              name: "Brackets",
              description: "Male and female colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Base Rail",
              description:
                "Extruded aluminium manufactured to ISO9001. Vertilux design available in various colours.",
            },
            {
              name: "Chain",
              description: "No.10 chain in either nylon bead chain or stainless steel ball chain.",
            },
          ],
          downloads: [
            { id: "1", name: "Vertilux_1_03_Easydrive_BGS_Roller_Blind_System_V1_0816.pdf" },
          ],
        },
        {
          id: "4",
          heading: "Multilink BGS™ Roller Blind System 1.04a - 15 Year Warranty!",
          product: "Vertilux",
          operation: "Linked",
          rollType: "Single",
          width: { min: 600, max: 5000 },
          drop: { min: 500, max: 4000 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Multilink BGS™ Roller Blind System allows several blinds to be lined up in a row then adjusted through a single control.",
          descriptionTwo:
            "This system provides an elegant solution to spanning multiple windows or very wide windows which exceed normal fabric widths. The multilink system is perfect for achieving a clean, minimalist look, as the number of hanging control chains is dramatically reduced. ",
          descriptionThree:
            "The Multilink BGS™ Roller Blind System is very popular in both commercial and domestic situations where the convenience and adaptability of singularly controlled multiple blinds is recognised.",
          descriptionFour: "",
          images: [{ name: RollerBlindOnePointZeroFourImageOne, alt: "1.04a Spring Operated" }],
          standAloneImg: {
            name: RollerBlindOnePointZeroFourImageTwo,
            alt: "1.04a Spring Operated",
          },
          techSpecs: [
            {
              name: "Clutch & booster",
              description:
                "Winder with direct action dual reverse clutchwound springs. High tensitywound steel wire coiled spring booster, with high ratio winding. Vertiluxpatent no 760547. ",
            },
            {
              name: "Male tube connector",
              description:
                "U.V. stabilised nylon with high resistance to ultraviolet light. Idle endconnector. 40mm ",
            },
            {
              name: "Key tube",
              description:
                "Aluminium alloy tube, with multiple cloth fixing. 39mm internal / 46mm external.",
            },
            {
              name: "Brackets",
              description: "Male and female colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Bearing lock",
              description: "U.V. stabilised nylon with bearing for intermediate and idle end.",
            },
            {
              name: "Female tube connector",
              description: "U.V. stabilised nylon with high resistance to ultraviolet light. ",
            },
            {
              name: "Idle end pin lock",
              description: "U.V. stabilised nylon with high resistance to ultraviolet light",
            },
            {
              name: "Base rail",
              description:
                "Extruded aluminium manufacturered to ISO9001. Vertilux design available in various colours.",
            },
          ],
          downloads: [{ id: "1", name: "Vertilux_1_04_Multilink_BGS_Roller_System_V1_0816.pdf" }],
        },
        {
          id: "5",
          heading: "Motorised Roller Blind System 1.05",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Single",
          width: { min: 550, max: 3100 },
          drop: { min: 500, max: 5000 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Our Motorised Roller Blind System lets you raise and lower blinds at the push of a button. Built from high quality market leading motors together with modern wireless technology, the Vertilux Motorised Roller Blind System is of unsurpassed durability and quality.",
          descriptionTwo:
            "Motorised roller systems are popular in important commercial and industrial settings. The ambience of apartments and homes is always greatly enhanced by the style and control provided by motorised roller blinds. Motorisation is also highly recommended for very large or heavy blinds.",
          descriptionThree:
            "All Vertilux motorised roller systems are easily installed. Various automation options are available so that blinds can even lift or lower automatically at certain times of day.",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroFiveImageOne, alt: "1.05 Motorised Operated" },
            { name: RollerBlindOnePointZeroFiveImageTwo, alt: "1.05 Motorised Operated" },
            { name: RollerBlindOnePointZeroFiveImageThree, alt: "1.05 Motorised Operated" },
            { name: RollerBlindOnePointZeroFiveImageFour, alt: "1.05 Motorised Operated" },
            { name: RollerBlindOnePointZeroFiveImageFive, alt: "1.05 Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroFiveImageSix,
            alt: "1.05 Motorised Operated",
          },
          techSpecs: [
            {
              name: "40mm / 50mm motor",
              description: "Motor to suit application.",
            },
            {
              name: "Key tube",
              description:
                "Aluminium alloy tube, with multiple cloth fixing. 47mm / 56mm / 69mm external.",
            },
            {
              name: "Female tube connector",
              description: "UV stabilised nylon. Idle end connector. 40mm",
            },
            {
              name: "Brackets",
              description: "Male and female colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Idle end pin lock",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. 4mm tolerance.",
            },
            {
              name: "Motor ring adaptor",
              description: "Colour coordinated pressed steel. ",
            },
            {
              name: "Ring adaptors",
              description: "UV stabilised nylon adaptor for 40mm / 56mm / 69mm tube.",
            },
            {
              name: "Base rail",
              description:
                "Extruded aluminium manufactured to ISO9001. Vertilux design available in various colours.",
            },
          ],
          downloads: [{ id: "1", name: "Vertilux_1_05_Motorised_Roller_System_V1_0816.pdf" }],
        },
        {
          id: "6",
          heading: "Motorised Multilink Roller Blind System 1.06",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Linked",
          width: { min: 600, max: 8000 },
          drop: { min: 500, max: 5000 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Our Motorised Multilink Roller Blind System allows several blinds to be lifted or lowered at once, from one remote control point. Incorporating industry standard motorisation, blinds in this system can be effortlessly lifted and lowered together.",
          descriptionTwo:
            "With a Motorised Multilink Roller Blind System, two or three blinds up to approximately 8000mm wide and with a drop of 5000mm can be lifted or lowered in unison, and all at the touch of a button.",
          descriptionThree:
            "The Motorised Multilink Roller Blind System is both attractive and time saving. Using the latest wireless motorisation technology, this system is perfect for large windows, in both commercial and residential settings. Plus, with motorisation, even very heavy blinds lift and lower with complete ease.",
          descriptionFour:
            "The Motorised Multilink Roller Blind System also enables fabric limitations to be accommodated. Usually, blind dimensions are restricted by the width at which fabrics are manufactured. However with the Motorised Multilink Roller Blind System a number of individual blinds effectively operate as one, as they can all be lifted and lowered together. The Motorised Multilink Roller Blind System is perfect for achieving a clean, minimalist and modern look. And, naturally, total light control comes standard.",
          images: [
            { name: RollerBlindOnePointZeroSixImageOne, alt: "1.06 Motorised Operated" },
            { name: RollerBlindOnePointZeroSixImageThree, alt: "1.06 Motorised Operated" },
            { name: RollerBlindOnePointZeroSixImageFour, alt: "1.06 Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroSixImageTwo,
            alt: "1.06 Motorised Operated",
          },
          techSpecs: [
            {
              name: "40mm / 50mm motor",
              description: "Motor to suit application.",
            },
            {
              name: "Male tube connector",
              description: "UV stabilised nylon with high resistance to ultraviolet light.",
            },
            {
              name: "Key tube",
              description:
                "Aluminium alloy tube, with multiple cloth fixing. 47mm / 56mm / 69mm external.",
            },
            {
              name: "Brackets",
              description: "Male and female colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Female tube connector",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. Idle end connector. 40mm.",
            },
            {
              name: "Idle end pin lock",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. 4mm tolerance.",
            },
            {
              name: "Motor ring adaptor",
              description: "Colour coordinated pressed steel. ",
            },
            {
              name: "Ring adaptors",
              description: "UV stabilised nylon adaptor for 40mm / 56mm / 69mm tube.",
            },
            {
              name: "Base rail",
              description:
                "Extruded aluminium manufactured to ISO9001. Vertilux design available in various colours.",
            },
          ],
          downloads: [
            { id: "1", name: "Vertilux_1_06_Motorised_Multilink_Roller_System_V1_0816.pdf" },
          ],
        },
        {
          id: "7",
          heading: "Double BGS™ Roller Blind Roller Blind System 1.07a",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Dual",
          width: { min: 400, max: 3100 },
          drop: { min: 500, max: 4500 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Double BGS™ Roller Blind allows two roller blinds of any type to be mounted as front and rear blinds on a shared set of brackets, so you have two blinds covering the one window.",
          descriptionTwo:
            "The first blind is often a sheer, offering UV protection and heat reduction without inhibiting natural light. The second blind, which rolls down seamlessly over the first, creates effective light block out and privacy.",
          descriptionThree:
            "This system is perfect for living spaces that require reduced glare during the day, such as family rooms or home theatres to total privacy at night. Equally, the double blind system offers a flexible and versatile approach to controlling light and shade in a commercial setting.",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroSevenImageOne, alt: "1.07 Spring Operated" },
            { name: RollerBlindOnePointZeroSevenImageThree, alt: "1.07 Spring Operated" },
            { name: RollerBlindOnePointZeroSevenImageFour, alt: "1.07 Spring Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroSevenImageTwo,
            alt: "1.07 Spring Operated",
          },
          techSpecs: [
            {
              name: "Clutch",
              description: "Winder with direct action dual reverse clutchwound springs. ",
            },
            {
              name: "Idle end plug",
              description:
                "Quick turn spring release for easy pin insertion into bracket. UV stabilised nylon with high resistance to ultraviolet light.",
            },
            {
              name: "Tube",
              description:
                "Aluminium alloy tube, with multiple cloth fixing. 40mm standard for widths up to 2199mm/ 46mm for widths over 2200mm",
            },
            {
              name: "Double brackets",
              description: "Colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Male tube connector",
              description: "UV stabilised nylon with high resistance to ultraviolet light.",
            },
            {
              name: "Female tube connector",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. Idle end connector. 40mm.",
            },
            {
              name: "Intermediate brackets",
              description: "Colour coordinated pressed steel.",
            },
            {
              name: "Idle end pin lock",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. With 4mm tolerance.",
            },
            {
              name: "Bearing lock",
              description: "UV stabilised nylon with bearing for intermediate and idle end.",
            },
            {
              name: "Base rail",
              description:
                "Extruded aluminium manufactured to ISO9001. Vertilux design available in various colours.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_07_BGS_Double_Blind_Double_Multilink_Roller_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "88",
          heading: "Double Multilink BGS™ Roller Blind System 1.07a",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Linked",
          width: { min: 800, max: 5000 },
          drop: { min: 500, max: 4000 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Double BGS™ Roller Blind allows two roller blinds of any type to be mounted as front and rear blinds on a shared set of brackets, so you have two blinds covering the one window.",
          descriptionTwo:
            "The first blind is often a sheer, offering UV protection and heat reduction without inhibiting natural light. The second blind, which rolls down seamlessly over the first, creates effective light block out and privacy.",
          descriptionThree:
            "This system is perfect for living spaces that require reduced glare during the day, such as family rooms or home theatres to total privacy at night. Equally, the double blind system offers a flexible and versatile approach to controlling light and shade in a commercial setting.",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroSevenImageOne, alt: "1.07 Spring Operated" },
            { name: RollerBlindOnePointZeroSevenImageThree, alt: "1.07 Spring Operated" },
            { name: RollerBlindOnePointZeroSevenImageFour, alt: "1.07 Spring Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroSevenImageTwo,
            alt: "1.07 Spring Operated",
          },
          techSpecs: [
            {
              name: "Clutch",
              description: "Winder with direct action dual reverse clutchwound springs. ",
            },
            {
              name: "Idle end plug",
              description:
                "Quick turn spring release for easy pin insertion into bracket. UV stabilised nylon with high resistance to ultraviolet light.",
            },
            {
              name: "Tube",
              description:
                "Aluminium alloy tube, with multiple cloth fixing. 40mm standard for widths up to 2199mm/ 46mm for widths over 2200mm",
            },
            {
              name: "Double brackets",
              description: "Colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Male tube connector",
              description: "UV stabilised nylon with high resistance to ultraviolet light.",
            },
            {
              name: "Female tube connector",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. Idle end connector. 40mm.",
            },
            {
              name: "Intermediate brackets",
              description: "Colour coordinated pressed steel.",
            },
            {
              name: "Idle end pin lock",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. With 4mm tolerance.",
            },
            {
              name: "Bearing lock",
              description: "UV stabilised nylon with bearing for intermediate and idle end.",
            },
            {
              name: "Base rail",
              description:
                "Extruded aluminium manufactured to ISO9001. Vertilux design available in various colours.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_07_BGS_Double_Blind_Double_Multilink_Roller_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "8",
          heading: "Motorised Double Roller Blind System 1.08",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Dual",
          width: { min: 550, max: 3100 },
          drop: { min: 500, max: 5000 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Packed with all the features of our Double Roller Blind System, the Motorised Double Roller Blind System adds the benefit of motorisation on both of the blinds or just one.",
          descriptionTwo:
            "With the Motorised Double Roller Blind System, blinds can be operated independently or in unison via remote control using the latest in wireless technology.            ",
          descriptionThree: "",
          descriptionFour: "",
          images: [{ name: RollerBlindOnePointZeroEightImageOne, alt: "1.08 Motorised Operated" }],
          standAloneImg: {
            name: RollerBlindOnePointZeroEightImageTwo,
            alt: "1.08 Motorised Operated",
          },
          techSpecs: [
            {
              name: "Double brackets",
              description: "Colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Bearing lock",
              description: "UV stabilised nylon with bearing for intermediate and idle end.",
            },
            {
              name: "40mm / 50mm motor",
              description: "Somfy to suit application.",
            },
            {
              name: "Key tube",
              description:
                "Aluminium alloy tube, with multiple cloth fixing. 46mm / 56mm external.",
            },
            {
              name: "Motor ring adaptor",
              description: "Colour coordinated pressed steel.",
            },
            {
              name: "Female tube connector",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. Idle endconnector. 40mm",
            },
            {
              name: "Idle end pin lock",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. With 4mm tolerance",
            },
            {
              name: "Base rail",
              description:
                "Extruded aluminium manufactured to ISO9001. Vertilux design available in various colours.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_08_Double_Blind_Motorised_Roller_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "9",
          heading: "Double Roller Blind Motorised Multilink System 1.09",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Linked",
          width: { min: 550, max: 8000 },
          drop: { min: 500, max: 5000 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "All the features of our Double Roller Blind Motorised Multilink System, with the added ability to combine a number of individual blinds to effectively operate as one, so they can all be lifted and lowered together.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroNineImageOne, alt: "1.09 Motorised Operated" },
            { name: RollerBlindOnePointZeroNineImageThree, alt: "1.09 Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroNineImageTwo,
            alt: "1.09 Motorised Operated",
          },
          techSpecs: [
            {
              name: "Double brackets",
              description: "Male and female colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Motor ring adaptor",
              description: "Colour coordinated pressed steel.",
            },
            {
              name: "40mm / 50mm motor",
              description: "Somfy to suit application.",
            },
            {
              name: "Key tube",
              description:
                "Aluminium alloy tube, with multiple cloth fixing. 46mm / 56mm external.",
            },
            {
              name: "Bearing lock",
              description: "UV stabilised nylon with bearing for intermediate and idle end.",
            },
            {
              name: "Male tube connector",
              description: "UV stabilised nylon with high resistance to ultraviolet light",
            },
            {
              name: "Female tube connector",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. Idle end connector. 40mm.",
            },
            {
              name: "Idle end pin lock",
              description:
                "UV stabilised nylon with high resistance to ultraviolet light. With 4mm tolerance",
            },
            {
              name: "Base rail",
              description:
                "Extruded aluminium manufactured to ISO9001. Vertilux design available in various colours.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_09_Double_Blind_Motorised_Multilink_Roller_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "10",
          heading: "Vertilux Chain Tensioner 1.10 (Accessories)",
          product: "Vertilux",
          operation: "Manual",
          rollType: "",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Our unique Vertilux Chain Tensioner removes the problems that are found in inferior chain based blind mechanisms. By keeping the chain taught, this spring pulley tensioner ensures the mechanism remains free flowing and fault free for the life of the blind.",
          descriptionTwo: "The Vertilux Chain Tensioner is available in Black or White.",
          descriptionThree: "",
          descriptionFour: "",
          images: [{ name: RollerBlindOnePointZeroTenImageOne, alt: "1.10 Spring Operated" }],
          standAloneImg: { name: RollerBlindOnePointZeroTenImageTwo, alt: "1.10 Spring Operated" },
          techSpecs: [
            {
              name: "Chain tensioner single",
              description: "U.V. stabilised nylon with high resistance to ultraviolet light.",
            },
            {
              name: "Spring hole",
              description: "Spring holder for various tension fixing applications.",
            },
            {
              name: "Chain rotation wheel",
              description:
                "Control of blind can be used while on chain tensioner as result of rotation wheel in body",
            },
            {
              name: "Fixing bracket",
              description: "Fixing bracket for multiple fixing applications with screw.",
            },
            {
              name: "Double bracket",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Colour",
              description: "Available in Black or White.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_10_Chain_Tensioner_with_Single_and_Double_Brackets_V1_0816.pdf",
            },
          ],
        },
        {
          id: "11",
          heading: "Single Fascia Kassett 1.11a (Accessories)",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Single",
          width: { min: 600, max: 5800 },
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Single Fascia Kassett is especially effective in locations where a uniform and finely detailed finish is demanded. The system can be fitted to any single Vertilux Roller Blind.",
          descriptionTwo:
            "Manufactured from extruded aluminium and available in a wide range of powder coated colours.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroElevenImageOne, alt: "1.11a Spring Operated" },
            { name: RollerBlindOnePointZeroElevenImageTwo, alt: "1.11a Spring Operated" },
            { name: RollerBlindOnePointZeroElevenImageFour, alt: "1.11a Spring Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroElevenImageThree,
            alt: "1.11a Spring Operated",
          },
          techSpecs: [
            {
              name: "Fascia kassett",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Fascia kassett end caps",
              description: "Moulded wear-resistant polycarbonate.",
            },
            {
              name: "Bracket",
              description: "Spring loaded pressed zinc coated steel.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_11a_Single_Fascia_Kassett_V1_0816.pdf",
            },
          ],
        },
        {
          id: "12",
          heading: "Double Fascia Kassett 1.11b (Accessories)",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Dual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Double Fascia Kassett is especially effective in locations where a uniform and finely detailed finish is demanded. The system can be fitted to specific Vertilux Double Roller Blind brackets.",
          descriptionTwo:
            "Manufactured from extruded aluminium and available in a wide range of powder coated colours.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroElevenImageSix, alt: "1.11b Spring Operated" },
            { name: RollerBlindOnePointZeroElevenImageSeven, alt: "1.11b Spring Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroElevenImageFive,
            alt: "1.11b Spring Operated",
          },
          techSpecs: [
            {
              name: "Double fascia kassett",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Brackets",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Fascia rail endcap",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Bracket",
              description: "Spring loaded pressed zinc coated steel.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_11b_Double_Fascia_Kassett_V1_0816.pdf",
            },
          ],
        },
        {
          id: "13",
          heading: "Double Slimline Fascia Kassett 1.11c (Accessories)",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Dual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Our Double Slimline Fascia Kassett systems are excellent for achieving a minimalist look where finishes need to be executed precisely down to the last detail, hiding the head roll and brackets to complete a stylish appearance.",
          descriptionTwo:
            "Additionally, these systems are perfectly suited for metal backed fabrics, since they allow you to back roll the fabric without seeing a silver head roll.",
          descriptionThree: "",
          descriptionFour: "",
          images: [{ name: RollerBlindOnePointZeroElevenImageEight, alt: "1.11c Spring Operated" }],
          standAloneImg: {
            name: RollerBlindOnePointZeroElevenImageEight,
            alt: "1.11c Spring Operated",
          },
          techSpecs: [
            {
              name: "Double fascia kassett",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Brackets",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Fascia rail endcap",
              description: "Moulded from U.V. resistant acetal",
            },
            {
              name: "Bracket",
              description: "Spring loaded pressed zinc coated steel.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "1.11c Vertilux Double Slimline Fascia Kassett Data Sheet_Sept2018.pdf",
            },
          ],
        },
        {
          id: "14",
          heading: "Fully Enclosed Kassett System 1.12a (Accessories)",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Single",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux internal/external Fully Enclosed Kassett System takes the Single Fascia Kassett System to the ultimate level by providing a fully enclosed headbox and full length side channels .",
          descriptionTwo:
            "Just like the Vertilux Single Fascia Kassett System, the internal/external Fully Enclosed Kassett System includes a sleek and stylish enclosure for the blind and its mechanisms. With the added side rails the blind becomes fully enclosed which will totally block out any side light leakage ensuring maximum insulation from the elements. These features also make this the most environmentally friendly choice on the market for anyone wanting to control air conditioning and heating within the interior environment.",
          descriptionThree:
            "This system is perfect for situations where light blockout must be total. It is also ideal in a home theatre situation or to provide coverage of meeting / presentation room windows.",
          descriptionFour:
            "Alternately this system also offers the option of stainless steel guide wires for use on external blinds or internally when the blind needs guidance over a long drop. The headbox and side channels are manufactured from extruded aluminium with a wide range of powder coated colours.",
          images: [
            { name: RollerBlindOnePointZeroTwelveImageOne, alt: "1.12a Spring Operated" },
            { name: RollerBlindOnePointZeroTwelveImageThree, alt: "1.12a Spring Operated" },
            { name: RollerBlindOnePointZeroTwelveImageFour, alt: "1.12a Spring Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroTwelveImageTwo,
            alt: "1.12a Spring Operated",
          },
          techSpecs: [
            {
              name: "Face cover plate - 80mm or 100mm",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Back rail section - 80mm or 100mm",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Headrail encaps L/R with side channel adaptor",
              description: "Moulded from U.V resistant aceta.",
            },
            {
              name: "Side Channel - 50mm or 70mm",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Bracket - Male or Female",
              description: "Zinc coated steel.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Endcap",
              description: "Zinc coated steel.",
            },
            {
              name: "Motor mount",
              description: "Zinc coated steel.",
            },
            {
              name: "Base rail - 35mm or 50mm",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Guidewire - Endcap/Fabric",
              description: "Moulded from U.V resistant acetal.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "1.12a Vertilux Fully Enclosed Kassett System_Data Sheet_Apr 2019.pdf",
            },
          ],
        },
        {
          id: "15",
          heading: "Fully Enclosed Multilink Kassett 1.12b (Accessories)",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Linked",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Fully Enclosed Multilink Kassett Blind System retains all the benefit of our Fully Enclosed Systems with the added benefit of allowing allows several blinds to be lined up in a row then adjusted through a single control.",
          descriptionTwo:
            "Taking the Fully Enclosed Kassett System to a whole new level by providing a fully enclosed headbox and with or without full length side channels .",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroTwelveImageFive, alt: "1.12b Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroTwelveImageSix,
            alt: "1.12b Motorised Operated",
          },
          techSpecs: [
            {
              name: "Face rail cover plate",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "L rail section",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Headrail endcaps",
              description: "Moulded from U.V. resistant acetal",
            },
            {
              name: "Multilink endcaps",
              description: "Moulded from U.V. resistant acetal",
            },
            {
              name: "Male link 'unidrive' connector",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "1.12b Vertilux Fully Enclosed Multilink Kassett_Data Sheet_Oct 2016.pdf",
            },
          ],
        },
        {
          id: "16",
          heading: "1.13a Manual Bottom Up Roller Blind System",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Single",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Manual Bottom Up Roller Blind System are pulled from the bottom up via constant tension cables and can be tailored to match the details of specific project requirements which uses spring tension that support rollers to keep the fabric taut during operation and in finished position resulting in a continuous smooth surface.            ",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroThirteenImageOne, alt: "1.13a Spring Operated" },
            { name: RollerBlindOnePointZeroThirteenImageTwo, alt: "1.13a Spring Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroThirteenImageThree,
            alt: "1.13a Spring Operated",
          },
          techSpecs: [
            {
              name: "Face cover plate",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Back rail section",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Headrail endcaps",
              description: "Moulded from U.V resistant acetal.",
            },
            {
              name: "Clutch",
              description:
                "Winder with direct action dual reverse clutchwound springs. U.V. stabilised nylon with high resistance to ultraviolet light.",
            },
            {
              name: "Booster",
              description:
                "High tensity wound steel wire coiled spring booster, with high ratio winding. Vertilux patent no 760547.",
            },
            {
              name: "Tube",
              description:
                "40mm standard tube - widths from 600mm to 2199mm. 47mm tube - widths from 2200mm 3299mm.",
            },
            {
              name: "Idle end plug",
              description:
                "Quickturn spring release for easy pin insertion into bracket. U.V. stabilised nylon.",
            },
            {
              name: "Brackets",
              description: "Male and female colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Base rail",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Endcap",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Pulleys",
              description: "",
            },
            {
              name: "Break-away Safety",
              description: "Moulded from U.V. resistant abs cedal.",
            },
            {
              name: "Tassel",
              description: "Moulded from U.V. resistant polypropylene.",
            },
            {
              name: "Kassett Bracket",
              description: "Extruded aluminium manufactured to IS09001.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "1.13a Vertilux Manual Bottom Up Roller Blind System Data Sheet_Oct 2016.pdf",
            },
          ],
        },

        {
          id: "17",
          heading: "Motorised Bottom Up Roller Blind System 1.13b",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Single",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Our Motorised Bottom Up Roller Blind Systems are ideal for trapezoidal or angled square window shapes as blinds are pulled from the bottom up via constant tension cables. It is a compact system which is ideal for windows that require privacy at the bottom while still allowing light entry at the top with few visible details including the side cables.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroThirteenImageFour, alt: "1.13b Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroThirteenImageFive,
            alt: "1.13b Motorised Operated",
          },
          techSpecs: [
            {
              name: "Face cover plate",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Back rail section",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Headrail endcaps",
              description: "Moulded from U.V resistant acetal.",
            },
            {
              name: "Clutch",
              description:
                "Winder with direct action dual reverse clutchwound springs. U.V. stabilised nylon with high resistance to ultraviolet light.",
            },
            {
              name: "Booster",
              description:
                "High tensity wound steel wire coiled spring booster, with high ratio winding. Vertilux patent no 760547.",
            },
            {
              name: "Tube",
              description:
                "40mm standard tube - widths from 600mm to 2199mm. 47mm tube - widths from 2200mm 3299mm.",
            },
            {
              name: "Idle end plug",
              description:
                "Quickturn spring release for easy pin insertion into bracket. U.V. stabilised nylon.",
            },
            {
              name: "Brackets MALE",
              description: "Colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Brackets FEMALE",
              description: "Colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Endcap",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Base rail",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Kassett bracket",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Motor support",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Motor",
              description: "40mm motor to suit application.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Tube support",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Female end cap with thread",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Male end cap with screw",
              description: "Powdercoated steel.",
            },
            {
              name: "Headbox",
              description: "Extruded aluminium manufactured to ISO9001",
            },
            {
              name: "Cover plate",
              description: "Extruded aluminium manufactured to ISO9001",
            },
            {
              name: "Head box bracket",
              description: "Extruded aluminium manufactured to ISO9001",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "1.13b Vertilux Motorised Bottom Up Roller Blind System Data Sheet_Oct 2016.pdf",
            },
          ],
        },
        {
          id: "18",
          heading: "Motorised Kassett Tensioned System 1.13c",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Single",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Motorised Kassett Tensioned System has the blind in a fully enclosed headbox and uses motorisation in conjunction with constant boxes to provide smooth constant tension. This makes them ideal for skylights or angled windows as are typically mounted vertically and horizontally but can be installed at any angle up to 360 degrees.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroThirteenImageSix, alt: "1.13c Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroThirteenImageSeven,
            alt: "1.13c Motorised Operated",
          },
          techSpecs: [
            {
              name: "Face cover plate",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Back rail section",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Headrail endcaps L/R",
              description: "Moulded from U.V resistant acetal.",
            },
            {
              name: "Bracket male",
              description: "Zinc coated steel.",
            },
            {
              name: "Bracket female",
              description: "Zinc coated steel.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Idle end",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Motor mount",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Base rail",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "End cap",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Kassett bracket",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "1.13c Vertilux Motorised Kassett Tensioned System Data Sheet_Feb2018.pdf.pdf",
            },
          ],
        },
        {
          id: "19",
          heading: "Motorised Tensioned System 1.13d",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Single",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Packed with all the features of our Double Roller Blind System, the Motorised Double Roller Blind System adds the benefit of motorisation on both of the blinds or just one.",
          descriptionTwo:
            "With the Motorised Double Roller Blind System, blinds can be operated independently or in unison via remote control using the latest in wireless technology.            ",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroThirteenImageEight, alt: "1.13 Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroThirteenImageEight,
            alt: "1.13 Motorised Operated",
          },
          techSpecs: [
            {
              name: "Bracket male",
              description: "Zinc coated steel.",
            },
            {
              name: "Bracket female",
              description: "Zinc coated steel.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Idle end",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Motor mount",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Base rail",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "End cap",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Constant Box",
              description: "Zinc coated steel.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "1.13d Vertilux Motorised Tensioned System Data Sheet_Jan 2018.pdf",
            },
          ],
        },
        {
          id: "20",
          heading: "Unidrive Roller Blind System 1.14 (Accessories)",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Linked",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Unidrive Roller Blind System is an extension of the Multilink Roller Blind System and allows group control of blinds that are not in a line.",
          descriptionTwo:
            "This system is perfect for tricky locations such as bay windows or deeply recessed windows with side panes. Just like the Multilink Roller Blind System, all blinds can be controlled from a single chain or motor, delivering the ultimate in convenience. Blinds at angles of up to 90 degrees to each other can be linked with ease and operated smoothly in unison. ",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroFourteenImageOne, alt: "1.14 Manual Operated" },
            { name: RollerBlindOnePointZeroFourteenImageTwo, alt: "1.14 Manual Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroFourteenImageThree,
            alt: "1.14 Manual Operated",
          },
          techSpecs: [
            {
              name: "Clutch & booster",
              description:
                "Winder with direct action dual reverse clutchwound springs. High tensity wound steel wire coiled spring booster, with high ratio winding. Vertilux patent no 760547.",
            },
            {
              name: "Male link connector 45º / 90º",
              description:
                "Structural aluminium 2011 swivel male connector adaptable for angles up to 45º or 90º.",
            },
            {
              name: "Key tube",
              description:
                "Aluminium alloy tube, with multiple cloth fixing. 39mm internal / 46mm external.",
            },
            {
              name: "Brackets",
              description: "Male and female colour coordinated pressed powdercoated steel.",
            },
            {
              name: "Beraing lock",
              description: "U.V. stabilised nylon with bearing for intermediate and idle end.",
            },
            {
              name: "Female tube connector",
              description: "U.V. stabilised nylon with high resistance to U.V. light.",
            },
            {
              name: "Idle end pin lock",
              description: "U.V. stabilised nylon with high resistance to U.V. light.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_14_Unidrive_V1_0816.pdf",
            },
          ],
        },
        {
          id: "21",
          heading: "Z1 Zip™ Roller Blind System 1.16",
          product: "Vertilux",
          operation: "Manual",
          rollType: "Single",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Z1 Zip Roller Blind System™ offers another level above the Fully Enclosed Kassett System. With its zip locking system running the full length of the side channel, the fabric is held in place allowing it to stay intact and fully operational should it receive some accidental force imposed onto it.",
          descriptionTwo:
            "Whilst at first glance it looks to be the Fully Enclosed Kassett System, by providing a sleek and stylish enclosure for the blind and its mechanisms, with a fully enclosed headbox and full length side rails, the Z1 Zip System™ ensures that the fabric is held in place with the unique zip insert attached to the fabric and side rails. The system can be operated by manual chain on a 4:1 ratio or motorised for ease of use in all situations.",
          descriptionThree:
            "With the added side channels and a room darkening fabric the blind becomes fully enclosed which will totally block out any side light leakage ensuring maximum insulation from the elements. These features also make this the most environmentally friendly choice on the market for anyone wanting to control air conditioning and heating within the interior environment. Perfect for health care and hospitality as well as office fit-out or residential use.",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroSixteenImageTwo, alt: "1.16 Manual Operated" },
            { name: RollerBlindOnePointZeroSixteenImageThree, alt: "1.16 Manual Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroSixteenImageOne,
            alt: "1.16 Manual Operated",
          },
          techSpecs: [
            {
              name: "Face cover plate",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Back rail section",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Headrail endcaps L/R with side channel adaptor",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Bracket male",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Bracket female",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Clutch",
              description:
                "Winder with direct action dual reverse clutchwound springs with 4:1 ratio.",
            },

            {
              name: "Motor",
              description: "Motor to suit application.",
            },
            {
              name: "Clutch end adapter",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "Motor end adapter",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "Idle end adapter",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "Idle end",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "50mm side channel",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Side channel insert",
              description: "Extruded ASA distortion resistance to 90°.",
            },
            {
              name: "Zip insert",
              description: "Polyester zip.",
            },
            {
              name: "Base rail",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Base rail end cap",
              description: "U.V. resistant nylon",
            },
            {
              name: "Side channel end cap",
              description: "U.V. resistant nylon",
            },
            {
              name: "External fixing brackets",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Manual operation",
              description: "No. 10 chain in either nylon bead chain or stainless steel ball chain.",
            },
            {
              name: "Chain tensioner system",
              description: "U.V. stabilised nylon available in black or white.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_16_Z1_Zip_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "22",
          heading: "Z1 Zip™ Roller Blind Tension System 1.17",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Single",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Z1 Zip™ Roller Blind Tension System now allows roller blinds to be used in situations not previously suitable for roller blinds. The Z1 Zip™ Roller Blind Tension system is suitable for skylight applications and angled windows; when all four corners are at 90°. Using this tension system along with the zip locking system, which runs the full length of the side channel, the fabric is held in place at any point of operation. This system is only available motorised.",
          descriptionTwo:
            "So, as with the Z1 Zip™ Roller Blind System, the Z1 Zip™ Tension Roller Blind System utilises a sleek and stylish enclosure for the blind and its mechanisms, with a 100mm fully enclosed headbox, 70mm deep full length side rails and 56mm enclosed motor casing.",
          descriptionThree:
            "With the added side channels  and a room darkening fabric the blind becomes fully enclosed which will totally block out any side light leakage ensuring maximum insulation from the elements or alternatively, using a Translucent or Transparent fabric with suitable Solar Optical Properties for the application.",
          descriptionFour:
            "The head boxes and side channels are manufactured from extruded aluminium and a wide range of powder coated colours and colour coordinated accessories are available.",
          images: [
            { name: RollerBlindOnePointZeroSeventeenImageOne, alt: "1.17 Motorised Operated" },
            { name: RollerBlindOnePointZeroSeventeenImageTwo, alt: "1.17 Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroSeventeenImageThree,
            alt: "1.17 Motorised Operated",
          },
          techSpecs: [
            {
              name: "Face cover plate",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Back rail section",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Headrail endcaps L/R with side channel adaptor",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Bracket male",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Bracket female",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Spring booster",
              description:
                "High tensity wound steel wire coiled spring booster, with high ratio winding Vertilux patent No. 760547.",
            },
            {
              name: "Drive end adaptor",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "Idle end",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "70mm side channel",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Side channel insert",
              description: "Extruded ASA distortion resistance to 90°.",
            },
            {
              name: "Zip insert",
              description: "Polyester zip.",
            },
            {
              name: "50mm base rail",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Base rail end cap",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "U channel",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "External fixing brackets",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Headbox back plate",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Headbox cover plate",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Head rail end caps",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Motor support",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "Idle end caps",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Motor",
              description: "Motor to suit application.",
            },
            {
              name: "Tube support",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "Brackets",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_17_Z1_Zip_Tension_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "23",
          heading: "OneBlind System 1.21",
          product: "Vertilux",
          operation: "Motorised",
          rollType: "Single",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Vertilux OneBlind system offers a clear advantage to the standard option of multiple blinds being capable of spanning up to 12 metres with no fabric splits. Equipped with the ability to control an enormous amount of light & temperature simultaneously is advantageous for optimizing glare and thermal protection.",
          descriptionTwo:
            "In addition, OneBlind can be easily integrated with building automation systems including lighting, climate control and audio-visual systems for the ultimate in convenience and energy control. It might look like magic, but it’s actually just a brilliant design.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroTwentyOneImageOne, alt: "1.21 Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroTwentyOneImageTwo,
            alt: "1.21 Motorised Operated",
          },
          techSpecs: [
            {
              name: "Outboard Kassett",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Inboard Kassett",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Tube",
              description: "Mill finish ISO9001.",
            },
            {
              name: "Round pin RT idle end",
              description: "Mill finished extruded aluminium.",
            },
            {
              name: "Roller tube splice",
              description: "Mill finished extruded aluminium.",
            },
            {
              name: "End guide plate",
              description: "Anodised aluminium.",
            },
            {
              name: "HDPE slide",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "End cap",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "HDPE Teflon cradle liner",
              description: "Extruded powdercoated aluminium.",
            },
            {
              name: "Ceiling pocket bracket",
              description: "Extruded powdercoated aluminium.",
            },
            {
              name: "Face fix bracket",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Motor",
              description: "To suit application.",
            },
            {
              name: "Base rail",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "1.21 Vertilux OneBlind Data Sheet_Sept2018.pdf",
            },
          ],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Chanell® Translucent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Chanell®",
          descriptionOne:
            "Chanell® is a translucent fabric that is designed to meet stringent standards in light, glare reduction and insulation from harmful UV rays. With superior heat and reflection properties it assists in maintaining an energy efficient environment. Chanell® allows light to filter into the room where privacy is required yet total blockout is unnecessary.",
          images: [{ name: ChanellTranslucentMAIN, alt: "Chanell Translucent" }],
          colorImages: [
            { name: ChanellTranslucentWHITE, alt: "Blind Fabric Colour White" },
            { name: ChanellTranslucentLUSTRE, alt: "Blind Fabric Colour Lustre" },
            { name: ChanellTranslucentLIMESTONE, alt: "Blind Fabric Colour Limestone" },
            { name: ChanellTranslucentPALLADIUM, alt: "Blind Fabric Colour Palladium" },
            { name: ChanellTranslucentEBONY1, alt: "Blind Fabric Colour Ebony" },
            { name: ChanellTranslucentCHARCOAL, alt: "Blind Fabric Colour Charcoal" },
          ],
          downloads: [{ id: "1", name: "Vertilux_Chanell_Translucent_TechData_V10_0621.pdf" }],
        },
        {
          id: "2",
          heading: "Chanell® ECO Translucent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Chanell®",
          descriptionOne:
            "Chanell® is a translucent fabric that is designed to meet stringent standards in light, glare reduction and insulation from harmful UV rays. With superior heat and reflection properties it assists in maintaining an energy efficient environment. Chanell® allows light to filter into the room where privacy is required yet total blockout is unnecessary.",
          images: [{ name: ChanellTranslucentEcoMAIN, alt: "Chanell Translucent" }],
          colorImages: [
            { name: ChanellTranslucentEcoALLURE, alt: "Blind Fabric Colour Allure" },
            { name: ChanellTranslucentEcoCHANCE, alt: "Blind Fabric Colour Chance" },
            { name: ChanellTranslucentEcoEGOISTE, alt: "Blind Fabric Colour Egoiste" },
            { name: ChanellTranslucentEcoMADEMOISELLE, alt: "Blind Fabric Colour Mademoiselle" },
            { name: ChanellTranslucentEcoOBSESSION, alt: "Blind Fabric Colour Obsession" },
            { name: ChanellTranslucentEcoPLATINUM, alt: "Blind Fabric Colour Platinum" },
          ],
          downloads: [{ id: "1", name: "Vertilux_Chanell Eco_Translucent_TechData_V1_0621.pdf" }],
        },
        {
          id: "3",
          heading: "Eloquent® Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Eloquent®",
          descriptionOne:
            "The Vertilux Eloquent® Room Darkening is a high quality 100% Polyester fabric. Eloquent® is a subtle textured range available as room darkening. Perfect partners together or as a textural match to the rest of the Vertilux fabric ranges, when different light control is required at the window. The Eloquent® Room Darkening fabric does not allow light into the room, giving total privacy. Eloquent® is woven into a jacquard weave with a matt finish giving another dimension to the surrounding furnishings that would comfortably compliment just about all environments",
          images: [{ name: EloquentRoomDarkeningMAIN, alt: "Chanell Translucent" }],
          colorImages: [
            { name: EloquentRoomDarkeningCLOUDWHITE, alt: "Blind Fabric Colour Cloud White" },
            { name: EloquentRoomDarkeningMETROPOLIS, alt: "Blind Fabric Colour Metropolis" },
            { name: EloquentRoomDarkeningPHANTOM, alt: "Blind Fabric Colour Phantom" },
            { name: EloquentRoomDarkeningREFLECTION, alt: "Blind Fabric Colour Reflection" },
            { name: EloquentRoomDarkeningSTONNINGTON, alt: "Blind Fabric Colour Stonnington" },
            { name: EloquentRoomDarkeningURBAN, alt: "Blind Fabric Colour Irban" },
            { name: EloquentRoomDarkeningVINTAGE, alt: "Blind Fabric Colour Vintage" },
          ],
          downloads: [{ id: "1", name: "Vertilux_Eloquent Room Darkening_v10_220819.pdf" }],
        },
        {
          id: "4",
          heading: "EuroBloc® Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "EuroBloc®",
          descriptionOne:
            "EuroBloc® is the room darkening version of the ever popular Vertilux Euroscreen®. With superior heat and reflection properties it can assist in maintaining an energy efficient environment that is designed to meet stringent standards in light glare reduction and insulation from harmful UV rays. EuroBloc® comes standard with the backing colour matched to the face colour of the fabric. Therefore, no more white backing on the room darkening blinds to interrupt the architectural language and cohesion with the building design as read from the streetscape. Available in a range of colours that coordinate well with other Vertilux Greenvision™ fabric ranges and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems. EuroBloc® Room Darkening fabric is also perfect for use in cinema rooms and the like, when maximum room darkness is required. For these applications the EuroBloc® Room Darkening fabric is used in conjunction with the Vertilux Fully Enclosed Kassett System",

          images: [{ name: EuroBlocRoomDarkeningMAIN, alt: "EuroBloc® Room Darkening" }],
          colorImages: [
            { name: EuroBlocRoomDarkeningCARAMEL, alt: "Blind Fabric Colour Caramel" },
            { name: EuroBlocRoomDarkeningCINNAMON, alt: "Blind Fabric Colour Cinnamon" },
            { name: EuroBlocRoomDarkeningHAZE, alt: "Blind Fabric Colour Haze" },
            { name: EuroBlocRoomDarkeningICE, alt: "Blind Fabric Colour Ice" },
            { name: EuroBlocRoomDarkeningIRONSTONE, alt: "Blind Fabric Colour Ironstone" },
            { name: EuroBlocRoomDarkeningMAGNESIUM, alt: "Blind Fabric Colour Magnesium" },
            { name: EuroBlocRoomDarkeningNOIR, alt: "Blind Fabric Colour Noir" },
            { name: EuroBlocRoomDarkeningSILK, alt: "Blind Fabric Colour Silk" },
          ],
          downloads: [{ id: "1", name: "Vertilux_Eurobloc_RoomDarkening_TechData_V10_0621.pdf" }],
        },
        {
          id: "5",
          heading: "Euroscreen® Metallised Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Euroscreen®",
          descriptionOne:
            "The Vertilux Euroscreen® Metallised range is a high quality 100% Trevira CS fabric. Euroscreen® Metallised Transparent is a transparent fabric with a metallised back that is designed to exceed the stringent standards in light, glare reduction and insulation from harmful UV rays. With superior heat and reflection properties it assists in maintaining an energy efficient environment. Giving you protection without blocking your view. Available in a range of colours that coordinate well with other Vertilux Greenvision® fabric ranges and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems.",

          images: [
            {
              name: EuroScreenMetallisedTransparentMAIN,
              alt: "Euroscreen® Metallised Transparent",
            },
          ],
          colorImages: [
            { name: EuroScreenMetallisedTransparentANTIQUE, alt: "Blind Fabric Colour Caramel" },
            { name: EuroScreenMetallisedTransparentBLACK2, alt: "Blind Fabric Colour Cinnamon" },
            { name: EuroScreenMetallisedTransparentGRAVEL, alt: "Blind Fabric Colour Haze" },
            { name: EuroScreenMetallisedTransparentOXIDE, alt: "Blind Fabric Colour Ice" },
            { name: EuroScreenMetallisedTransparentPEARL, alt: "Blind Fabric Colour Ironstone" },
            { name: EuroScreenMetallisedTransparentPOTTERY, alt: "Blind Fabric Colour Magnesium" },
          ],
          downloads: [
            { id: "1", name: "Vertilux_Euroscreen_Metallised Transparent_TechData_V10_0621.pdf" },
          ],
        },
        {
          id: "6",
          heading: "Euroscreen® ECO Metallised Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Euroscreen®",
          descriptionOne:
            "Euroscreen Eco Metallised Transparent is a high quality 100% Trevira CS ECO fabric uniting two properties of flame retardancy and sustainability to ensure a highly positive impact on the environment. It’s metallised back provides superior heat and reflection properties to assist in maintaining an energy efficient environment and insulation from harmful UV rays. It also has very low VOC emissions, exceeding the Green Building Council Australia standards.",

          images: [
            {
              name: EuroScreenEcoMetallisedTransparentMAIN,
              alt: "Euroscreen® ECO Metallised Transparent",
            },
          ],
          colorImages: [
            {
              name: EuroScreenEcoMetallisedTransparentCHICSHADOW,
              alt: "Blind Fabric Colour Chic Shadow",
            },
            { name: EuroScreenEcoMetallisedTransparentCOVE, alt: "Blind Fabric Colour Cove" },
            { name: EuroScreenEcoMetallisedTransparentHORIZON, alt: "Blind Fabric Colour Horizon" },
            {
              name: EuroScreenEcoMetallisedTransparentMIDNIGHT,
              alt: "Blind Fabric Colour Midnight",
            },
            {
              name: EuroScreenEcoMetallisedTransparentSNOWBANK,
              alt: "Blind Fabric Colour Snowbank",
            },
            {
              name: EuroScreenEcoMetallisedTransparentWHITEWISP,
              alt: "Blind Fabric Colour White wisp",
            },
          ],
          downloads: [
            { id: "1", name: "Vertilux_Euroscreen_Metallised Transparent_TechData_V10_0621.pdf" },
          ],
        },
        {
          id: "7",
          heading: "Euroscreen® ECO Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Euroscreen®",
          descriptionOne:
            "Euroscreen Eco Transparent is a high quality 100% Trevira CS ECO fabric uniting two properties of flame retardancy and sustainability to ensure a highly positive impact on the environment. It has been designed to meet stringent standards in light glare and insulation from harmful UV rays, and has very low VOC emissions, exceeding the Green Building Council Australia standards. Euroscreen® Eco’s superior heat and reflection properties assist in maintaining an energy efficient environment.",

          images: [
            {
              name: EuroScreenEcoTransparentMAIN,
              alt: "Euroscreen® ECO Transparent",
            },
          ],
          colorImages: [
            { name: EuroScreenEcoTransparentAMMO1, alt: "Blind Fabric Colour Ammo" },
            { name: EuroScreenEcoTransparentBLANC, alt: "Blind Fabric Colour Blanc" },
            { name: EuroScreenEcoTransparentGUNMETAL1, alt: "Blind Fabric Colour Gun Metal" },
            { name: EuroScreenEcoTransparentNERO, alt: "Blind Fabric Colour Nero" },
            { name: EuroScreenEcoTransparentPEBBLE, alt: "Blind Fabric Colour Pebble" },
            { name: EuroScreenEcoTransparentRIVERSTONE, alt: "Blind Fabric Colour Rivestone" },
            { name: EuroScreenEcoTransparentSMOKE, alt: "Blind Fabric Colour Smoke" },
            { name: EuroScreenEcoTransparentWHISPER, alt: "Blind Fabric Colour Whisper" },
          ],
          downloads: [
            { id: "1", name: "Vertilux_Euroscreen Eco_Transparent_TechData_V1_0621.pdf" },
          ],
        },
        {
          id: "8",
          heading: "Euroscreen® ECO Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Euroscreen®",
          descriptionOne:
            "Euroscreen Eco Transparent is a high quality 100% Trevira CS ECO fabric uniting two properties of flame retardancy and sustainability to ensure a highly positive impact on the environment. It has been designed to meet stringent standards in light glare and insulation from harmful UV rays, and has very low VOC emissions, exceeding the Green Building Council Australia standards. Euroscreen® Eco’s superior heat and reflection properties assist in maintaining an energy efficient environment.",

          images: [
            {
              name: EuroScreenTransparentMAIN,
              alt: "Euroscreen® ECO Transparent",
            },
          ],
          colorImages: [
            { name: EuroScreenTransparentAMMO1, alt: "Blind Fabric Colour Ammo" },
            { name: EuroScreenTransparentBLANC, alt: "Blind Fabric Colour Blanc" },
            { name: EuroScreenTransparentGUNMETAL1, alt: "Blind Fabric Colour Gun Metal" },
            { name: EuroScreenTransparentNERO, alt: "Blind Fabric Colour Nero" },
            { name: EuroScreenTransparentPEBBLE, alt: "Blind Fabric Colour Pebble" },
            { name: EuroScreenTransparentRIVERSTONE, alt: "Blind Fabric Colour Rivestone" },
            { name: EuroScreenTransparentSMOKE, alt: "Blind Fabric Colour Smoke" },
            { name: EuroScreenTransparentWHISPER, alt: "Blind Fabric Colour Whisper" },
          ],
          downloads: [{ id: "1", name: "Vertilux_Euroscreen_Transparent_TechData_V8_0216.pdf" }],
        },
        {
          id: "9",
          heading: "Euroview™ Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Euroview™",
          descriptionOne:
            "The Vertilux Euroview™ fabric range is a high quality 100% Trevira CS fabric. Euroview™ is an open weave transparent fabric that is designed to assist with light glare reduction and insulation from harmful UV rays. An elegant sheer fabric, Euroview™ is available in a range of colours that coordinate well with other Vertilux Greenvision® fabric ranges and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems.",

          images: [
            {
              name: EuroViewTransparentMAIN,
              alt: "Euroview™ Transparent",
            },
          ],
          colorImages: [
            { name: EuroViewTransparentCLAY, alt: "Blind Fabric Colour Clay" },
            { name: EuroViewTransparentCOAL, alt: "Blind Fabric Colour Coal" },
            { name: EuroViewTransparentDOVE, alt: "Blind Fabric Colour Dove" },
            { name: EuroViewTransparentRICE, alt: "Blind Fabric Colour Rice" },
            { name: EuroViewTransparentSPACE, alt: "Blind Fabric Colour Space" },
            { name: EuroViewTransparentWHITE, alt: "Blind Fabric Colour White" },
          ],
          downloads: [{ id: "1", name: "Vertilux_Euroview_Transparent_TechData_V11_0621.pdf" }],
        },
        {
          id: "10",
          heading: "Eurovision® Metallised Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Euroview™",
          descriptionOne:
            "The Vertilux Eurovision® Metallised range is a high quality 100% Trevira CS fabric. Its engineered fibre is specifically woven to a screen-like finish. Eurovision® Metallised is highly transparent, enabling clear visibility through the fabric. The fabric is low glare while still achieving excellent Rs (Solar Reflection) values. It is designed to exceed stringent standards in light glare reduction and insulation from harmful UV rays, and has very low VOC emissions, exceeding the Green Building Council Australia standards. A dimensionally stable, well-weighted fabric that hangs consistently flat and does not warp on its edges or at the base rail, its manufacture is certified to DIN EN ISO 14001, 9001 and 50001. With superior heat and reflection properties it assists in maintaining an energy efficient environment. Giving you protection without blocking your view. Available in a range of colours that coordinate well with other Vertilux Greenvision® fabric ranges, and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems.",

          images: [
            {
              name: EuroVisionMetallisedTransparentMAIN,
              alt: "Eurovision® Metallised Transparent",
            },
          ],
          colorImages: [
            { name: EuroVisionMetallisedTransparentHELSINKI, alt: "Blind Fabric Colour Helsinki" },
            { name: EuroVisionMetallisedTransparentISTANBUL, alt: "Blind Fabric Colour Istanbul" },
            { name: EuroVisionMetallisedTransparentKIEV, alt: "Blind Fabric Colour Kiev" },
            { name: EuroVisionMetallisedTransparentZAGREB, alt: "Blind Fabric Colour Zagreb" },
          ],
          downloads: [
            { id: "1", name: "Vertilux_EuroVision_MetallisedTransparent_TechData_V10_0621.pdf" },
          ],
        },
        {
          id: "11",
          heading: "Eurovision® Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Euroview™",
          descriptionOne:
            "The Vertilux Eurovision® Metallised range is a high quality 100% Trevira CS fabric. Its engineered fibre is specifically woven to a screen-like finish. Eurovision® Metallised is highly transparent, enabling clear visibility through the fabric. The fabric is low glare while still achieving excellent Rs (Solar Reflection) values. It is designed to exceed stringent standards in light glare reduction and insulation from harmful UV rays, and has very low VOC emissions, exceeding the Green Building Council Australia standards. A dimensionally stable, well-weighted fabric that hangs consistently flat and does not warp on its edges or at the base rail, its manufacture is certified to DIN EN ISO 14001, 9001 and 50001. With superior heat and reflection properties it assists in maintaining an energy efficient environment. Giving you protection without blocking your view. Available in a range of colours that coordinate well with other Vertilux Greenvision® fabric ranges, and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems.",
          images: [
            {
              name: EuroVisionTransparentMAIN,
              alt: "Eurovision® Transparent",
            },
          ],
          colorImages: [
            { name: EuroVisionTransparentDUBLIN, alt: "Blind Fabric Colour Dublin" },
            { name: EuroVisionTransparentEDINBURGH, alt: "Blind Fabric Colour Edinburgh" },
            { name: EuroVisionTransparentMUNICH, alt: "Blind Fabric Colour Munich" },
            { name: EuroVisionTransparentOSLO, alt: "Blind Fabric Colour Oslo" },
          ],
          downloads: [{ id: "1", name: "Vertilux_EuroVision_Transparent_TechData_V10_0621.pdf" }],
        },
        {
          id: "12",
          heading: "Evoscreen Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Evoscreen",
          descriptionOne:
            "Evoscreen Transparent is a high quality 50% Trevira CS & 50% Polyester fabric combination designed for performance & sustainability. The plain weave of our Evoscreen range offers a tone-on-tone pattern that sits comfortably with any design style – traditional or contemporary. Evoscreen Transparent gives you protection while allowing for uninterrupted views of the outside environment.",
          images: [
            {
              name: EvoscreenTransparentMAIN,
              alt: "Evoscreen Transparent",
            },
          ],
          colorImages: [
            { name: EvoscreenTransparentAZZURE, alt: "Blind Fabric Colour Azzure" },
            { name: EvoscreenTransparentINSPIRON, alt: "Blind Fabric Colour Inspiron" },
            { name: EvoscreenTransparentLUMINARE, alt: "Blind Fabric Colour Luminare" },
            { name: EvoscreenTransparentMOMENTUM, alt: "Blind Fabric Colour Momentum" },
            { name: EvoscreenTransparentPHANTOM, alt: "Blind Fabric Colour Phantom" },
            { name: EvoscreenTransparentSPECTRE, alt: "Blind Fabric Colour Spectre" },
          ],
          downloads: [{ id: "1", name: "Vertilux_Evoscreen_Transparent_TechData_V3_0821.pdf" }],
        },
        {
          id: "13",
          heading: "Flamingo™ Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Flamingo™",
          descriptionOne:
            "Our Flamingo™ Room Darkening range is a high quality 100% Polyester room darkening fabric designed to meet stringent standards in light, glare reduction and insulation from harmful UV rays. The plain textured weave of Flamingo™ Room Darkening gives a modern look to any environment and is suitable for Roller Blinds, Roman Blinds or Panel Glide Systems.",
          images: [
            {
              name: FlamingoRoomDarkeningMAIN,
              alt: "Flamingo™ Room Darkening",
            },
          ],
          colorImages: [
            { name: FlamingoRoomDarkeningASH, alt: "Blind Fabric Colour Ash" },
            { name: FlamingoRoomDarkeningBLACK, alt: "Blind Fabric Colour Black" },
            { name: FlamingoRoomDarkeningCREAM, alt: "Blind Fabric Colour Cream" },
            { name: FlamingoRoomDarkeningSILVER, alt: "Blind Fabric Colour Silver" },
            { name: FlamingoRoomDarkeningSNOW, alt: "Blind Fabric Colour Snow" },
            { name: FlamingoRoomDarkeningSTEEL, alt: "Blind Fabric Colour Steel" },
            { name: FlamingoRoomDarkeningSTONE, alt: "Blind Fabric Colour Stone" },
            { name: FlamingoRoomDarkeningWHITE, alt: "Blind Fabric Colour White" },
          ],
          downloads: [{ id: "1", name: "Flamingo RD.pdf" }],
        },
        {
          id: "14",
          heading: "Flamingo™ Translucent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Flamingo™",
          descriptionOne:
            "Flamingo™ Translucent range is a high quality 100% Polyester fabric designed to meet stringent standards in light, glare reduction and insulation from harmful UV rays. The plain textured weave of Flamingo™ gives a modern look to any environment and allows light to be filtered into a room where privacy is required yet total blockout is unnecessary. Suitable for Roller Blinds, Roman Blinds or Panel Glide Systems.",
          images: [
            {
              name: FlamingoTranslucentMAIN,
              alt: "Flamingo™ Translucent",
            },
          ],
          colorImages: [
            { name: FlamingoTranslucentASH, alt: "Blind Fabric Colour Ash" },
            { name: FlamingoTranslucentBLACK, alt: "Blind Fabric Colour Black" },
            { name: FlamingoTranslucentCREAM, alt: "Blind Fabric Colour Cream" },
            { name: FlamingoTranslucentSILVER, alt: "Blind Fabric Colour Silver" },
            { name: FlamingoTranslucentSNOW, alt: "Blind Fabric Colour Snow" },
            { name: FlamingoTranslucentSTEEL, alt: "Blind Fabric Colour Steel" },
            { name: FlamingoTranslucentSTONE, alt: "Blind Fabric Colour Stone" },
            { name: FlamingoTranslucentWHITE, alt: "Blind Fabric Colour White" },
          ],
          downloads: [{ id: "1", name: "Flamingo Trans.pdf" }],
        },
        {
          id: "15",
          heading: "Hamilton™ Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Hamilton™",
          descriptionOne:
            "Hamilton is an Australian Made jacquard. Woven from 100% Polyester to achieve a subtle texture and with a soft lustre, then coated on the back with an acrylic foam to create a room darkening fabric. Hamilton® Room Darkening fabric does not allow light into the room, giving total privacy. This jacquard weave is available in a range of colours that will give another dimension to the surrounding furnishings that would comfortably compliment just about any environments.",
          images: [
            {
              name: HamiltonRoomDarkeningMAIN,
              alt: "Hamilton™ Room Darkening",
            },
          ],
          colorImages: [
            { name: HamiltonRoomDarkeningALABASTER, alt: "Blind Fabric Colour Alabaster" },
            { name: HamiltonRoomDarkeningANTHRACITE, alt: "Blind Fabric Colour Anthracite" },
            { name: HamiltonRoomDarkeningCADMIUM, alt: "Blind Fabric Colour Cadmium" },
            { name: HamiltonRoomDarkeningECRU, alt: "Blind Fabric Colour Ecru" },
            { name: HamiltonRoomDarkeningGARDENIA, alt: "Blind Fabric Colour Gardenia" },
            { name: HamiltonRoomDarkeningINK, alt: "Blind Fabric Colour Ink" },
            { name: HamiltonRoomDarkeningMORNING, alt: "Blind Fabric Colour Morning" },
            { name: HamiltonRoomDarkeningMOUSE, alt: "Blind Fabric Colour Mouse" },
            { name: HamiltonRoomDarkeningNUTMEG, alt: "Blind Fabric Colour Nutmeg" },
            { name: HamiltonRoomDarkeningRATTAN, alt: "Blind Fabric Colour Rattan" },
          ],
          downloads: [{ id: "1", name: "Vertilux_Hamilton_RoomDarkening_TechData_V10_220819.pdf" }],
        },
        {
          id: "16",
          heading: "Jewel™ Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Jewel™",
          descriptionOne:
            "Not only Jewel by name but also by weave. If the thread of silver woven into this subtle jacquard pattern tweaks your interest then these images below don’t tell the full story. This is one fabric you will need to see for yourself. This Jewel™ Room Darkening fabric does not allow light into the room, giving total privacy. Perfect partners with Jewel™ Translucent, together or as a textural match to the rest of the Vertilux fabric ranges, when different light control is required at the window.",
          images: [
            {
              name: JewellRoomDarkeningMAIN,
              alt: "Jewel™ Room Darkening",
            },
          ],
          colorImages: [
            { name: JewellRoomDarkeningADVENTURINE, alt: "Blind Fabric Colour Adventurine" },
            { name: JewellRoomDarkeningHEMATITE, alt: "Blind Fabric Colour Hematite" },
            { name: JewellRoomDarkeningTOPAZ, alt: "Blind Fabric Colour Topaz" },
          ],
          downloads: [{ id: "1", name: "Vertilux_Jewel_RoomDarkening_TechData_V10_220819.pdf" }],
        },
        {
          id: "17",
          heading: "Journey™ Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Journey™",
          descriptionOne:
            "Journey is an Australian Made jacquard. Woven from 100% Polyester to achieve a striking two-tone textural effect with a soft sheen, then coated on the back with an acrylic foam to create a Room Darkening fabric. Journey® is a self coordinating design available in both Room Darkening and a Translucent finish.This jacquard weave is available in a range of colours that will add a fine detail to any window as coordinating partners or with any other of the Vertilux fabric ranges. Suitable for Roller Blinds, Roman Blinds or Panel Glides.",
          images: [
            {
              name: JourneyRoomDarkeningMAIN,
              alt: "Journey™ Room Darkening",
            },
          ],
          colorImages: [
            {
              name: JourneyRoomDarkeningASHMISTY,
              alt: "Blind Fabric Colour ASHMISTY",
            },
            {
              name: JourneyRoomDarkeningBAUXITEGRIT,
              alt: "Blind Fabric Colour BAUXITEGRIT",
            },
            {
              name: JourneyRoomDarkeningBLEACHSNOWWHITE,
              alt: "Blind Fabric Colour BLEACHSNOWWHITE",
            },
            {
              name: JourneyRoomDarkeningGLACIERPAPER,
              alt: "Blind Fabric Colour GLACIERPAPER",
            },
            {
              name: JourneyRoomDarkeningHESSIANPEAT,
              alt: "Blind Fabric Colour HESSIANPEAT",
            },
            {
              name: JourneyRoomDarkeningMALTSEED,
              alt: "Blind Fabric Colour MALTSEED",
            },
            {
              name: JourneyRoomDarkeningRAWCHALKSTONE,
              alt: "Blind Fabric Colour RAWCHALKSTONE",
            },
            {
              name: JourneyRoomDarkeningSTORMCARIBOU,
              alt: "Blind Fabric Colour STORMCARIBOU",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Journey_RoomDarkening_TechData_V1_0216.pdf",
            },
          ],
        },
        {
          id: "18",
          heading: "Lexington™ Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Lexington™",
          descriptionOne:
            "Lexington is an Australian Made jacquard. Woven from 100% Polyester to achieve an organic two-tone textural effect with just a hint of sheen and coloured in a range of natural hues. The woven fabric is coated on the back with an acrylic foam to create a Room Darkening fabric. Lexington will coordinate well with any other of the Vertilux fabric ranges to achieve different light control as required. Suitable for Roller Blinds, Roman Blinds or Panel Glides.",
          images: [
            {
              name: LexingtonRoomDarkeningMAIN,
              alt: "Lexington™ Room Darkening",
            },
          ],
          colorImages: [
            {
              name: LexingtonRoomDarkeningGRAVEL,
              alt: "Blind Fabric Colour GRAVEL",
            },
            {
              name: LexingtonRoomDarkeningHAVANA,
              alt: "Blind Fabric Colour HAVANA",
            },
            {
              name: LexingtonRoomDarkeningJET,
              alt: "Blind Fabric Colour JET",
            },
            {
              name: LexingtonRoomDarkeningMIST,
              alt: "Blind Fabric Colour MIST",
            },
            {
              name: LexingtonRoomDarkeningPEWTER,
              alt: "Blind Fabric Colour PEWTER",
            },
            {
              name: LexingtonRoomDarkeningPOLAR,
              alt: "Blind Fabric Colour POLAR",
            },
            {
              name: LexingtonRoomDarkeningROSETTA,
              alt: "Blind Fabric Colour ROSETTA",
            },
            {
              name: LexingtonRoomDarkeningSHALE,
              alt: "Blind Fabric Colour SHALE",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Lexington_RoomDarkening_TechData_V1_0216.pdf",
            },
          ],
        },

        {
          id: "19",
          heading: "Madison Two™ Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Madison Two™",
          descriptionOne:
            "The Vertilux Madison Two™ fabric is made from a high quality 100% Polyester with acrylic foam backing. With superior heat and reflection properties it assists in maintaining an energy efficient environment. Madison Two™ does not allow light into the room, giving total privacy. Woven to a fine fabric weave with just a glimpse of lustre giving it a stylish, contemporary look. Available in a range of colours that coordinate well with other Vertilux fabric ranges and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems. For these applications the Madison Two™ Room Darkening fabric is used in conjunction with the Vertilux Fully Enclosed Kassett System.",
          images: [
            {
              name: MadisonTwoRoomDarkeningMAIN,
              alt: "Madison Two™ Room Darkening",
            },
          ],
          colorImages: [
            {
              name: MadisonTwoRoomDarkeningALPACHA,
              alt: "Blind Fabric Colour ALPACHA",
            },
            {
              name: MadisonTwoRoomDarkeningBLUESTONE,
              alt: "Blind Fabric Colour BLUESTONE",
            },
            {
              name: MadisonTwoRoomDarkeningCHAMPAGNE,
              alt: "Blind Fabric Colour CHAMPAGNE",
            },
            {
              name: MadisonTwoRoomDarkeningCONCRETE,
              alt: "Blind Fabric Colour CONCRETE",
            },
            {
              name: MadisonTwoRoomDarkeningGHOST,
              alt: "Blind Fabric Colour GHOST",
            },
            {
              name: MadisonTwoRoomDarkeningKNIGHT,
              alt: "Blind Fabric Colour KNIGHT",
            },
            {
              name: MadisonTwoRoomDarkeningLICORICE,
              alt: "Blind Fabric Colour LICORICE",
            },
            {
              name: MadisonTwoRoomDarkeningPLATINUM,
              alt: "Blind Fabric Colour PLATINUM",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_MadisonTwo_RoomDarkening_TechData_V3_1019.pdf",
            },
          ],
        },
        {
          id: "20",
          heading: "Metalo® Metallised Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Metalo®",
          descriptionOne:
            "The Vertilux Metalo® Metallised range is a high quality 100% Trevira CS fabric. Metalo® is a transparent fabric with a metallised back that is designed to exceed the stringent standards in light, glare reduction and insulation from harmful UV rays. With superior heat and reflection properties it assists in maintaining an energy efficient environment. Giving you protection, without interrupting your view. Available in colours that coordinate well with other Vertilux fabric ranges and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems.",
          images: [
            {
              name: MetaloMetallisedTransparentMAIN,
              alt: "Metalo® Metallised Transparent",
            },
          ],
          colorImages: [
            {
              name: MetaloMetallisedTransparentAMMO,
              alt: "Blind Fabric Colour AMMO",
            },
            {
              name: MetaloMetallisedTransparentGREY,
              alt: "Blind Fabric Colour GREY",
            },
            {
              name: MetaloMetallisedTransparentGUNMETAL,
              alt: "Blind Fabric Colour GUNMETAL",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Metalo_MetallisedTransparent_TechData_V7_0216.pdf",
            },
          ],
        },
        {
          id: "21",
          heading: "Metalo® Metallised Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Metalo®",
          descriptionOne:
            "The Vertilux Metalo® Metallised range is a high quality 100% Trevira CS fabric. Metalo® is a transparent fabric with a metallised back that is designed to exceed the stringent standards in light, glare reduction and insulation from harmful UV rays. With superior heat and reflection properties it assists in maintaining an energy efficient environment. Giving you protection, without interrupting your view. Available in colours that coordinate well with other Vertilux fabric ranges and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems.",
          images: [
            {
              name: MetaloMetallisedTransparentMAIN,
              alt: "Metalo® Metallised Transparent",
            },
          ],
          colorImages: [
            {
              name: MetaloMetallisedTransparentAMMO,
              alt: "Blind Fabric Colour AMMO",
            },
            {
              name: MetaloMetallisedTransparentGREY,
              alt: "Blind Fabric Colour GREY",
            },
            {
              name: MetaloMetallisedTransparentGUNMETAL,
              alt: "Blind Fabric Colour GUNMETAL",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Metalo_MetallisedTransparent_TechData_V7_0216.pdf",
            },
          ],
        },
        {
          id: "22",
          heading: "Silverstone™ Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Silverstone™",
          descriptionOne:
            "The Vertilux Silverstone™ Room Darkening fabric is made from high quality 100% Polyester fabric.Silverstone™ is a metallised fabric with excellent heat and reflection properties that can assist in maintaining an energy efficient environment and is designed to meet stringent standards in light glare reduction and insulation from harmful UV rays. Available in a range of colours that coordinate well with other Vertilux fabric ranges and can be used with the Vertilux Roller Blind and Panel Glide Systems. Silverstone™ Room Darkening fabric is also perfect for use in cinema rooms and the like, when maximum room darkness is required. For these applications the Silverstone™ Room Darkening fabric is used in conjunction with the Vertilux Fully Enclosed Kassett System.",
          images: [
            {
              name: SilverstoneRoomDarkeningMAIN,
              alt: "Silverstone™ Room Darkening",
            },
          ],
          colorImages: [
            {
              name: SilverstoneRoomDarkeningBALSASAMPLE,
              alt: "Blind Fabric Colour BALSASAMPLE",
            },
            {
              name: SilverstoneRoomDarkeningCHINASAMPLE,
              alt: "Blind Fabric Colour CHINASAMPLE",
            },
            {
              name: SilverstoneRoomDarkeningLINENSAMPLE,
              alt: "Blind Fabric Colour LINENSAMPLE",
            },
            {
              name: SilverstoneRoomDarkeningSHALESSAMPLE,
              alt: "Blind Fabric Colour SHALESSAMPLE",
            },
            {
              name: SilverstoneRoomDarkeningTUXEDO,
              alt: "Blind Fabric Colour TUXEDO",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Silverstone_RoomDarkening_TechData_V4_0216.pdf",
            },
          ],
        },
        {
          id: "23",
          heading: "Sorrento™ Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Sorrento™",
          descriptionOne:
            "Sorrento™ Room Darkening is an Australian made high quality 100% Polyester fabric. The unique weave of our Sorrento™ fabrics offer a textural tone-ontone pattern that sits comfortably with any design style – traditional or contemporary. Sorrento™ Room Darkening is suitable for Roller Blinds, Roman Blinds or Panel Glide Systems.",
          images: [
            {
              name: SorrentoRoomDarkeningMAIN,
              alt: "Sorrento™ Room Darkening",
            },
          ],
          colorImages: [
            {
              name: SorrentoRoomDarkeningBLAZER,
              alt: "Blind Fabric Colour BLAZER",
            },
            {
              name: SorrentoRoomDarkeningCHIFFON,
              alt: "Blind Fabric Colour CHIFFON",
            },
            {
              name: SorrentoRoomDarkeningCHROME,
              alt: "Blind Fabric Colour CHROME",
            },
            {
              name: SorrentoRoomDarkeningEARLGREY,
              alt: "Blind Fabric Colour EARLGREY",
            },
            {
              name: SorrentoRoomDarkeningOYSTER,
              alt: "Blind Fabric Colour OYSTER",
            },
            {
              name: SorrentoRoomDarkeningPORCELAIN,
              alt: "Blind Fabric Colour PORCELAIN",
            },
            {
              name: SorrentoRoomDarkeningRAVEN,
              alt: "Blind Fabric Colour RAVEN",
            },
            {
              name: SorrentoRoomDarkeningSAIL,
              alt: "Blind Fabric Colour SAIL",
            },
            {
              name: SorrentoRoomDarkeningSWAN,
              alt: "Blind Fabric Colour SWAN",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Sorrento RD.pdf",
            },
          ],
        },
        {
          id: "24",
          heading: "Sorrento™ Translucent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Sorrento™",
          descriptionOne:
            "Sorrento™ Translucent is an Australian made from high quality 100% Polyester fabric. The unique weave of our Sorrento™ fabrics offer a textural tone-on-tone pattern that sits comfortably with any design style – traditional or contemporary. Sorrento™ Translucent allows light to filter into the room where privacy is required.",
          images: [
            {
              name: SorrentoTranslucentMAIN,
              alt: "Sorrento™ Translucent",
            },
          ],
          colorImages: [
            {
              name: SorrentoTranslucentAMALFIT,
              alt: "Blind Fabric Colour AMALFIT",
            },
            {
              name: SorrentoTranslucentBASILICATAT,
              alt: "Blind Fabric Colour BASILICATAT",
            },
            {
              name: SorrentoTranslucentCORSOT,
              alt: "Blind Fabric Colour CORSOT",
            },
            {
              name: SorrentoTranslucentGROTTOT,
              alt: "Blind Fabric Colour GROTTOT",
            },
            {
              name: SorrentoTranslucentLUCANIAT,
              alt: "Blind Fabric Colour LUCANIAT",
            },
            {
              name: SorrentoTranslucentPIAZZAT,
              alt: "Blind Fabric Colour PIAZZAT",
            },
            {
              name: SorrentoTranslucentPOSITANOT,
              alt: "Blind Fabric Colour POSITANOT",
            },
            {
              name: SorrentoTranslucentPOTENZAT,
              alt: "Blind Fabric Colour POTENZAT",
            },
            {
              name: SorrentoTranslucentRAVELLOT,
              alt: "Blind Fabric Colour RAVELLOT",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Sorrento Trans.pdf",
            },
          ],
        },
        {
          id: "25",
          heading: "Status 10 Percent™ Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Status™",
          descriptionOne:
            "Status is a Basketweave 2 x 2 woven construction that allows insulation from harmful UV rays with heat and reflection properties that assists in maintaining an energy efficient environment. Available in colours and openness values as shown.  The 10% gives a crisper view to the outside and the 5% openness, in the colours shown, also has good visual through the weave and meets the Green Building Council of Australia (G.B.C.A.) requirement of a fabric with a Vlt value of \u003c10%. There are a range of stylish tonal colours to select from. Suitable for use on the Vertilux Roller Blind and Panel Glide Systems.",
          images: [
            {
              name: StatusTenTransparentMAIN,
              alt: "Status 10 Percent™ Transparent",
            },
          ],
          colorImages: [
            {
              name: StatusTenTransparentBASALT,
              alt: "Blind Fabric Colour BASALT",
            },
            {
              name: StatusTenTransparentCARBON,
              alt: "Blind Fabric Colour CARBON",
            },
            {
              name: StatusTenTransparentCOPPER,
              alt: "Blind Fabric Colour COPPER",
            },
            {
              name: StatusTenTransparentCORNSILK,
              alt: "Blind Fabric Colour CORNSILK",
            },
            {
              name: StatusTenTransparentFOG,
              alt: "Blind Fabric Colour FOG",
            },
            {
              name: StatusTenTransparentGLACIER,
              alt: "Blind Fabric Colour GLACIER",
            },
            {
              name: StatusTenTransparentLIMESTONE,
              alt: "Blind Fabric Colour LIMESTONE",
            },
            {
              name: StatusTenTransparentMAGNOLIA,
              alt: "Blind Fabric Colour MAGNOLIA",
            },
            {
              name: StatusTenTransparentMARBLE,
              alt: "Blind Fabric Colour MARBLE",
            },
            {
              name: StatusTenTransparentOATMEAL,
              alt: "Blind Fabric Colour OATMEAL",
            },
            {
              name: StatusTenTransparentPITCH,
              alt: "Blind Fabric Colour PITCH",
            },
            {
              name: StatusTenTransparentSTATUSGRAPNEL,
              alt: "Blind Fabric Colour STATUSGRAPNEL",
            },
            {
              name: StatusTenTransparentSTATUSMISTRAL,
              alt: "Blind Fabric Colour STATUSMISTRAL",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Status 10%Transparent_TechData_V4_1122.pdf",
            },
          ],
        },
        {
          id: "26",
          heading: "Status 5%™ Transparent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Status™",
          descriptionOne:
            "Status is a Basketweave 2 x 2 woven construction that allows insulation from harmful UV rays with heat and reflection properties that assists in maintaining an energy efficient environment. Available in colours and openness values as shown.  The 10% gives a crisper view to the outside and the 5% openness, in the colours shown, also has good visual through the weave and meets the Green Building Council of Australia (G.B.C.A.) requirement of a fabric with a Vlt value of \u003c10%. There are a range of stylish tonal colours to select from. Suitable for use on the Vertilux Roller Blind and Panel Glide Systems.",
          images: [
            {
              name: StatusFiveTransparentMAIN,
              alt: "Status 5%™ Transparent",
            },
          ],
          colorImages: [
            {
              name: StatusFiveTransparentANCHOR,
              alt: "Blind Fabric Colour ANCHOR",
            },
            {
              name: StatusFiveTransparentARGENT,
              alt: "Blind Fabric Colour ARGENT",
            },
            {
              name: StatusFiveTransparentBIRCHER,
              alt: "Blind Fabric Colour BIRCHER",
            },
            {
              name: StatusFiveTransparentDAISY,
              alt: "Blind Fabric Colour DAISY",
            },
            {
              name: StatusFiveTransparentGRANITE,
              alt: "Blind Fabric Colour GRANITE",
            },
            {
              name: StatusFiveTransparentGRAPHITE,
              alt: "Blind Fabric Colour GRAPHITE",
            },
            {
              name: StatusFiveTransparentHAZE,
              alt: "Blind Fabric Colour HAZE",
            },
            {
              name: StatusFiveTransparentKERNAL,
              alt: "Blind Fabric Colour KERNAL",
            },
            {
              name: StatusFiveTransparentLEAD,
              alt: "Blind Fabric Colour LEAD",
            },
            {
              name: StatusFiveTransparentPORPHYRY,
              alt: "Blind Fabric Colour PORPHYRY",
            },
            {
              name: StatusFiveTransparentQUARTZ,
              alt: "Blind Fabric Colour QUARTZ",
            },
            {
              name: StatusFiveTransparentRICHCOPPER,
              alt: "Blind Fabric Colour RICHCOPPER",
            },
            {
              name: StatusFiveTransparentSNOW,
              alt: "Blind Fabric Colour SNOW",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Status 10%Transparent_TechData_V4_1122.pdf",
            },
          ],
        },

        {
          id: "27",
          heading: "Yang™ Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Yang™",
          descriptionOne:
            "The Vertilux Yang™ Room Darkening range is made in Australia to suit Australian conditions.  It provides insulation from harmful UV rays with heat and reflection properties that assists in maintaining an energy efficient environment. Available in a wide colour palette, it coordinates with other Vertilux fabric ranges and can be used with the Vertilux Roller Blind, Panel Glide System and Vertical Blind Systems.",
          images: [
            {
              name: YangRoomDarkeningMAIN,
              alt: "Yang™ Room Darkening",
            },
          ],
          colorImages: [
            {
              name: YangRoomDarkeningYANGBOUNTY,
              alt: "Blind Fabric Colour YANGBOUNTY",
            },
            {
              name: YangRoomDarkeningYANGCASHMERE,
              alt: "Blind Fabric Colour YANGCASHMERE",
            },
            {
              name: YangRoomDarkeningYANGCHALKWHITE,
              alt: "Blind Fabric Colour YANGCHALKWHITE",
            },
            {
              name: YangRoomDarkeningYANGCHI,
              alt: "Blind Fabric Colour YANGCHI",
            },
            {
              name: YangRoomDarkeningYANGCOCOA,
              alt: "Blind Fabric Colour YANGCOCOA",
            },
            {
              name: YangRoomDarkeningYANGELEMENT,
              alt: "Blind Fabric Colour YANGELEMENT",
            },
            {
              name: YangRoomDarkeningYANGEMBRACE,
              alt: "Blind Fabric Colour YANGEMBRACE",
            },
            {
              name: YangRoomDarkeningYANGGAZING,
              alt: "Blind Fabric Colour YANGGAZING",
            },
            {
              name: YangRoomDarkeningYANGILLUSION,
              alt: "Blind Fabric Colour YANGILLUSION",
            },
            {
              name: YangRoomDarkeningYANGIMPRESSION,
              alt: "Blind Fabric Colour YANGIMPRESSION",
            },
            {
              name: YangRoomDarkeningYANGMARIO,
              alt: "Blind Fabric Colour YANGMARIO",
            },
            {
              name: YangRoomDarkeningYANGMARTINI,
              alt: "Blind Fabric Colour YANGMARTINI",
            },
            {
              name: YangRoomDarkeningYANGMINERAL,
              alt: "Blind Fabric Colour YANGMINERAL",
            },
            {
              name: YangRoomDarkeningYANGMOUNTAINMIST,
              alt: "Blind Fabric Colour YANGMOUNTAINMIST",
            },
            {
              name: YangRoomDarkeningYANGSAHARA,
              alt: "Blind Fabric Colour YANGSAHARA",
            },
            {
              name: YangRoomDarkeningYANGSTEALTH,
              alt: "Blind Fabric Colour YANGSTEALTH",
            },
            {
              name: YangRoomDarkeningYANGWHITEDOVE,
              alt: "Blind Fabric Colour YANGWHITEDOVE",
            },
            {
              name: YangRoomDarkeningYANGYEN,
              alt: "Blind Fabric Colour YANGYEN",
            },
            {
              name: YangRoomDarkeningYANGZELDA,
              alt: "Blind Fabric Colour YANGZELDA",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Yang_RoomDarkening_TechData_V3_0622.pdf",
            },
          ],
        },
        {
          id: "28",
          heading: "Zing® Room Darkening",
          product: "Vertilux",
          operation: "Fabric",
          details: "Zing®",
          descriptionOne:
            "The Vertilux Zing® Room Darkening is a high quality 100% Polyester fabric. Zing® offers a textural tone-on-tone pattern that would sit comfortably with any design style –  traditional and period architecture as well as the most modern of installations. Zing® is a room darkening fabric, which does not allow light into the room, giving total privacy. Available in a range of colours that coordinate well with other Vertilux fabric ranges and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems. Zing® Room Darkening fabric is also perfect for use in cinema rooms and the like, when maximum room darkness is required. For these applications the Zing® Room Darkening fabric is used in conjunction with the Vertilux Fully Enclosed Kassett System.",
          images: [
            {
              name: ZingRoomDarkeningMAIN,
              alt: "Zing® Room Darkening",
            },
          ],
          colorImages: [
            {
              name: ZingRoomDarkeningALBATROSS,
              alt: "Blind Fabric Colour ALBATROSS",
            },
            {
              name: ZingRoomDarkeningBISQU,
              alt: "Blind Fabric Colour BISQU",
            },
            {
              name: ZingRoomDarkeningDOVER,
              alt: "Blind Fabric Colour DOVER",
            },
            {
              name: ZingRoomDarkeningFLAX,
              alt: "Blind Fabric Colour FLAX",
            },
            {
              name: ZingRoomDarkeningLUNARWHITE,
              alt: "Blind Fabric Colour LUNARWHITE",
            },
            {
              name: ZingRoomDarkeningMACAW,
              alt: "Blind Fabric Colour MACAW",
            },
            {
              name: ZingRoomDarkeningNIGHTHAWK,
              alt: "Blind Fabric Colour NIGHTHAWK",
            },
            {
              name: ZingRoomDarkeningSHADOW,
              alt: "Blind Fabric Colour SHADOW",
            },
            {
              name: ZingRoomDarkeningTWILIGHT,
              alt: "Blind Fabric Colour TWILIGHT",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Zing_RoomDarkening_TechData_V10_220819.pdf",
            },
          ],
        },

        {
          id: "29",
          heading: "Zing® Translucent",
          product: "Vertilux",
          operation: "Fabric",
          details: "Zing®",
          descriptionOne:
            "The Vertilux Zing® Translucent is a high quality 100% Polyester fabric.. Zing® offers a textural tone-on-tone pattern that would sit comfortably with any design style – traditional and period architecture as well as the most modern of installations. Zing® allows light to filter into the room where privacy is required yet total blockout is unnecessary. Available in a range of colours that coordinate well with other Vertilux fabric ranges and can be used with the Vertilux Roller Blind, Roman Blind or Panel Glide Systems.",
          images: [
            {
              name: ZingTranslucentMAIN,
              alt: "Zing® Translucent",
            },
          ],
          colorImages: [
            {
              name: ZingTranslucentALBATROSS,
              alt: "Blind Fabric Colour ALBATROSS",
            },
            {
              name: ZingTranslucentBISQU,
              alt: "Blind Fabric Colour BISQU",
            },
            {
              name: ZingTranslucentDOVER,
              alt: "Blind Fabric Colour DOVER",
            },
            {
              name: ZingTranslucentFLAX,
              alt: "Blind Fabric Colour FLAX",
            },
            {
              name: ZingTranslucentLUNARWHITE,
              alt: "Blind Fabric Colour LUNARWHITE",
            },
            {
              name: ZingTranslucentMACAW,
              alt: "Blind Fabric Colour MACAW",
            },
            {
              name: ZingTranslucentNIGHTHAWK,
              alt: "Blind Fabric Colour NIGHTHAWK",
            },
            {
              name: ZingTranslucentSHADOW,
              alt: "Blind Fabric Colour SHADOW",
            },
            {
              name: ZingTranslucentTWILIGHT,
              alt: "Blind Fabric Colour TWILIGHT",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_Zing_Translucent_TechData_V10_220819.pdf",
            },
          ],
        },
      ],
      accessories: [],
    },
  },
  {
    id: "2",
    type: "curtains",
    heading: "Curtains",
    images: [{ name: CurtainImage, alt: "Curtains" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "Multiplo 412",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          curtainType: ["Manual"],
          installation: ["Ceiling mount", "Wall mount"],
          direction: ["Straight", "Curved"],
          descriptionOne: "Hand or cord operated.",
          descriptionTwo:
            "For use with curtains 500cm width max. and 300cm height, weighing not more than 6kg. The curtain opening can be central or lateral; (multiple or asymmetric by hand only). The system is suitable for ceiling and wall installation. With hand use the system allows systems curved through 90° or with wide radius centring to be installed. Only straight systems can be installed with cord operating.",
          descriptionThree:
            "Sliding is guaranteed by self-lubricating polyethylene slides or by acetylic roller gliders for curtains at the load limit.",
          descriptionFour: "",
          images: [
            {
              name: Multiplo412b,
              alt: "Multiplo412",
            },
          ],
          standAloneImg: {
            name: Multiplo412a,
            alt: "Multiplo412",
          },
          techSpecs: [
            {
              name: "Hand Traverse",
              description: "6060 T6 UNI 8278 extruded aluminium alloy track",
            },
            {
              name: "Hand Traverse",
              description: "Width 14mm; Height 23mm - Sliding channel: 6mm",
            },
            {
              name: "Hand Traverse",
              description:
                "Minimum radius of curvature: 90° curves: 12cm; wide radius curves: 50cm",
            },
            {
              name: "Hand Traverse",
              description: "Track colours: Anodised Silver; or powdercoated Pearl White",
            },
            { name: "Hand Traverse", description: "Self-lubricating acetylic accessories" },
            { name: "Hand Traverse", description: "Hand operated" },
            {
              name: "Hand Traverse",
              description: "The system is suitable for ceiling and wall installation",
            },
            { name: "Hand Traverse", description: "Suitable for S-Fold gliders (hand drawn only)" },

            {
              name: "Cord Operated",
              description: "6060 T6 UNI 8278 extruded aluminium alloy track",
            },
            {
              name: "Cord Operated",
              description: "Width 14mm; Height 23mm - Sliding channel: 6mm",
            },
            {
              name: "Cord Operated",
              description: "Track colours: Anodised Silver; or powdercoated Pearl White ",
            },
            { name: "Cord Operated", description: "Steel pulleys with wheels for cord operating" },
            { name: "Cord Operated", description: "Self-lubricating acetylic accessories" },
            { name: "Cord Operated", description: "Cord operated" },
            {
              name: "Cord Operated",
              description: "The system is suitable for ceiling and wall installation",
            },
            {
              name: "Cord Operated",
              description: "Only straight systems can be installed with cord operating",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_1_Multiplo_412_Manual_Curtain_Track_System_hand_traverse_V1_0816.pdf",
            },
            {
              id: "2",
              name: "Vertilux_9_2_Multiplo_412_Manual_Curtain_Track_System_cord_operated_V1_0816.pdf",
            },
          ],
        },
        {
          id: "2",
          heading: "Separè 458",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          curtainType: ["Healthcare"],
          installation: ["Ceiling mount", "Wall mount"],
          direction: ["Straight", "Curved"],
          descriptionOne:
            "Hand operated and can be used with curtains 800cm width max. and 300cm height, weighing up to 6kg. Separè is also the perfect solution for your Healthcare Curtain Track needs. ",
          descriptionTwo:
            "In addition, OneBlind can be easily integrated with building automation systems including lighting, climate control and audio-visual systems for the ultimate in convenience and energy control. It might look like magic, but it’s actually just a brilliant design.",
          descriptionThree:
            "The system is suitable for ceiling and wall installations and free hanging ones using specific hangers, preferably with exposed track. The system allows tracks curved through 90° or with wide radius centring to be installed. Sliding is guaranteed by selflubricating polyethylene slides or by roller gliders for curtains at the load limit. ",
          descriptionFour:
            "The S.R.D. Safety Release Device mechanism can be fitted to this system, making it the perfect safety device and it does not require a specialist to reset the system once activated.",

          images: [
            {
              name: Separe458b,
              alt: "Separe458",
            },
            {
              name: Separe458c,
              alt: "Separe458",
            },
            {
              name: Separe458d,
              alt: "Separe458",
            },
          ],
          standAloneImg: {
            name: Separe458a,
            alt: "Separe458",
          },

          techSpecs: [
            {
              name: "Hand Traverse",
              description: "6060 T6 UNI 8278 extruded aluminium alloy track",
            },
            { name: "Hand Traverse", description: "Width 14mm; Height 34mm" },
            { name: "Hand Traverse", description: "Sliding channel: 6mm" },
            {
              name: "Hand Traverse",
              description:
                "Minimum radius of curvature: 90° curves: 12cm - wide radius curves: 50cm",
            },
            {
              name: "Hand Traverse",
              description: "Track colours: Anodised Silver; or powdercoated Pearl White",
            },
            { name: "Hand Traverse", description: "Self-lubricating acetylic accessories" },
            {
              name: "Hand Traverse",
              description:
                " Sliding is guaranteed by self-lubricating polyethylene slides or by roller gliders",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_6_Separè_458_Manual_Curtain_Track_System_hand_traverse_V1_0816.pdf",
            },
          ],
        },
        {
          id: "3",
          heading: "Ego 480",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          curtainType: ["Manual"],
          installation: ["Ceiling mount", "Wall mount"],
          direction: ["Curved"],
          descriptionOne:
            "The system has the feature of being double-faced as both sides can be installed to show the flat or curved aesthetic effect.",
          descriptionTwo:
            "For use with curtains 600cm width max. and 300cm height, weighing up to 6kg. Sliding is guaranteed by self-lubricating polyethylene slides or by roller gliders for curtains at the load limits.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: Ego480b,
              alt: "Ego480",
            },
            {
              name: Ego480c,
              alt: "Ego480",
            },
            {
              name: Ego480d,
              alt: "Ego480",
            },
          ],
          standAloneImg: {
            name: Ego480a,
            alt: "Ego480",
          },
          techSpecs: [
            {
              name: "Hand Traverse",
              description: "6060 T6 UNI 8278 extruded aluminium alloy track",
            },
            {
              name: "Hand Traverse",
              description: "Width 22mm - Height 24mm",
            },
            {
              name: "Hand Traverse",
              description: "Sliding channel: 6mm",
            },
            {
              name: "Hand Traverse",
              description:
                " Minimum radius of curvature: 90° curves: 12cm wide radius curves: 50cm",
            },
            {
              name: "Hand Traverse",
              description: "Track colours: Anodised Silver; powdercoated Pearl White or Black",
            },
            {
              name: "Hand Traverse",
              description: "The system is suitable for ceiling and wall installations",
            },
            {
              name: "Hand Traverse",
              description: "Suitable for S-Fold gliders",
            },
          ],
          downloads: [],
        },
        {
          id: "4",
          heading: "Arco 490",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          curtainType: ["Manual"],
          installation: ["Ceiling mount", "Wall mount"],
          direction: ["Curved"],
          descriptionOne:
            "500cm height not weighing more than 12kg. The opening can be central, lateral, multiple or asymmetric.",
          descriptionTwo:
            "The system is suitable for ceiling and wall installations. The running of the operating cords in independent channels allows systems with 90° curves or with wide radius centring to be installed, guaranteeing excellent sliding.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: Arco490a,
              alt: "Arco490",
            },
          ],
          standAloneImg: {
            name: Arco490b,
            alt: "Arco490",
          },
          techSpecs: [
            {
              name: "Hand Traverse",
              description: "6060 T6 UNI 8278 extruded aluminium alloy track",
            },
            {
              name: "Hand Traverse",
              description: "Width 22mm - Height 24mm",
            },
            {
              name: "Hand Traverse",
              description: "Sliding channel: 6mm",
            },
            {
              name: "Hand Traverse",
              description: "Minimum radius of curvature: 90° curves: 12cm wide radius curves: 50cm",
            },
            {
              name: "Hand Traverse",
              description: "Track colours: Anodised Silver; powdercoated Pearl White or Black",
            },
            {
              name: "Hand Traverse",
              description: "The system is suitable for ceiling and wall installations",
            },
            {
              name: "Hand Traverse",
              description: "Suitable for S-Fold gliders",
            },
            {
              name: "Cord Operated",
              description: "6060 T6 UNI 8278 extruded aluminium alloy track",
            },
            {
              name: "Cord Operated",
              description: "Width 22mm - Height 24mm",
            },
            {
              name: "Cord Operated",
              description: "Sliding channel: 6mm",
            },
            {
              name: "Cord Operated",
              description: "Minimum radius of curvature: 90° curves: 12cm wide radius curves: 50cm",
            },
            {
              name: "Cord Operated",
              description: "Track colours: Anodised Silver; powdercoated Pearl White or Black",
            },
            {
              name: "Cord Operated",
              description: "Shockproof thermoplastic pulleys with wheels on steel journals",
            },
            {
              name: "Cord Operated",
              description: "Self-lubricating acetylic overlap with steel rod up to 10cm",
            },
            {
              name: "Cord Operated",
              description: "Self-lubricating acetylic accessories",
            },
            {
              name: "Cord Operated",
              description: "The system is suitable for ceiling and wall installations",
            },
            {
              name: "Cord Operated",
              description:
                "The running of the operating cords in independent channels allows systems with 90° curves or with wide radius centring to be installed, guaranteeing excellent sliding",
            },
            {
              name: "Cord Operated",
              description: "Suitable for S-Fold gliders",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_4 Arco 490 Manual Curtain_Track_System_hand_traverse_V1_0816.pdf",
            },
            {
              id: "2",
              name: "Vertilux_9_5_Arco_490_Manual_Curtain_Track_System_cord_operated_V1_0816.pdf",
            },
          ],
        },
        {
          id: "5",
          heading: "Luna",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          curtainType: ["Manual"],
          installation: ["Ceiling mount"],
          direction: ["Straight"],
          descriptionOne:
            "Luna is our newest curtain track available in 3 colors ( Satin, Black & White ). ",
          descriptionTwo:
            "Specially designed with interconnecting gliders (available in 60mm or 80mm) which are contained within the curtain track to create a seamless ripple effect. It’s hand drawn operation can be set up for central or one way opening for systems up to 6000mm in length.",
          descriptionThree:
            "Please note that this curtain system is a top fix ceiling track with no bends or joins possible.",
          descriptionFour: "",
          images: [
            {
              name: Lunab,
              alt: "Luna",
            },
            {
              name: Lunaa,
              alt: "Luna",
            },
          ],
          standAloneImg: {
            name: Lunaa,
            alt: "Luna",
          },
          techSpecs: [
            {
              name: "Standard Inclusions",
              description: "577 Aluminium Track",
            },
            {
              name: "Standard Inclusions",
              description: "4646 End Plugs",
            },
            {
              name: "Standard Inclusions",
              description: "4263 Gliders",
            },
            {
              name: "Standard Inclusions",
              description: "4483 Ceiling Fixing Keys",
            },
            {
              name: "Parameters",
              description: "Maximum Size = 6000mm",
            },
            {
              name: "Parameters",
              description: "No bends or joins possible",
            },
            {
              name: "Parameters",
              description: "Hand drawn operation.",
            },
            {
              name: "Parameters",
              description: "Central Opening or One Way Opening.",
            },
            {
              name: "Parameters",
              description: "Top fixing ceiling installation only",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "LUNA_Product Data Sheet_Vertilux_08022017.pdf",
            },
          ],
        },
        {
          id: "6",
          heading: "Power 541",
          product: "Vertilux",
          operation: "Motorised",
          details: "VERTILUX SYSTEMS.",
          curtainType: ["Motorised"],
          installation: ["Ceiling mount", "Wall mount"],
          direction: ["Straight"],
          descriptionOne:
            "Power 541 is equipped with Autostart® which allows hand-drawn operating too.",
          descriptionTwo:
            "The actuator consists of a 24 Vdc motor with wide-range switching power supply 90-250 Vac / 24Vdc and electronics that supervise system operation. The sliding speed of the curtain is 12 cm/sec.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: Power541a,
              alt: "Power541",
            },
            {
              name: Power541c,
              alt: "Power541",
            },
          ],
          standAloneImg: {
            name: Power541b,
            alt: "Power541",
          },
          techSpecs: [
            {
              name: "6060 T6 UNI 8278 aluminium alloy track",
              description: "",
            },
            {
              name: "Power 541/1: Width 29.2mm; Height 20.4mm",
              description: "",
            },
            {
              name: "Sliding channel: 6mm - Sliding speed: 12cm/sec",
              description: "",
            },
            {
              name: "24Vdc motor with switching power supply 90-250 Vac / 10W (541/1) or 30W (541/2)",
              description: "",
            },
            {
              name: "Noise Operational: 32 dbi (541/1) or 42dbi (541/2)",
              description: "",
            },
            {
              name: "Minimum radius of curvature (1-channel track only): 90° curves: 30cm - wide radius curves: 50cm",
              description: "",
            },
            {
              name: "Track colours: Anodised Silver; powdercoated Pearl White or Black",
              description: "",
            },
            {
              name: "Shockproof thermoplastic pulleys - Self-lubricating acetylic accessories",
              description: "",
            },
            {
              name: "Power 541 is equipped with Autostart® (the motor starts to operate by just pulling the curtain)",
              description: "",
            },
            {
              name: "Automatic Limit Stops (setting is not needed)",
              description: "",
            },
            {
              name: "Manual override, perfect if power is lost",
              description: "",
            },
            {
              name: "Built in receiver (remote control available for one, four or 100 channels)",
              description: "",
            },
            {
              name: "Suitable for S Fold gliders/runners",
              description: "",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_7_Power_541_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "7",
          heading: "Power 571",
          product: "Vertilux",
          operation: "Motorised",
          details: "VERTILUX SYSTEMS.",
          curtainType: ["Motorised"],
          installation: ["Ceiling mount", "Wall mount"],
          direction: ["Curved"],
          descriptionOne: "Power 571 can move up to 50kg of curtaining. ",
          descriptionTwo:
            "The actuator consists of a 24 Vdc motor with wide-range switching power supply 90-250 Vac / 24Vdc and electronics that supervise system operation. The sliding speed of the curtain is 25cm/sec. Power is transmitted by a polyurethane toothed belt. Maximum guaranteed dimensions: length 15 metres. Side, symmetrical or asymmetrical centre openings, 90° curves with 35cm radius, or wide radius arches are possible. ",
          descriptionThree:
            "The use of sliders on ball bearings ensures good operation even near the weight limit. The system electronics have been developed to allow use of the Power 571 system in wireless digital networks or by hardwire with bidirectional exchange of data between actuator and control device.",
          descriptionFour: "",
          images: [
            {
              name: Power571d,
              alt: "Power571",
            },
            {
              name: Power571c,
              alt: "Power571",
            },
            {
              name: Power571b,
              alt: "Power571",
            },
            {
              name: Power571a,
              alt: "Power571",
            },
          ],
          standAloneImg: {
            name: Power571e,
            alt: "Power571",
          },
          techSpecs: [
            {
              name: "6060 T6 UNI 8278 aluminium alloy profile",
              description: "",
            },
            {
              name: "L 32mm; H 29.7mm",
              description: "",
            },
            {
              name: "Sliding track: 6mm - Sliding speed: 25cm/sec",
              description: "",
            },
            {
              name: "24Vdc motor with switching power supply 90-250 Vac / 55W",
              description: "",
            },
            {
              name: "Minimum radius of curvature (1-channel track only): 90° curves: 30cm - wide radius curves: 50cm",
              description: "",
            },
            {
              name: "Power transmission by polyurethane toothed belt with steel strands and polyamide coating to reduce friction and wear",
              description: "",
            },
            {
              name: "Track colours: Anodised Silver; powdercoated Pearl White or Black",
              description: "",
            },
            {
              name: "Pulleys made of thermoplastic material with toothed pulley on ball bearings",
              description: "",
            },
            {
              name: "Automatic Limit Stop (setting is not needed)",
              description: "",
            },
            {
              name: "Built in receiver (remote control available for one, four or 100 channels)",
              description: "",
            },
            {
              name: "Suitable for S Fold gliders/runners",
              description: "",
            },
            {
              name: "230v mechanical motor available on request",
              description: "",
            },
            {
              name: "Motor can be installed at the top of the track",
              description: "",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "8",
          heading: "Aluproff 1100",
          product: "1100 / 1121",
          operation: "Manual",
          details: "ALUPROFF",
          curtainType: ["Manual"],
          installation: ["Ceiling mount", "Wall mount"],
          direction: ["Curved"],
          descriptionOne:
            "AP 1100 is the minimum sized curtain track for a heavy duty project. The smooth curves and strong ”wings” makes the AP 1100 profile ideal for professional mounting purposes. The 1100 profile also has the option of adding our innovative LED lights. The 1121 profile comes with holes, giving the option of fitting it directly into the ceiling.",

          images: [
            {
              name: CurtainSystemOne,
              alt: "1100 or 1121",
            },
          ],
          standAloneImg: {
            name: CurtainSystemOne,
            alt: "1100 or 1121",
          },
          techSpecs: [
            {
              name: "Product",
              description: "1100 or 1121 Hand Drawn Profile",
            },
            {
              name: "System Type",
              description: "Curtain Track",
            },
            {
              name: "Mounting",
              description: "Hidden ceiling bracket and wall brackets.",
            },
            {
              name: "Material",
              description: "Aluminium alloy 6060/T6",
            },
            {
              name: "Powdercoating",
              description: "100 - 130 my",
            },
            {
              name: "Track width",
              description: "6,0 mm",
            },
            {
              name: "Ext. dimens",
              description: "Height 13,75, top width 19,45, bottom width 16,20",
            },
            {
              name: "Length:",
              description: "6000 mm",
            },
            {
              name: "Thickness:",
              description: "1,0 -1,2 mm",
            },
            {
              name: "Max. load:",
              description: "4.5 - 15 kg",
            },
            {
              name: "Bending:",
              description: "Min. radius 10 (recommended)",
            },
            {
              name: "Milling:",
              description: "Yes",
            },
            {
              name: "Webster:",
              description: "No B minimum 11",
            },
            {
              name: "Colours: ",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "9",
          heading: "Aluproff 1500",
          product: "1500",
          operation: "Manual",
          details: "ALUPROFF",
          curtainType: ["Manual"],
          installation: ["Ceiling mount"],
          direction: ["Curved"],
          descriptionOne:
            "Built-in rail to fit into ceiling recess. Gives a modern, ex travagant look with floor-to ceiling fabric. Delivered in lengths of 6 meter. AP 1500 can be bent and curved.",

          images: [
            {
              name: CurtainSystemTwo,
              alt: "1500",
            },
          ],
          standAloneImg: {
            name: CurtainSystemTwo,
            alt: "1500",
          },
          techSpecs: [
            {
              name: "Product",
              description: "1500 Built-In Hand Drawn Profile",
            },
            {
              name: "System Type",
              description: "Curtain Rail",
            },
            {
              name: "Mounting",
              description:
                "Curtain rail for installation in ceiling or panel. Milling directly into the ceiling.",
            },
            {
              name: "Material",
              description: "Aluminium alloy 6060/T6",
            },
            {
              name: "Powdercoating",
              description: "100 - 130 my",
            },
            {
              name: "Track width",
              description: "6,0 mm",
            },
            {
              name: "Ext. dimens",
              description: "20 mm x 13 mm",
            },
            {
              name: "Length:",
              description: "6000 mm",
            },
            {
              name: "Max. load:",
              description: "4.5 - 15 kg",
            },
            {
              name: "Bending:",
              description: "Min. radius 16 (recommended)",
            },
            {
              name: "Webster:",
              description: "No B minimum 11",
            },
            {
              name: "Recommneded Glider:",
              description: "5721, 5722, 5738, 5734",
            },
            {
              name: "Recommneded Wave Glider:",
              description: "5735, 5730, 14058, 6025",
            },

            {
              name: "Colours: ",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "10",
          heading: "Aluproff 1600",
          product: "1600",
          operation: "Manual",
          details: "ALUPROFF",
          curtainType: ["Manual"],
          installation: ["Ceiling mount"],
          direction: ["Curved"],
          descriptionOne:
            "AP 1600 is a cord operated track for both the domestic and contract market. It has smooth, elegant curves and strong wings. Bending Radius 11.",

          images: [
            {
              name: CurtainSystemThree,
              alt: "1600",
            },
          ],
          standAloneImg: {
            name: CurtainSystemThree,
            alt: "1600",
          },
          techSpecs: [
            {
              name: "Product",
              description: "1600 Cord Drawn Profile",
            },
            {
              name: "System Type",
              description: "Curtain Track",
            },
            {
              name: "Mounting",
              description: "Click bracket",
            },
            {
              name: "Material",
              description: "Aluminium alloy 6060/T6",
            },
            {
              name: "Powdercoating",
              description: "100 - 130 my",
            },
            {
              name: "Accessories",
              description: "Plastic/Metal",
            },
            {
              name: "Track width",
              description: "6,0 mm",
            },
            {
              name: "Ext. dimens",
              description: "20 mm x 14 mm",
            },
            {
              name: "Length:",
              description: "6000 mm",
            },
            {
              name: "Max. load:",
              description: "4.5 - 15 kg",
            },
            {
              name: "Bending:",
              description: "Min. radius 11 (recommended)",
            },
            {
              name: "Webster:",
              description: "No B minimum 11",
            },
            {
              name: "Recommneded Glider:",
              description: "5738, 5722, 1050223, 1050224, 14058",
            },
            {
              name: "Colours: ",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "11",
          heading: "Aluproff 1905 Piet Hein",
          product: "1905",
          operation: "Manual",
          details: "ALUPROFF",
          curtainType: ["Manual"],
          installation: ["Ceiling mount"],
          direction: ["Curved"],
          descriptionOne:
            "AP 1905 is the world's first Super-ellipse shaped curtain track and the first to come with a groove specifically designed for LED. Created by Aluproff in collaboration with heritage brand Piet Hein, it continues the legacy of the famous Danish designer, architect, mathematician and poet Piet Hein. With perfect functionality and a plethora of specially designed accessories, like aluminum endcaps and unique roller gliders. Available in 5 colours.",

          images: [
            {
              name: CurtainSystemFour,
              alt: "1905",
            },
          ],
          standAloneImg: {
            name: CurtainSystemFour,
            alt: "1905",
          },
          techSpecs: [
            {
              name: "Product",
              description: "1905 Piet Hein Superellipse Track",
            },
            {
              name: "System Type",
              description: "Curtain Track",
            },
            {
              name: "Mounting",
              description: "Ceiling brackets, wall brackets and wall to wall",
            },
            {
              name: "Material",
              description: "Aluminium alloy 6060/T6",
            },
            {
              name: "Anodizing",
              description: "(Brushed Steel): 10 my",
            },
            {
              name: "Powdercoating",
              description: "100 - 130 my",
            },
            {
              name: "Accessories",
              description: "Plastic/aluminum, colour coordinated, laquered metal",
            },
            {
              name: "Track width",
              description: "6,3 mm",
            },
            {
              name: "Ext. dimens",
              description: "44 x 29 mm",
            },
            {
              name: "Length:",
              description: "6000 mm, Brushed Steel 4000",
            },
            {
              name: "Thickness:",
              description: "1,0 -1,2 mm",
            },
            {
              name: "Max. load:",
              description: "10 - 25 kg",
            },
            {
              name: "Bending:",
              description: "No",
            },
            {
              name: "Milling:",
              description: "No",
            },
            {
              name: "Webster:",
              description: "No B minimum 11",
            },
            {
              name: "Gliders:",
              description:
                "Steel ball bearing roller gliders with rubber wheels, wave roller gliders 11",
            },
            {
              name: "Overlength:",
              description: "Connected by 3 part connector",
            },
            {
              name: "LED",
              description: "Individual special track. 3000 kelvin light, warm white.",
            },
            {
              name: "Colours: ",
              description: "Snow, Midnight, Stone, Brushed Steel, Sand",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "12",
          heading: "Aluproff 5001",
          product: "5001",
          operation: "Manual",
          details: "ALUPROFF",
          curtainType: ["Manual"],
          installation: ["Ceiling mount"],
          direction: ["Curved"],
          descriptionOne:
            "The AP 5001 (6 mm) cubicle curtain track was developed for technical curtain solutions in hospitals and health care facilities. Whether for light weight or heavy weight curtain solutions the cubicle track has a wide variety of flexible options. 5001 is popular as roomdivider, for ceiling curtains and for bathrooms as well. Works perfectly with our new 6036 wave roller glider.",

          images: [
            {
              name: CurtainSystemFour,
              alt: "1905",
            },
          ],
          standAloneImg: {
            name: CurtainSystemFour,
            alt: "1905",
          },
          techSpecs: [
            {
              name: "Product",
              description: "1905 Piet Hein Superellipse Track",
            },
            {
              name: "System Type",
              description: "Curtain Rail",
            },
            {
              name: "Mounting",
              description: "Supports and ceiling/ wall brackets. Special for hospitals",
            },
            {
              name: "Material",
              description: "Aluminium alloy 6060/T6",
            },
            {
              name: "Powdercoating",
              description: "100 - 130 my",
            },
            {
              name: "Accessories",
              description: "Plastic/metal",
            },
            {
              name: "Track width",
              description: "6,0 mm",
            },
            {
              name: "Ext. dimens",
              description: "15 x 33 mm",
            },
            {
              name: "Length",
              description: "6000 mm",
            },
            {
              name: "Thickness",
              description: "1,0 -1,2 mm",
            },
            {
              name: "Max. load",
              description: "10 - 22 kg",
            },
            {
              name: "Bending",
              description: "Min. Radius 10 (recommended)",
            },
            {
              name: "Milling",
              description: "According to Specs",
            },
            {
              name: "Webster",
              description: "No B minimum 11",
            },
            {
              name: "Recomened Gliders:",
              description:
                "5730, 5734, 5735, 5721, 5722, 5738, 5739K, 6025 and wave roller glider 6036",
            },
            {
              name: "Colours",
              description: "White, Super Silver, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "13",
          heading: "Aluproff 9001",
          product: "9001",
          operation: "Manual",
          details: "ALUPROFF",
          curtainType: ["Manual"],
          installation: ["Ceiling mount"],
          direction: ["Curved"],
          descriptionOne:
            "The AP 9001 (6 mm) is a unique curtain track system for both the professional and residential market and comes in various fashionable colours. Mix and match your favourite finials and colours and create your own deco pole.",
          images: [
            {
              name: CurtainSystemFive,
              alt: "9001",
            },
          ],
          standAloneImg: {
            name: CurtainSystemFive,
            alt: "9001",
          },
          techSpecs: [
            {
              name: "Product",
              description: "9001 Metal Fusion",
            },
            {
              name: "System Type",
              description: "Curtain Track",
            },
            {
              name: "Mounting",
              description: "Bracket for ceiling",
            },
            {
              name: "Material",
              description: "Aluminium alloy 6060/T6",
            },
            {
              name: "Powdercoating",
              description: "100 - 130 my",
            },
            {
              name: "Accessories",
              description: "Plastic/Metal",
            },
            {
              name: "Track width",
              description: "6,0 mm",
            },
            {
              name: "Ext. dimens",
              description: "26 x 28 mm",
            },
            {
              name: "Length",
              description: "6000 mm / Gold 4000 mm",
            },
            {
              name: "Thickness",
              description: "1,0 -1,2 mm",
            },
            {
              name: "Max. load",
              description: "6 - 22 kg",
            },
            {
              name: "Bending",
              description: "Min. Radius 32 (recommended)",
            },
            {
              name: "Milling",
              description: "Yes",
            },
            {
              name: "Webster",
              description: "No B minimum 11",
            },
            {
              name: "Recomened Gliders:",
              description: "1307, 5734, 5735, 5730, 5738, 5722, 5721, 6036",
            },
            {
              name: "Colours",
              description: "White, Super Silver, Black, Gold (Anodized), Antique (Anodized)",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "14",
          heading: "Glydea Ultra Wired Track",
          product: "1781379",
          operation: "Motorised",
          details: "ALUPROFF",
          curtainType: ["Motorised"],
          installation: ["Ceiling mount"],
          direction: ["Curved"],
          descriptionOne:
            "The Glydea® system is an industry-leading motorised curtain track system produced by Somfy and developed over many years of experience and expertise. The Glydea® Ultra motor offers a superior wired solution with a wide array of user-friendly features. The motor has a weight capacity of up to 60kg, and is operated through either 1 to 16 channel remote controls or by switch. As an optional extra, we also offer a remote controlled ambience LED clip-on track for the system.",
          descriptionTwo:
            "The Glydea® system with RTS motor is smarthome compatible through the Somfy TaHoma® hub, which links to popular voice assistants such as Google Home and Amazon Alexa.",
          images: [
            {
              name: CurtainSystemFive,
              alt: "9001",
            },
          ],
          standAloneImg: {
            name: CurtainSystemFive,
            alt: "9001",
          },
          techSpecs: [
            {
              name: "Operation",
              description: "Remote Controlled",
            },
            {
              name: "Mounting",
              description: "Twist Bracket for Ceiling",
            },
            {
              name: "Material",
              description: "Aluminium alloy 6060/T6",
            },
            {
              name: "Powdercoating",
              description: "100 - 130 my",
            },
            {
              name: "Accessories",
              description: "Plastic/Metal",
            },
            {
              name: "Track width",
              description: "6,0 mm",
            },
            {
              name: "Ext. dimens",
              description: "30.6 x 25 mm",
            },
            {
              name: "Length",
              description: "6000 mm",
            },
            {
              name: "Thickness",
              description: "1,2 -1,6 mm",
            },
            {
              name: "Bending",
              description: "Min. radius 30 (recommended)",
            },
            {
              name: "Milling",
              description: "According to specs",
            },
            {
              name: "Recomended gliders",
              description: "8626, 8021",
            },
            {
              name: "Colours",
              description: "Black, White",
            },
            {
              name: "Power Supply",
              description: "100 - 240V ~ 50/60 HZ",
            },
            {
              name: "Max. Load",
              description: "60kg",
            },
            {
              name: "Sound Level",
              description: "38 dBA in silent mode",
            },
            {
              name: "Linear Speed",
              description: "12.45 cm/s - 19.96 cm/s (7.49 cm/s in silent mode)",
            },
            {
              name: "Rated Torque",
              description: "11.0 nm",
            },
            {
              name: "Wireless protocol",
              description: "Somfy RTS (Radio Technology)",
            },
            {
              name: "Wired options",
              description: "DCT (Dry Contact) / WT (Wired)",
            },
            {
              name: "Power usage",
              description: "60W",
            },
            {
              name: "Motor Colour",
              description: "Silver",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "15",
          heading: "Movelite Wired Track",
          product: "1781379",
          operation: "Motorised",
          details: "ALUPROFF",
          curtainType: ["Motorised"],
          installation: ["Ceiling mount"],
          direction: ["Curved"],
          descriptionOne:
            "The Glydea® system is an industry-leading motorised curtain track system produced by Somfy and developed over many years of experience and expertise. The wired Movelite offers a cost-friendly motorised solution without need to recharge a battery. The motor has a weight capacity of up to 35kg, and is operated through either 1 to 16 channel remote controls or by switch. As an optional extra, we also offer a remote controlled ambience LED clip-on track for the system.",
          descriptionTwo:
            "The Glydea® system with RTS motor is smarthome compatible through the Somfy TaHoma® hub, which links to popular voice assistants such as Google Home and Amazon Alexa.",
          images: [
            {
              name: CurtainSystemFive,
              alt: "9001",
            },
          ],
          standAloneImg: {
            name: CurtainSystemFive,
            alt: "9001",
          },
          techSpecs: [
            {
              name: "Operation",
              description: "Remote Controlled",
            },
            {
              name: "Mounting",
              description: "Twist Bracket for Ceiling",
            },
            {
              name: "Material",
              description: "Aluminium alloy 6060/T6",
            },
            {
              name: "Powdercoating",
              description: "100 - 130 my",
            },
            {
              name: "Accessories",
              description: "Plastic/Metal",
            },
            {
              name: "Track width",
              description: "6,0 mm",
            },
            {
              name: "Ext. dimens",
              description: "30.6 x 25 mm",
            },
            {
              name: "Length",
              description: "6000 mm",
            },
            {
              name: "Thickness",
              description: "1,2 -1,6 mm",
            },
            {
              name: "Bending",
              description: "Min. radius 30 (recommended)",
            },
            {
              name: "Milling",
              description: "According to specs",
            },
            {
              name: "Recomended gliders",
              description: "8626, 8021",
            },
            {
              name: "Recomended wave gliders",
              description: "8625, 8627, 8021W",
            },
            {
              name: "Colours",
              description: "Black, White",
            },
            {
              name: "Power Supply",
              description: "100 - 240V ~ 50/60 HZ",
            },
            {
              name: "Max. Load",
              description: "35kg",
            },
            {
              name: "Sound Level",
              description: "40.6 dB(A)",
            },
            {
              name: "Linear Speed",
              description: "15 cm/s",
            },
            {
              name: "Rated Torque",
              description: "0.6 nm",
            },
            {
              name: "Wireless protocol",
              description: "Somfy RTS (Radio Technology)",
            },
            {
              name: "Wired options",
              description: "DCT (Dry Contact) / WT (Wired)",
            },
            {
              name: "Motor Colour",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "16",
          heading: "Movelite Wirefree Track",
          product: "1781379",
          operation: "Motorised",
          details: "ALUPROFF",
          curtainType: ["Motorised"],
          installation: ["Ceiling mount"],
          direction: ["Curved"],
          descriptionOne:
            "The Glydea® system is an industry-leading motorised curtain track system produced by Somfy and developed over many years of experience and expertise. The Movelite battery offers a truly wireless solution without the need for any wiring. The motor has a weight capacity of up to 35kg, and is operated through either 1 to 16 channel remote controls or by switch. As an optional extra, we also offer a remote controlled ambience LED clip-on track for the system.",
          descriptionTwo:
            "The Glydea® system with RTS motor is smarthome compatible through the Somfy TaHoma® hub, which links to popular voice assistants such as Google Home and Amazon Alexa.",
          images: [
            {
              name: CurtainSystemFive,
              alt: "9001",
            },
          ],
          standAloneImg: {
            name: CurtainSystemFive,
            alt: "9001",
          },
          techSpecs: [
            {
              name: "Operation",
              description: "Remote Controlled",
            },
            {
              name: "Mounting",
              description: "Twist Bracket for Ceiling",
            },
            {
              name: "Material",
              description: "Aluminium alloy 6060/T6",
            },
            {
              name: "Powdercoating",
              description: "100 - 130 my",
            },
            {
              name: "Accessories",
              description: "Plastic/Metal",
            },
            {
              name: "Track width",
              description: "6,0 mm",
            },
            {
              name: "Ext. dimens",
              description: "30.6 x 25 mm",
            },
            {
              name: "Length",
              description: "6000 mm",
            },
            {
              name: "Thickness",
              description: "1,2 -1,6 mm",
            },
            {
              name: "Bending",
              description: "Min. radius 30 (recommended)",
            },
            {
              name: "Milling",
              description: "According to specs",
            },
            {
              name: "Recomended gliders",
              description: "8626, 8021",
            },
            {
              name: "Recomended wave gliders",
              description: "8625, 8627, 8021W",
            },
            {
              name: "Colours",
              description: "Black, White",
            },
            {
              name: "Power Supply",
              description: "14.8V/2000 mAh",
            },
            {
              name: "Max. Load",
              description: "35kg",
            },
            {
              name: "Sound Level",
              description: "40.6 dB(A)",
            },
            {
              name: "Linear Speed",
              description: "15 cm/s",
            },
            {
              name: "Rated Torque",
              description: "0.6 nm",
            },
            {
              name: "Wireless protocol",
              description: "Somfy RTS (Radio Technology)",
            },
            {
              name: "Charger",
              description: "100-240V AC ~ 50/60 HZ",
            },
            {
              name: "Charging Time",
              description: "< 9 hours",
            },
            {
              name: "Motor Colour",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Delos",
          operation: "Fabric",
          details: "Delos",
          descriptionOne:
            "Our Delos Curtain Collection is the latest Linen Polyester fabric that is as durable as \r\nit is luxurious. Its open weave gives it a lovely texture which drapes well without \r\nthe creasing of pure linen. \r\nThis cohesive colour palette is wonderfully adaptable and provides a touch of \r\ncontemporary appeal to any room",
          images: [
            {
              name: DelosMAIN,
              alt: "Delos",
            },
          ],
          colorImages: [],
          downloads: [
            {
              id: "1",
              name: "2018 Vertilux Delos Curtain Fabric Tech Data Sheet.pdf",
            },
          ],
        },
        {
          id: "2",
          heading: "Etoile Sheer",
          operation: "Fabric",
          details: "EtoileSheer",
          descriptionOne:
            "Our Sheer Etoile Collection has an architectonic rib with a subtle gloss that \r\nincludes 8 must-have colours. This 100% polyester range also has an extra \r\nwide width making it perfect floor-to-ceiling fabric for draping and event \r\ndecorations.",
          images: [
            {
              name: EtoileSheerMAIN,
              alt: "Etoile Sheer",
            },
          ],
          colorImages: [],
          downloads: [
            {
              id: "1",
              name: "2018 Vertilux Etoile Curtain Fabric Tech Data Sheet.pdf",
            },
          ],
        },
        {
          id: "3",
          heading: "Excelsior",
          operation: "Fabric",
          details: "Excelsior",
          descriptionOne:
            "The Mottura by Vertilux Excelsior Light Filtering fabric range is suitable for use within the hospitality sector. It provides a tonal linen look fabric with a \r\nslight sheen in a light fi ltering opacity for curtaining. Available in large colour palette of 33 colours, it coordinates with other Mottura by Vertilux fabrics and \r\nVertilux Blind fabrics.",
          images: [
            {
              name: ExcelsiorMAIN,
              alt: "Excelsior",
            },
          ],
          colorImages: [],
          downloads: [
            {
              id: "1",
              name: "Mottura_Excelsior Fabric Tech Data_V1_0316.pdf",
            },
          ],
        },
        {
          id: "4",
          heading: "Lustre Sheer",
          operation: "Fabric",
          details: "Lustre Sheer",
          descriptionOne:
            "Our Lustre Sheer Collection comprises 12 textured fabrics available in a \r\nmodern palette, offering superb window furnishing properties and easy \r\nmaintenance. ",
          images: [
            {
              name: LustreSheerMAIN,
              alt: "Lustre Sheer",
            },
          ],
          colorImages: [
            {
              name: LustreSheerCHAMPAGNE,
              alt: "Blind Fabric Colour CHAMPAGNE",
            },
            {
              name: LustreSheerGLACIER,
              alt: "Blind Fabric Colour GLACIER",
            },
            {
              name: LustreSheerIVORY,
              alt: "Blind Fabric Colour IVORY",
            },
            {
              name: LustreSheerLIMESTONE,
              alt: "Blind Fabric Colour LIMESTONE",
            },
            {
              name: LustreSheerMINERALASH,
              alt: "Blind Fabric Colour MINERALASH",
            },
            {
              name: LustreSheerMOONSHINE,
              alt: "Blind Fabric Colour MOONSHINE",
            },
            {
              name: LustreSheerONYX,
              alt: "Blind Fabric Colour ONYX",
            },
            {
              name: LustreSheerPEARL,
              alt: "Blind Fabric Colour PEARL",
            },
            {
              name: LustreSheerRUSTIQUE,
              alt: "Blind Fabric Colour RUSTIQUE",
            },
            {
              name: LustreSheerSMOKYQUARTZ,
              alt: "Blind Fabric Colour SMOKYQUARTZ",
            },
            {
              name: LustreSheerSNOWFLAKE,
              alt: "Blind Fabric Colour SNOWFLAKE",
            },
            {
              name: LustreSheerTITANIUM,
              alt: "Blind Fabric Colour TITANIUM",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "2018 Vertilux Lustre Sheer Curtain Fabric Tech Data Sheet.pdf",
            },
          ],
        },
        {
          id: "5",
          heading: "Métallique®",
          operation: "Fabric",
          details: "Métallique®",
          descriptionOne:
            "Our Métallique® Curtain Collection is a drapery version of the ever popular \r\nVertilux Euroscreen® Metallised fabric. \r\nMétallique® is a transparent fabric with a metallised back that is designed \r\nto exceed the stringent standards in light, glare reduction and insulation \r\nfrom harmful UV rays. With superior heat and reflection properties \r\nit assists in maintaining an energy efficient environment. Giving you \r\nprotection without blocking your view.",
          images: [
            {
              name: MetalliqueMAIN,
              alt: "Metallique",
            },
          ],
          colorImages: [
            {
              name: MetalliqueAZTECSILVER,
              alt: "Blind Fabric Colour AZTECSILVER",
            },
            {
              name: MetalliqueGALAXY,
              alt: "Blind Fabric Colour GALAXY",
            },
            {
              name: MetalliqueMAYANGOLD,
              alt: "Blind Fabric Colour MAYANGOLD",
            },
            {
              name: MetalliqueSTARDUST,
              alt: "Blind Fabric Colour STARDUST",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "2018 Vertilux Metallique Material Tech Data Sheet.pdf",
            },
          ],
        },
        {
          id: "6",
          heading: "Regio FR",
          operation: "Fabric",
          details: "Regio FR",
          descriptionOne:
            "Our Regio FR Collection is a polyester velvet fabric that is a seductively \r\nlustrous material with a feather-like softness and gorgeous sheen. It \r\noffers outstanding durability, insulation and wrinkle resistance. ",
          images: [
            {
              name: RegioFRMAIN,
              alt: "Regio FR",
            },
          ],
          colorImages: [],
          downloads: [
            {
              id: "1",
              name: "2019 Vertilux Regio FR Curtain Fabric Tech Data Sheet V1 0719.pdf",
            },
          ],
        },
        {
          id: "7",
          heading: "Savannah",
          operation: "Fabric",
          details: "Savannah",
          descriptionOne:
            "Our NEW Savannah Collection is 100% natural linen in a pale speckled \r\npalette that will work in a variety of settings. The clean, crisp colours, \r\nan occasional slub yarn for texture and the unexpected soft hand make \r\nthis a desirable linen. ",
          images: [
            {
              name: SavannahMAIN,
              alt: "Savannah",
            },
          ],
          colorImages: [],
          downloads: [
            {
              id: "1",
              name: "2018 Vertilux Savannah Curtain Fabric Tech Data Sheet.pdf",
            },
          ],
        },
        {
          id: "8",
          heading: "Euronite FR",
          operation: "Fabric",
          details: "Euronite FR",
          descriptionOne:
            "Our Euronite FR Curtain Collection is the latest FR Polyester fabric \r\nthat is as durable as it is luxurious. It is our first 100% blockout fabric \r\ndesigned without a lining yet still maintains superior performance.",
          images: [
            {
              name: EuroniteFRMAIN,
              alt: "Euronite FR",
            },
          ],
          colorImages: [
            {
              name: EuroniteFR1123_01A,
              alt: "Blind Fabric Colour 1123_01A",
            },
            {
              name: EuroniteFR1123_01B,
              alt: "Blind Fabric Colour 1123_01B",
            },
            {
              name: EuroniteFR1123_02A,
              alt: "Blind Fabric Colour 1123_02A",
            },
            {
              name: EuroniteFR1123_02B,
              alt: "Blind Fabric Colour 1123_02B",
            },
            {
              name: EuroniteFR1123_03A,
              alt: "Blind Fabric Colour 1123_03A",
            },
            {
              name: EuroniteFR1123_03B,
              alt: "Blind Fabric Colour 1123_03B",
            },
            {
              name: EuroniteFR1123_04A,
              alt: "Blind Fabric Colour 1123_04A",
            },
            {
              name: EuroniteFR1123_04B,
              alt: "Blind Fabric Colour 1123_04B",
            },
            {
              name: EuroniteFR1123_05A,
              alt: "Blind Fabric Colour 1123_05A",
            },
            {
              name: EuroniteFR1123_05B,
              alt: "Blind Fabric Colour 1123_05B",
            },
            {
              name: EuroniteFR1123_06A,
              alt: "Blind Fabric Colour 1123_06A",
            },
            {
              name: EuroniteFR1123_06B,
              alt: "Blind Fabric Colour 1123_06B",
            },
            {
              name: EuroniteFR1123_07A,
              alt: "Blind Fabric Colour 1123_07A",
            },
            {
              name: EuroniteFR1123_07B,
              alt: "Blind Fabric Colour 1123_07B",
            },
            {
              name: EuroniteFR1123_08A,
              alt: "Blind Fabric Colour 1123_08A",
            },
            {
              name: EuroniteFR1123_08B,
              alt: "Blind Fabric Colour 1123_08B",
            },
            {
              name: EuroniteFR1123_09A,
              alt: "Blind Fabric Colour 1123_09A",
            },
            {
              name: EuroniteFR1123_09B,
              alt: "Blind Fabric Colour 1123_09B",
            },
            {
              name: EuroniteFR1123_10A,
              alt: "Blind Fabric Colour 1123_10A",
            },
            {
              name: EuroniteFR1123_10B,
              alt: "Blind Fabric Colour 1123_10B",
            },
            {
              name: EuroniteFR1123_11A,
              alt: "Blind Fabric Colour 1123_11A",
            },
            {
              name: EuroniteFR1123_11B,
              alt: "Blind Fabric Colour 1123_11B",
            },
            {
              name: EuroniteFR1123_12A,
              alt: "Blind Fabric Colour 1123_12A",
            },
            {
              name: EuroniteFR1123_12B,
              alt: "Blind Fabric Colour 1123_12B",
            },
            {
              name: EuroniteFR1123_13A,
              alt: "Blind Fabric Colour 1123_13A",
            },
            {
              name: EuroniteFR1123_13B,
              alt: "Blind Fabric Colour 1123_13B",
            },
            {
              name: EuroniteFR1123_14A,
              alt: "Blind Fabric Colour 1123_14A",
            },
            {
              name: EuroniteFR1123_14B,
              alt: "Blind Fabric Colour 1123_14B",
            },
            {
              name: EuroniteFR1123_15A,
              alt: "Blind Fabric Colour 1123_15A",
            },
            {
              name: EuroniteFR1123_15B,
              alt: "Blind Fabric Colour 1123_15B",
            },
            {
              name: EuroniteFR1123_16A,
              alt: "Blind Fabric Colour 1123_16A",
            },
            {
              name: EuroniteFR1123_16B,
              alt: "Blind Fabric Colour 1123_16B",
            },
            {
              name: EuroniteFR1123_17A,
              alt: "Blind Fabric Colour 1123_17A",
            },
            {
              name: EuroniteFR1123_17B,
              alt: "Blind Fabric Colour 1123_17B",
            },
            {
              name: EuroniteFR1123_18A,
              alt: "Blind Fabric Colour 1123_18A",
            },
            {
              name: EuroniteFR1123_18B,
              alt: "Blind Fabric Colour 1123_18B",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "2019 Vertilux Euronite FR Curtain Fabric Tech Data Sheet.pdf",
            },
          ],
        },
        {
          id: "9",
          heading: "Mottura Master 1",
          operation: "Fabric",
          details: "Mottura Master 1",
          descriptionOne:
            "The 100% Trevira CS fabric is lightweight and measures 135 gr/m2. With a soft lustre and plain weave, it comes in 35 colors and is 340cm wide. This PVC-free fabric is easily cleaned and remains durable, maintaining its shape even with frequent washing. It can be machine washed up to 30°C. Additionally, it meets fire resistance standards such as FR: AS1530.2 + AS 1530.3 and Italian UNI 9177, 9174, and 8456 Class 1.",
          images: [
            {
              name: MotturaMasterOneMAIN,
              alt: "Mottura Master 1",
            },
          ],
          colorImages: [
            {
              name: MotturaMasterOne3102,
              alt: "Blind Fabric Colour 3102",
            },
            {
              name: MotturaMasterOne3103,
              alt: "Blind Fabric Colour 3103",
            },
            {
              name: MotturaMasterOne3104,
              alt: "Blind Fabric Colour 3104",
            },
            {
              name: MotturaMasterOne3105,
              alt: "Blind Fabric Colour 3105",
            },
            {
              name: MotturaMasterOne3106,
              alt: "Blind Fabric Colour 3106",
            },
            {
              name: MotturaMasterOne3107,
              alt: "Blind Fabric Colour 3107",
            },
            {
              name: MotturaMasterOne3108,
              alt: "Blind Fabric Colour 3108",
            },
            {
              name: MotturaMasterOne3109,
              alt: "Blind Fabric Colour 3109",
            },
            {
              name: MotturaMasterOne3110,
              alt: "Blind Fabric Colour 3110",
            },
            {
              name: MotturaMasterOne3111,
              alt: "Blind Fabric Colour 3111",
            },
            {
              name: MotturaMasterOne3113,
              alt: "Blind Fabric Colour 3113",
            },
            {
              name: MotturaMasterOne3123,
              alt: "Blind Fabric Colour 3123",
            },
            {
              name: MotturaMasterOne3124,
              alt: "Blind Fabric Colour 3124",
            },
            {
              name: MotturaMasterOne3125,
              alt: "Blind Fabric Colour 3125",
            },
            {
              name: MotturaMasterOne3127,
              alt: "Blind Fabric Colour 3127",
            },
            {
              name: MotturaMasterOne3138,
              alt: "Blind Fabric Colour 3138",
            },
            {
              name: MotturaMasterOne3141,
              alt: "Blind Fabric Colour 3141",
            },
            {
              name: MotturaMasterOne3143,
              alt: "Blind Fabric Colour 3143",
            },
            {
              name: MotturaMasterOne3146,
              alt: "Blind Fabric Colour 3146",
            },
            {
              name: MotturaMasterOne3151,
              alt: "Blind Fabric Colour 3151",
            },
            {
              name: MotturaMasterOne3155,
              alt: "Blind Fabric Colour 3155",
            },
            {
              name: MotturaMasterOne3156,
              alt: "Blind Fabric Colour 3156",
            },
            {
              name: MotturaMasterOne3157,
              alt: "Blind Fabric Colour 3157",
            },
            {
              name: MotturaMasterOne3159,
              alt: "Blind Fabric Colour 3159",
            },
            {
              name: MotturaMasterOne3161,
              alt: "Blind Fabric Colour 3161",
            },
            {
              name: MotturaMasterOne3164,
              alt: "Blind Fabric Colour 3164",
            },
            {
              name: MotturaMasterOne3165,
              alt: "Blind Fabric Colour 3165",
            },
            {
              name: MotturaMasterOne3166,
              alt: "Blind Fabric Colour 3166",
            },
            {
              name: MotturaMasterOne3167,
              alt: "Blind Fabric Colour 3167",
            },
            {
              name: MotturaMasterOne3168,
              alt: "Blind Fabric Colour 3168",
            },
            {
              name: MotturaMasterOne3169,
              alt: "Blind Fabric Colour 3169",
            },
            {
              name: MotturaMasterOne3170,
              alt: "Blind Fabric Colour 3170",
            },
            {
              name: MotturaMasterOne3173,
              alt: "Blind Fabric Colour 3173",
            },
            {
              name: MotturaMasterOne3174,
              alt: "Blind Fabric Colour 3174",
            },
            {
              name: MotturaMasterOne3182,
              alt: "Blind Fabric Colour 3182",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Mottura Master Fabric Tech Data 0220.pdf",
            },
          ],
        },
        {
          id: "10",
          heading: "Mottura Master 2",
          operation: "Fabric",
          details: "Mottura Master 2",
          descriptionOne:
            "Featuring the same soft lustre and plain weave as Mottura Master 1, this 100% Trevira CS fabric is denser and weighs 260 gr/m2. It is also available in 35 colors, with some options suitable for roller blinds due to their weight. The fabric is 340cm wide and 0.45mm thick. Similar to the first fabric, it is PVC-free, easy to clean, and maintains its durability and shape after frequent washing. It can be machine washed up to 30°C and meets fire resistance standards such as FR: AS1530.2 + AS 1530.3 and Italian UNI 9177, 9174, and 8456 Class 1.",
          images: [
            {
              name: MotturaMasterTwoMAIN,
              alt: "Mottura Master 2",
            },
          ],
          colorImages: [
            {
              name: MotturaMasterTwo3002,
              alt: "Blind Fabric Colour 3002",
            },
            {
              name: MotturaMasterTwo3003,
              alt: "Blind Fabric Colour 3003",
            },
            {
              name: MotturaMasterTwo3004,
              alt: "Blind Fabric Colour 3004",
            },
            {
              name: MotturaMasterTwo3005,
              alt: "Blind Fabric Colour 3005",
            },
            {
              name: MotturaMasterTwo3006,
              alt: "Blind Fabric Colour 3006",
            },
            {
              name: MotturaMasterTwo3007,
              alt: "Blind Fabric Colour 3007",
            },
            {
              name: MotturaMasterTwo3008,
              alt: "Blind Fabric Colour 3008",
            },
            {
              name: MotturaMasterTwo3009,
              alt: "Blind Fabric Colour 3009",
            },
            {
              name: MotturaMasterTwo3010,
              alt: "Blind Fabric Colour 3010",
            },
            {
              name: MotturaMasterTwo3011,
              alt: "Blind Fabric Colour 3011",
            },
            {
              name: MotturaMasterTwo3013,
              alt: "Blind Fabric Colour 3013",
            },
            {
              name: MotturaMasterTwo3023,
              alt: "Blind Fabric Colour 3023",
            },
            {
              name: MotturaMasterTwo3024,
              alt: "Blind Fabric Colour 3024",
            },
            {
              name: MotturaMasterTwo3025,
              alt: "Blind Fabric Colour 3025",
            },
            {
              name: MotturaMasterTwo3027,
              alt: "Blind Fabric Colour 3027",
            },
            {
              name: MotturaMasterTwo3038,
              alt: "Blind Fabric Colour 3038",
            },
            {
              name: MotturaMasterTwo3041,
              alt: "Blind Fabric Colour 3041",
            },
            {
              name: MotturaMasterTwo3043,
              alt: "Blind Fabric Colour 3043",
            },
            {
              name: MotturaMasterTwo3046,
              alt: "Blind Fabric Colour 3046",
            },
            {
              name: MotturaMasterTwo3051,
              alt: "Blind Fabric Colour 3051",
            },
            {
              name: MotturaMasterTwo3055,
              alt: "Blind Fabric Colour 3055",
            },
            {
              name: MotturaMasterTwo3056,
              alt: "Blind Fabric Colour 3056",
            },
            {
              name: MotturaMasterTwo3057,
              alt: "Blind Fabric Colour 3057",
            },
            {
              name: MotturaMasterTwo3059,
              alt: "Blind Fabric Colour 3059",
            },
            {
              name: MotturaMasterTwo3061,
              alt: "Blind Fabric Colour 3061",
            },
            {
              name: MotturaMasterTwo3064,
              alt: "Blind Fabric Colour 3064",
            },
            {
              name: MotturaMasterTwo3065,
              alt: "Blind Fabric Colour 3065",
            },
            {
              name: MotturaMasterTwo3066,
              alt: "Blind Fabric Colour 3066",
            },
            {
              name: MotturaMasterTwo3067,
              alt: "Blind Fabric Colour 3067",
            },
            {
              name: MotturaMasterTwo3068,
              alt: "Blind Fabric Colour 3068",
            },
            {
              name: MotturaMasterTwo3069,
              alt: "Blind Fabric Colour 3069",
            },
            {
              name: MotturaMasterTwo3070,
              alt: "Blind Fabric Colour 3070",
            },
            {
              name: MotturaMasterTwo3073,
              alt: "Blind Fabric Colour 3073",
            },
            {
              name: MotturaMasterTwo3074,
              alt: "Blind Fabric Colour 3074",
            },
            {
              name: MotturaMasterTwo3082,
              alt: "Blind Fabric Colour 3082",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Mottura Master Fabric Tech Data 0220.pdf",
            },
          ],
        },
        {
          id: "11",
          heading: "Notte 1 FR",
          operation: "Fabric",
          details: "Notte 1 FR",
          descriptionOne:
            "Our Notte 1 FR Collection is a tightly woven, medium to heavyweight fabric that \r\nis perfect for refreshing and modernizing interiors with the added benefit of sound \r\nabsorbency and flame retardant properties.",
          images: [
            {
              name: NotteOneFrMAIN,
              alt: "Notte 1 FR",
            },
          ],
          colorImages: [],
          downloads: [
            {
              id: "1",
              name: "2018 Vertilux Notte 1 FR Curtain Fabric Tech Data Sheet.pdf",
            },
          ],
        },
      ],
      accessories: [
        {
          id: "1",
          heading: "Aluproff Hook 2571",
          product: "2571",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "2571",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "2571",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Material 1",
              description: "ABS",
            },
            {
              name: "Material",
              description: "UV-Protection",
            },

            {
              name: "Colours",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "2",
          heading: "Aluproff Hook 2572",
          product: "2572",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          descriptionTwo: "Hook, Strong Model.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "2572",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "2572",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Material 1",
              description: "ABS",
            },
            {
              name: "Material",
              description: "UV-Protection",
            },

            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "3",
          heading: "Aluproff Hook 2573",
          product: "2573",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "2573",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "2573",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Plastic - Strong, Hook Model",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "4",
          heading: "Aluproff Hook 2075",
          product: "2075",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "2075",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "2075",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Plastic - Double Hook",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "5",
          heading: "Aluproff Hook 2078",
          product: "2078",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "2078",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "2078",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Curtain hook for double glider 4057",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "6",
          heading: "Aluproff Hook 4071",
          product: "4071",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "4071",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "4071",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Metal hook 29 mm",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "7",
          heading: "Aluproff Hook 4072",
          product: "4072",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "4072",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "4072",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Metal - Metal Hook 39 mm",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "8",
          heading: "Aluproff Clip Hook 5801",
          product: "5801",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5801",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5801",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Metal - Clip Hook",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "9",
          heading: "Aluproff Adjustable Hook 2568",
          product: "2568",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "2568",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "2568",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Metal - Adjustable Hook 74 mm",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "10",
          heading: "Aluproff Adjustable Hook 2569",
          product: "2569",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "2569",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "2569",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Metal - Adjustable Hook 95 mm",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "11",
          heading: "Aluproff Metal Hook 10038",
          product: "10038",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Hooks for multiple purposes - suitable for all curtain tapes and gliders.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "10038",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "10038",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For curtain tapes",
            },
            {
              name: "Material",
              description: "Metal Hook",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "12",
          heading: "Aluproff Gliders 5730 - 6 mm",
          product: "5730",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5730",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5730",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For 4 mm & 6 mm glider tracks",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "13",
          heading: "Aluproff Gliders 5735 - 8 mm",
          product: "5735",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5735",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5735",
          },
          techSpecs: [
            {
              name: "Use",
              description: "For 4 mm & 6 mm glider tracks",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "14",
          heading: "Aluproff Pull Rod 1274",
          product: "1274",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1274",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1274",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Plastic coated - 50 cm",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "15",
          heading: "Aluproff Pull Rod 1275",
          product: "1275",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1275",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1275",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Plastic coated - 75 cm",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "16",
          heading: "Aluproff Pull Rod 1276",
          product: "1276",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1276",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1276",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Plastic coated - 100 cm",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "17",
          heading: "Aluproff Pull Rod 1280",
          product: "1280",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1280",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1280",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Plastic coated - 125 cm",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "18",
          heading: "Aluproff Pull Rod 1281",
          product: "1281",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1281",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1281",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Plastic coated - 150 cm",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "19",
          heading: "Aluproff Pull Rod 1284",
          product: "1284",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1284",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1284",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Plastic coated - 175 cm",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White, Black",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "20",
          heading: "Aluproff Pull Rod 1283",
          product: "1283",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1283",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1283",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Plastic coated - 200 cm",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "21",
          heading: "Aluproff Pull Rod 1273",
          product: "1273",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1273",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1273",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Plastic coated - 250 cm",
            },
            {
              name: "Material",
              description: "Plastic",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "22",
          heading: "Aluproff Pull Rod 1277",
          product: "1277",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1277",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1277",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Acrylic - 50 cm",
            },
            {
              name: "Material",
              description: "Acrylic",
            },
            {
              name: "Colours",
              description: "Clear",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "23",
          heading: "Aluproff Pull Rod 1278",
          product: "1278",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1278",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1278",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Acrylic - 75 cm",
            },
            {
              name: "Material",
              description: "Acrylic",
            },
            {
              name: "Colours",
              description: "Clear",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "24",
          heading: "Aluproff Pull Rod 1279",
          product: "1279",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1279",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1279",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Acrylic - 100 cm",
            },
            {
              name: "Material",
              description: "Acrylic",
            },
            {
              name: "Colours",
              description: "Clear",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "25",
          heading: "Aluproff Pull Rod 1282",
          product: "1282",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1282",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1282",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Acrylic - 125 cm",
            },
            {
              name: "Material",
              description: "Acrylic",
            },
            {
              name: "Colours",
              description: "Clear",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "26",
          heading: "Aluproff Pull Rod 1285",
          product: "1285",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1285",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1285",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Acrylic - 150 cm",
            },
            {
              name: "Material",
              description: "Acrylic",
            },
            {
              name: "Colours",
              description: "Clear",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "27",
          heading: "Aluproff Pull Rod 1287",
          product: "1287",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1287",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1287",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Acrylic - 175 cm",
            },
            {
              name: "Material",
              description: "Acrylic",
            },
            {
              name: "Colours",
              description: "Clear",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "28",
          heading: "Aluproff Pull Rod 1288",
          product: "1288",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne:
            "Our wide range of gliders are suitable for multiple tracks and purposes. With UV-protection to ensure the material is long lasting and keeps its colour. And with gradual silicone release to ensure smooth funtionality.",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1288",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1288",
          },
          techSpecs: [
            {
              name: "Description",
              description: "Pull rod Acrylic - 200 cm",
            },
            {
              name: "Material",
              description: "Acrylic",
            },
            {
              name: "Colours",
              description: "Clear",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "29",
          heading: "Aluproff Pull Rod 1388",
          product: "1388",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Pull Rod Aluminum",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1388",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1388",
          },
          techSpecs: [
            {
              name: "Length",
              description: "75 cm",
            },
            {
              name: "Material",
              description: "Aluminum",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "30",
          heading: "Aluproff Pull Rod 1389",
          product: "1389",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Pull Rod Aluminum",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1389",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1389",
          },
          techSpecs: [
            {
              name: "Length",
              description: "100 cm",
            },
            {
              name: "Material",
              description: "Aluminum",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "31",
          heading: "Aluproff Pull Rod 1390",
          product: "1390",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Pull Rod Aluminum",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1390",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1390",
          },
          techSpecs: [
            {
              name: "Length",
              description: "125 cm",
            },
            {
              name: "Material",
              description: "Aluminum",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "32",
          heading: "Aluproff Pull Rod 1391",
          product: "1391",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Pull Rod Aluminum",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1391",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1391",
          },
          techSpecs: [
            {
              name: "Length",
              description: "150 cm",
            },
            {
              name: "Material",
              description: "Aluminum",
            },
            {
              name: "Colours",
              description: "White",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },

        {
          id: "33",
          heading: "Aluproff Pull Rod 1384",
          product: "1384",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Pull Rod Aluminum",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1384",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1384",
          },
          techSpecs: [
            {
              name: "Length",
              description: "75 cm",
            },
            {
              name: "Material",
              description: "Aluminum",
            },
            {
              name: "Colours",
              description: "Gun Metal Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "34",
          heading: "Aluproff Pull Rod 1385",
          product: "1385",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Pull Rod Aluminum",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1385",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1385",
          },
          techSpecs: [
            {
              name: "Length",
              description: "100 cm",
            },
            {
              name: "Material",
              description: "Aluminum",
            },
            {
              name: "Colours",
              description: "Gun Metal Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "35",
          heading: "Aluproff Pull Rod 1386",
          product: "1386",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Pull Rod Aluminum",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1386",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1386",
          },
          techSpecs: [
            {
              name: "Length",
              description: "125 cm",
            },
            {
              name: "Material",
              description: "Aluminum",
            },
            {
              name: "Colours",
              description: "Gun Metal Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "36",
          heading: "Aluproff Pull Rod 1387",
          product: "1387",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Pull Rod Aluminum",
          images: [
            {
              name: CurtainSystemSix,
              alt: "1387",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "1387",
          },
          techSpecs: [
            {
              name: "Length",
              description: "150 cm",
            },
            {
              name: "Material",
              description: "Aluminum",
            },
            {
              name: "Colours",
              description: "Gun Metal Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "37",
          heading: "Aluproff Ceiling Support 5022/20",
          product: "5022",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5022",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5022",
          },
          techSpecs: [
            {
              name: "Length",
              description: "20 cm",
            },
            {
              name: "Tube Size",
              description: "8 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "38",
          heading: "Aluproff Ceiling Support 5022/40",
          product: "5022",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5022",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5022",
          },
          techSpecs: [
            {
              name: "Length",
              description: "40 cm",
            },
            {
              name: "Tube Size",
              description: "8 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "39",
          heading: "Aluproff Ceiling Support 5022/60",
          product: "5022",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5022",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5022",
          },
          techSpecs: [
            {
              name: "Length",
              description: "60 cm",
            },
            {
              name: "Tube Size",
              description: "8 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "40",
          heading: "Aluproff Ceiling Support 5022/80",
          product: "5022",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5022",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5022",
          },
          techSpecs: [
            {
              name: "Length",
              description: "80 cm",
            },
            {
              name: "Tube Size",
              description: "8 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "41",
          heading: "Aluproff Ceiling Support 5022/100",
          product: "5022",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5022",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5022",
          },
          techSpecs: [
            {
              name: "Length",
              description: "100 cm",
            },
            {
              name: "Tube Size",
              description: "8 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "42",
          heading: "Aluproff Ceiling Support 5021/80",
          product: "5021",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5021",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5021",
          },
          techSpecs: [
            {
              name: "Length",
              description: "80 cm",
            },
            {
              name: "Tube Size",
              description: "16 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "43",
          heading: "Aluproff Ceiling Support 5021/120",
          product: "5021",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5021",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5021",
          },
          techSpecs: [
            {
              name: "Length",
              description: "120 cm",
            },
            {
              name: "Tube Size",
              description: "16 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "44",
          heading: "Aluproff Ceiling Support 5021/140",
          product: "5021",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5021",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5021",
          },
          techSpecs: [
            {
              name: "Length",
              description: "140 cm",
            },
            {
              name: "Tube Size",
              description: "16 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "45",
          heading: "Aluproff Ceiling Support 5021/160",
          product: "5021",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5021",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5021",
          },
          techSpecs: [
            {
              name: "Length",
              description: "160 cm",
            },
            {
              name: "Tube Size",
              description: "16 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "46",
          heading: "Aluproff Ceiling Support 5021/200",
          product: "5021",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Ceiling Support Tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5021",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5021",
          },
          techSpecs: [
            {
              name: "Length",
              description: "200 cm",
            },
            {
              name: "Tube Size",
              description: "16 mm",
            },
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "47",
          heading: "Aluproff F4042",
          product: "F4042",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Top part for the 16 mm tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "F4042",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "F4042",
          },
          techSpecs: [
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "48",
          heading: "Aluproff F4043",
          product: "F4043",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Alu tube 16 mm, in lengths of 5 meters",
          images: [
            {
              name: CurtainSystemSix,
              alt: "F4043",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "F4043",
          },
          techSpecs: [
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "49",
          heading: "Aluproff F4045",
          product: "F4045",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "Bottom part for the 16 mm tube",
          images: [
            {
              name: CurtainSystemSix,
              alt: "F4045",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "F4045",
          },
          techSpecs: [
            {
              name: "Colours",
              description: "White, Black, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "50",
          heading: "Aluproff 5020",
          product: "5020",
          operation: "accessories",
          details: "ALUPROFF",
          descriptionOne: "V-Ceiling support 8 mm - made to measure",
          images: [
            {
              name: CurtainSystemSix,
              alt: "5020",
            },
          ],
          standAloneImg: {
            name: CurtainSystemSix,
            alt: "5020",
          },
          techSpecs: [
            {
              name: "Colours",
              description: "White, Grey",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
      ],
    },
  },
  {
    id: "3",
    type: "venetians",
    heading: "Venetians",
    images: [{ name: VenetiansImage, alt: "Venetians" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "25mm Easytilt Venetian Blind System 2.1A",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Our 25mm Easytilt Venetian Blind System’s mechanism allows the blind to go from fully opened to fully closed in just two and a half turns of the wand.            ",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: Easytiltb,
              alt: "Easytilt",
            },
            {
              name: Easytiltc,
              alt: "Easytilt",
            },
            {
              name: Easytiltd,
              alt: "Easytilt",
            },
            {
              name: Easytilte,
              alt: "Easytilt",
            },
          ],
          standAloneImg: {
            name: Easytilta,
            alt: "Easytilt",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_2_1a_Easytilt_Aluminium_Venetian_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "2",
          heading: "25mm Easytilt Midnighter Venetian System 2.1B",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Easytilt Midnighter Venetian System Features all the benefits of the Easytilt Venetian Blind. However, light filtering through slats is minimised further when closed through specialised positioning of rout holes, offering the ultimate in light control and privacy.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: EasytiltMidnighterb,
              alt: "EasytiltMidnighter",
            },
          ],
          standAloneImg: {
            name: EasytiltMidnightera,
            alt: "EasytiltMidnighter",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_2_1b_Easytilt_Midnighter_Aluminium_Venetian_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "3",
          heading: "25mm Monocommand Venetian Blind System 2.2",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "With the Vertilux Monocommand Venetian Blind System, a single chain controls all of the blind’s functions: opening, closing, raising and lowering.",
          descriptionTwo:
            "Offering complete light and shade control the Vertilux Monocommand Venetian Blind System suits large and small window spaces, and complements architectural styles in both residential and commercial settings.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: Monocommandb,
              alt: "Monocommand",
            },
            {
              name: Monocommandc,
              alt: "Monocommand",
            },
          ],
          standAloneImg: {
            name: Monocommanda,
            alt: "Monocommand",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "2.2 Vertilux 25mm Monocommand Aluminium Venetian System Data Sheet_Apr 2019.pdf",
            },
          ],
        },
        {
          id: "4",
          heading: "50mm Wideline Venetian Blind System 2.3",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "A classic window covering choice, the 50mm Wideline Venetian Blind compacts neatly for minimal stacking bulk.",
          descriptionTwo:
            "Vertilux 50mm Wideline Venetian Blind System differ slightly from our other Venetian Blinds in that they operate using two sets of cords instead of the traditional wand. One set of cords is used to tilt the blind, while the other is used to open and close slats.",
          descriptionThree:
            "Offering excellent light and shade control, the Vertilux 50mm Wideline Venetian Blind System is ideal for large span windows.",
          descriptionFour: "",
          images: [
            {
              name: Widelineb,
              alt: "Wideline",
            },
          ],
          standAloneImg: {
            name: Widelinea,
            alt: "Wideline",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_2_3_Wideline_Aluminium_Venetian_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "5",
          heading: "Motorised Venetian Blind System 2.4",
          product: "Vertilux",
          operation: "Motorised",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "With the Vertilux Motorised Venetian Blind System, the benefits of Venetian blinds are enhanced by the total ease of operation that comes with motorisation. Perfect for situations where large numbers of blinds need to be tilted, raised or lowered, Vertilux motorised Venetians can even be programmed so that light control is automatic.",
          descriptionTwo:
            "Vertilux Motorised Venetian Blinds use the latest in wireless technology. An easy to use remote control means motorised Venetians are both convenient and simple to use. Both Aluminium and Timber Venetians can be motorised and all Vertilux motorised Venetians include colour coordinated accessories.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: MotorisedVenetiansb,
              alt: "MotorisedVenetians",
            },
            {
              name: MotorisedVenetiansc,
              alt: "MotorisedVenetians",
            },
            {
              name: MotorisedVenetiansd,
              alt: "MotorisedVenetians",
            },
            {
              name: MotorisedVenetianse,
              alt: "MotorisedVenetians",
            },
            {
              name: MotorisedVenetiansf,
              alt: "MotorisedVenetians",
            },
          ],
          standAloneImg: {
            name: MotorisedVenetiansa,
            alt: "MotorisedVenetians",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_2_4_Motorised_Venetian_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "6",
          heading: "46mm, 60mm + 84mm Cedarline Venetian Blind System 2.5",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Cedarline Venetian Blind System is a classic blind choice made from the highest quality genuine natural cedar. Available in 46mm, 60mm and 84mm width slats, they complement contemporary as well as period and heritage homes, adding warm, natural texture to any interior space.",
          descriptionTwo:
            "Cedarline Venetian Blinds are easy to operate and offer total light control. Clean lines and unobtrusive ladder tape enhance the overall effect.",
          descriptionThree:
            "Vertilux Cedarline Venetian Blinds are finished using UV fade resistant oil and lacquer. To highlight natural grain, slats are evenly graded and vacuum coated in a single pass to ensure all four sides of each slat have a completely even finish.",
          descriptionFour:
            "Vertilux cedar is aerated and naturally dried prior to manufacture, increasing its attractiveness and durability. It exceeds Australian standards for colourfastness and fade resistance. Cedarline Venetian Blind Systems are easily installed and include a timber headbox for a uniform finish. Accessories are colour coordinated.",
          images: [
            {
              name: CedarlineVenetiansb,
              alt: "CedarlineVenetians",
            },
            {
              name: CedarlineVenetiansc,
              alt: "CedarlineVenetians",
            },
            {
              name: CedarlineVenetiansd,
              alt: "CedarlineVenetians",
            },
            {
              name: CedarlineVenetianse,
              alt: "CedarlineVenetians",
            },
          ],
          standAloneImg: {
            name: CedarlineVenetiansa,
            alt: "CedarlineVenetians",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_2_5a_Cedarline_Venetian_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "7",
          heading: "Double Glazed Venetian Blind System 2.7",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Double Glazed Venetian Blind System has been specifically designed to fit snugly between panes of a double glazed window. Tilt is adjusted by a control normally mounted on the frame of the internal glass pane.",
          descriptionTwo:
            "The control of the Venetian can be via a Knob, Allen Key or Anti-Ligature Control.  Available only in 25mm wide slats.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: DoubleGlazedVenetiansb,
              alt: "DoubleGlazedVenetians",
            },
            {
              name: DoubleGlazedVenetiansc,
              alt: "DoubleGlazedVenetians",
            },
            {
              name: DoubleGlazedVenetiansd,
              alt: "DoubleGlazedVenetians",
            },
            {
              name: DoubleGlazedVenetianse,
              alt: "DoubleGlazedVenetians",
            },
            {
              name: DoubleGlazedVenetiansf,
              alt: "DoubleGlazedVenetians",
            },
            {
              name: DoubleGlazedVenetiansg,
              alt: "DoubleGlazedVenetians",
            },
            {
              name: DoubleGlazedVenetiansh,
              alt: "DoubleGlazedVenetians",
            },
          ],
          standAloneImg: {
            name: DoubleGlazedVenetiansa,
            alt: "DoubleGlazedVenetians",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_2_7_Double_Glazed_Interstitial_Venetian_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "8",
          heading: "Venetian Shapes and Control Guide Systems 2.8",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Vertilux offer a wide range of shape options for the full range of Venetian blinds, such as Skylights, caravans and Cathedral shaped windows. We can also tailor Venetians to your particular windows from a template.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: ShapesControlVenetiansb,
              alt: "ShapesControlVenetians",
            },
            {
              name: ShapesControlVenetiansc,
              alt: "ShapesControlVenetians",
            },
            {
              name: ShapesControlVenetiansd,
              alt: "ShapesControlVenetians",
            },
          ],
          standAloneImg: {
            name: ShapesControlVenetiansa,
            alt: "ShapesControlVenetians",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_2_8_Venetian_Shape_Control_Types_Venetian_V1_0816.pdf",
            },
          ],
        },
        {
          id: "9",
          heading: "Ultrawood Venetian System 2.9",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Ultrawood Venetian Blinds provide high-tech design. Functional yet elegant, they are available in 50mm & 65mm blade widths.",
          descriptionTwo:
            "Manufactured using Industry-Leading Technology, this expanded Wood Fibre Polystyrene Composite can withstand temperatures up to 65 degrees Celsius without bending or losing integrity.These timbers offer a unique composition that add an understated touch of sophistication to any interior.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: UltrawoodVenetiansb,
              alt: "UltrawoodVenetians",
            },
            {
              name: UltrawoodVenetiansc,
              alt: "UltrawoodVenetians",
            },
            {
              name: UltrawoodVenetiansd,
              alt: "UltrawoodVenetians",
            },
            {
              name: UltrawoodVenetianse,
              alt: "UltrawoodVenetians",
            },
            {
              name: UltrawoodVenetiansf,
              alt: "UltrawoodVenetians",
            },
            {
              name: UltrawoodVenetiansg,
              alt: "UltrawoodVenetians",
            },
          ],
          standAloneImg: {
            name: UltrawoodVenetiansa,
            alt: "UltrawoodVenetians",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "2.9 Vertilux Ultrawood Venetian System_Data Sheet_V3_1122.pdf",
            },
          ],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Coming soon",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      accessories: [],
    },
  },
  {
    id: "4",
    type: "panel-glides",
    heading: "Panel Glides",
    images: [{ name: PanelGlideImage, alt: "Panel Glides" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "Panel Glide System 5.2",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Available in two, three, four or five channel track, the Panel Glide Blind System offers effective light and shade control and UV protection across large expanses of window making it the perfect blind for larger windows, offices, patios and sliding doors.",
          descriptionTwo:
            "Panels may be free-floating and individually adjusted. Alternatively, panels may be collected and follow a lead panel in sequence to open and close.",
          descriptionThree:
            "Recommended panel sizes range from 300mm (minimum) to 1100mm (maximum).",
          descriptionFour: "",
          images: [
            {
              name: PanelGlidesb,
              alt: "PanelGlides",
            },
            {
              name: PanelGlidesc,
              alt: "PanelGlides",
            },
            {
              name: PanelGlidesd,
              alt: "PanelGlides",
            },
            {
              name: PanelGlidese,
              alt: "PanelGlides",
            },
            {
              name: PanelGlidesf,
              alt: "PanelGlides",
            },
            {
              name: PanelGlidesg,
              alt: "PanelGlides",
            },
          ],
          standAloneImg: {
            name: PanelGlidesa,
            alt: "PanelGlides",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_5_2_Panel_Glide_System_V1_0816.pdf",
            },
          ],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Coming soon",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      accessories: [],
    },
  },
  {
    id: "5",
    type: "verticals",
    heading: "Verticals",
    images: [{ name: VerticalImage, alt: "Verticals" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "Vertical Blind System 5.1",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Designed for optimum performance over spans of up to 5800mm, the Vertilux Vertical Blind is both hardy and ideal for unique design applications and are an excellent solution for hard-to-reach and expansive situations. Vertical Blind operational components are high-moisture rated and come with a five-year guarantee.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: Verticalsb,
              alt: "Verticals",
            },
            {
              name: Verticalsc,
              alt: "Verticals",
            },
            {
              name: Verticalsd,
              alt: "Verticals",
            },
            {
              name: Verticalse,
              alt: "Verticals",
            },
            {
              name: Verticalsf,
              alt: "Verticals",
            },
            {
              name: Verticalsg,
              alt: "Verticals",
            },
            {
              name: Verticalsh,
              alt: "Verticals",
            },
            {
              name: Verticalsi,
              alt: "Verticals",
            },
          ],
          standAloneImg: {
            name: Verticalsa,
            alt: "Verticals",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_5_1_Vertical_Blind_System_V1_0816.pdf",
            },
          ],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Coming soon",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      accessories: [],
    },
  },
  {
    id: "6",
    type: "romans",
    heading: "Romans",
    images: [{ name: RomansImage, alt: "Romans" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "Avanti Roman Blind System 4.0",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Available in a wide range of fabrics, Roman Blind Systems offer great flexibility in light control. Roman Blinds made with sheer fabrics provide glare reduction and protection from harmful UV, while total room darkening fabrics provide complete darkness. All fabrics are non-flammable and distortion-free.",
          descriptionTwo:
            "Tasteful and easy to operate, Vertilux Roman Blind Systems include a fabric covered head-rail and operating mechanisms, generating a clean, stylish finish.",
          descriptionThree:
            "Each Roman Blind System is designed to ensure each folding segment is symmetric and evenly sized. Battens are secured using a spline and channel instead of being sewn, therefore eliminating all light permitting tiny holes. Vertilux traditional Roman Blind Systems are Australian made and are both stylish and durable. They are also easy to clean and virtually maintenance-free.",
          descriptionFour:
            "The Avanti Roman Blind System takes the Traditional Roman Blind System and adds a stylish Front Batten detail spaced the full drop of the blind, creating a more contemporary look.  The Front Batten is available in a wide range of colours and is designed to coordinate with the base rail.",
          images: [
            {
              name: AvantiRomansb,
              alt: "AvantiRomans",
            },
            {
              name: AvantiRomansc,
              alt: "AvantiRomans",
            },
            {
              name: AvantiRomansd,
              alt: "AvantiRomans",
            },
          ],
          standAloneImg: {
            name: AvantiRomansa,
            alt: "AvantiRomans",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_4_0_Avanti_Roman_Blind_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "2",
          heading: "Contemporary Roman Blind System 4.1",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Features all the style benefits of the Traditional Roman Blind System but with the addition of a contemporary spring loaded bracket system           ",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: ContemporaryRomansb,
              alt: "ContemporaryRomans",
            },
            {
              name: ContemporaryRomansc,
              alt: "ContemporaryRomans",
            },
            {
              name: ContemporaryRomansd,
              alt: "ContemporaryRomans",
            },
            {
              name: ContemporaryRomanse,
              alt: "ContemporaryRomans",
            },
          ],
          standAloneImg: {
            name: ContemporaryRomansa,
            alt: "ContemporaryRomans",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_4_1_Contemporary_Roman_Blind_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "3",
          heading: "Easyrise Roman Blind System 4.2",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Easyrise Roman Blind System operates with the ease of a continuous chain mechanism.",
          descriptionTwo:
            "Suited to a wide range of architectural styles, Easyrise Roman Blinds can sustain fabric widths of up to 2500mm.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: EasyriseRomansb,
              alt: "EasyriseRomans",
            },
            {
              name: EasyriseRomansc,
              alt: "EasyriseRomans",
            },
          ],
          standAloneImg: {
            name: EasyriseRomansa,
            alt: "EasyriseRomans",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "4.2 Vertilux Contemporary Easyrise Roman System Data Sheet_Oct 2016.pdf",
            },
          ],
        },
        {
          id: "4",
          heading: "Traditional Roman Blind System 4.3",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Our classic Traditional Roman Blind System, featuring all the specifications of the Avanti Roman Blind System, but without the front batten.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: TraditionalRomansa,
              alt: "TraditionalRomans",
            },
          ],
          standAloneImg: {
            name: TraditionalRomansa,
            alt: "TraditionalRomans",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_4_3_Traditional_Roman_Blind_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "5",
          heading: "Motorised Roman Blind System 4.4",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Motorised Roman Blind System takes our traditional Romans and adds total light and shade control at the touch of a button.",
          descriptionTwo:
            "All Vertilux Motorised Roman Blinds use the latest in wireless technology, making their operation simple, reliable and durable. State of the art motorisation comes standard on all Vertilux Motorised Roman Blind Systems, making them a blind choice that combines technological excellence with functionality and style",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: MotorisedRomansa,
              alt: "MotorisedRomans",
            },
          ],
          standAloneImg: {
            name: MotorisedRomansa,
            alt: "MotorisedRomans",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_4_4_Motorised_Roman_System_V1_0816.pdf",
            },
          ],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Coming soon",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      accessories: [],
    },
  },
  {
    id: "7",
    type: "pleated-blinds",
    heading: "Pleated Blinds",
    images: [{ name: PleatedImage, alt: "Pleated Blinds" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "Pleated Duopleat Blind System 3.1",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The body of our Pleated/Duopleat system type is made of lightweight fabric, which will compact into a narrow stack giving it an elegant and minimalistic look when totally opened. The Vertilux Pleated Blind System is especially suited to very large blinds, which would be impossible using other blind types such as Venetians.",
          descriptionTwo:
            "Easy to install and requiring no maintenance, Pleated Blind System are energy efficient and will contribute to a reduction in heating and cooling costs.",
          descriptionThree:
            "Vertilux Pleated and Duopleat Blind Systems include colour coordinated extruded aluminium head-rails, base rails and associated componentry such as control cords.",
          descriptionFour: "",
          images: [
            {
              name: DuopleatPleatedb,
              alt: "DuopleatPleated",
            },
            {
              name: DuopleatPleatedc,
              alt: "DuopleatPleated",
            },
            {
              name: DuopleatPleatedd,
              alt: "DuopleatPleated",
            },
            {
              name: DuopleatPleatede,
              alt: "DuopleatPleated",
            },
            {
              name: DuopleatPleatedf,
              alt: "DuopleatPleated",
            },
          ],
          standAloneImg: {
            name: DuopleatPleateda,
            alt: "DuopleatPleated",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_3_1_Pleated_Duopleat_System_V1_0816.pdf",
            },
          ], //QQQQQ
        },
        {
          id: "2",
          heading: "Easyrise Pleated Duopleat Blind System 3.2",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Easyrise Pleated/Duopleated Blind System is operated by a single continuous loop chain instead of cords.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [{ name: NoImage, alt: "Easyrise Pleated/Duopleat Blind System 3.2" }],
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_3_2_Easyrise_Pleated_Duopleat_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "3",
          heading: "Motorised Pleated Duopleat Blind System 3.3",
          product: "Vertilux",
          operation: "Motorised",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Motorised Pleated/Duopleat Blind System takes our traditional systems and adds total light and shade control at the touch of a button.",
          descriptionTwo:
            "All Vertilux Motorised Pleated/Duopleat Blind Systems use the latest in technology options, making their operation simple, reliable and durable. State of the art motorisation comes standard on all Vertilux Motorised Pleated/Duopleat Blinds, making them a blind choice that combines technological excellence with functionality and style.",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: MotorisedPleatedb,
              alt: "MotorisedPleated",
            },
            {
              name: MotorisedPleatedc,
              alt: "MotorisedPleated",
            },
            {
              name: MotorisedPleatedd,
              alt: "MotorisedPleated",
            },
            {
              name: MotorisedPleatede,
              alt: "MotorisedPleated",
            },
            {
              name: MotorisedPleatedf,
              alt: "MotorisedPleated",
            },
            {
              name: MotorisedPleatedg,
              alt: "MotorisedPleated",
            },
            {
              name: MotorisedPleatedh,
              alt: "MotorisedPleated",
            },
            {
              name: MotorisedPleatedi,
              alt: "MotorisedPleated",
            },
            {
              name: MotorisedPleatedj,
              alt: "MotorisedPleated",
            },
          ],
          standAloneImg: {
            name: MotorisedPleateda,
            alt: "MotorisedPleated",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_3_3_Motorised_Pleated_Duopleat_System_V1_0816.pdf",
            },
          ],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Coming soon",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      accessories: [],
    },
  },
  {
    id: "8",
    type: "external-zip-systems",
    heading: "External Zip Systems",
    images: [{ name: RollerBlindOnePointZeroSixteenImageTwo, alt: "External Zip Systems" }],
    components: {
      systems: [],
      fabrics: [],
      accessories: [],
    },
  },

  {
    id: "9",
    type: "plantation-shutters",
    heading: "Plantation Shutters",
    images: [{ name: ShuttersImage, alt: "Plantation Shutters" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "Coming soon",
          product: "Vertilux",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Coming soon",
          product: "Vertilux",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      accessories: [],
    },
  },
  {
    id: "10",
    type: "external-louvers-venetians",
    heading: "External Louvers/Venetians ",
    images: [{ name: NoImage, alt: "External Louvers/Venetians " }],
    components: {
      systems: [],
      fabrics: [
        {
          id: "1",
          heading: "Coming soon",
          product: "Vertilux",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      accessories: [],
    },
  },
  {
    id: "11",
    type: "skylight-blinds",
    heading: "Skylight Blinds",
    images: [{ name: SkylightImage, alt: "Skylight Blinds" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "Motorised Kassett Tensioned System 1.13c",
          product: "Vertilux",
          operation: "Motorised",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Motorised Kassett Tensioned System has the blind in a fully enclosed headbox and uses motorisation in conjunction with constant boxes to provide smooth constant tension. This makes them ideal for skylights or angled windows as are typically mounted vertically and horizontally but can be installed at any angle up to 360 degrees.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            { name: RollerBlindOnePointZeroThirteenImageSix, alt: "1.13c Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroThirteenImageSeven,
            alt: "1.13c Motorised Operated",
          },
          techSpecs: [
            {
              name: "Face cover plate",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Back rail section",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Headrail endcaps L/R",
              description: "Moulded from U.V resistant acetal.",
            },
            {
              name: "Bracket male",
              description: "Zinc coated steel.",
            },
            {
              name: "Bracket female",
              description: "Zinc coated steel.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to IS09001.",
            },
            {
              name: "Idle end",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Motor mount",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Base rail",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "End cap",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Kassett bracket",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "1.13c Vertilux Motorised Kassett Tensioned System Data Sheet_Feb2018.pdf.pdf",
            },
          ],
        },
        {
          id: "2",
          heading: "Z1 Zip™ Roller Blind Tension System 1.17",
          product: "Vertilux",
          operation: "Motorised",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The Vertilux Z1 Zip™ Roller Blind Tension System now allows roller blinds to be used in situations not previously suitable for roller blinds. The Z1 Zip™ Roller Blind Tension system is suitable for skylight applications and angled windows; when all four corners are at 90°. Using this tension system along with the zip locking system, which runs the full length of the side channel, the fabric is held in place at any point of operation. This system is only available motorised.",
          descriptionTwo:
            "So, as with the Z1 Zip™ Roller Blind System, the Z1 Zip™ Tension Roller Blind System utilises a sleek and stylish enclosure for the blind and its mechanisms, with a 100mm fully enclosed headbox, 70mm deep full length side rails and 56mm enclosed motor casing.",
          descriptionThree:
            "With the added side channels  and a room darkening fabric the blind becomes fully enclosed which will totally block out any side light leakage ensuring maximum insulation from the elements or alternatively, using a Translucent or Transparent fabric with suitable Solar Optical Properties for the application.",
          descriptionFour:
            "The head boxes and side channels are manufactured from extruded aluminium and a wide range of powder coated colours and colour coordinated accessories are available.",
          images: [
            { name: RollerBlindOnePointZeroSeventeenImageOne, alt: "1.17 Motorised Operated" },
            { name: RollerBlindOnePointZeroSeventeenImageTwo, alt: "1.17 Motorised Operated" },
          ],
          standAloneImg: {
            name: RollerBlindOnePointZeroSeventeenImageThree,
            alt: "1.17 Motorised Operated",
          },
          techSpecs: [
            {
              name: "Face cover plate",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Back rail section",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Headrail endcaps L/R with side channel adaptor",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "Bracket male",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Bracket female",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Spring booster",
              description:
                "High tensity wound steel wire coiled spring booster, with high ratio winding Vertilux patent No. 760547.",
            },
            {
              name: "Drive end adaptor",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "Idle end",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "70mm side channel",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Side channel insert",
              description: "Extruded ASA distortion resistance to 90°.",
            },
            {
              name: "Zip insert",
              description: "Polyester zip.",
            },
            {
              name: "50mm base rail",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Base rail end cap",
              description: "Moulded from U.V. resistant acetal.",
            },
            {
              name: "U channel",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "External fixing brackets",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Headbox back plate",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Headbox cover plate",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Head rail end caps",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Motor support",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "Idle end caps",
              description: "Pressed powdercoated steel.",
            },
            {
              name: "Tube",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
            {
              name: "Motor",
              description: "Motor to suit application.",
            },
            {
              name: "Tube support",
              description: "U.V. stabilised nylon.",
            },
            {
              name: "Brackets",
              description: "Extruded aluminium manufactured to ISO9001.",
            },
          ],
          downloads: [
            {
              id: "1",
              name: "Vertilux_1_17_Z1_Zip_Tension_System_V1_0816.pdf",
            },
          ],
        },
        {
          id: "3",
          heading: "Venetian Shapes and Control Guide Systems 2.8",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Vertilux offer a wide range of shape options for the full range of Venetian blinds, such as Skylights, caravans and Cathedral shaped windows. We can also tailor Venetians to your particular windows from a template.",
          descriptionTwo: "",
          descriptionThree: "",
          descriptionFour: "",
          images: [
            {
              name: ShapesControlVenetiansb,
              alt: "ShapesControlVenetians",
            },
            {
              name: ShapesControlVenetiansc,
              alt: "ShapesControlVenetians",
            },
            {
              name: ShapesControlVenetiansd,
              alt: "ShapesControlVenetians",
            },
          ],
          standAloneImg: {
            name: ShapesControlVenetiansa,
            alt: "ShapesControlVenetians",
          },
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_2_8_Venetian_Shape_Control_Types_Venetian_V1_0816.pdf",
            },
          ],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Coming soon",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      accessories: [],
    },
  },
  {
    id: "12",
    type: "mesh-curtains",
    heading: "Mesh Curtains",
    images: [{ name: NoImage, alt: "Mesh Curtains" }],
    components: {
      systems: [
        {
          id: "1",
          heading: "Multiplo 412",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne: "Hand or cord operated.",
          descriptionTwo:
            "For use with curtains 500cm width max. and 300cm height, weighing not more than 6kg. The curtain opening can be central or lateral; (multiple or asymmetric by hand only). The system is suitable for ceiling and wall installation. With hand use the system allows systems curved through 90° or with wide radius centring to be installed. Only straight systems can be installed with cord operating.",
          descriptionThree:
            "Sliding is guaranteed by self-lubricating polyethylene slides or by acetylic roller gliders for curtains at the load limit.",
          descriptionFour: "",
          images: [{ name: OneZeroOneSpringOperated, alt: "Multiplo 412" }],
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_1_Multiplo_412_Manual_Curtain_Track_System_hand_traverse_V1_0816.pdf",
            },
            {
              id: "2",
              name: "Vertilux_9_2_Multiplo_412_Manual_Curtain_Track_System_cord_operated_V1_0816.pdf",
            },
          ],
        },
        {
          id: "2",
          heading: "Separè 458",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Hand operated and can be used with curtains 800cm width max. and 300cm height, weighing up to 6kg. Separè is also the perfect solution for your Healthcare Curtain Track needs. ",
          descriptionTwo:
            "In addition, OneBlind can be easily integrated with building automation systems including lighting, climate control and audio-visual systems for the ultimate in convenience and energy control. It might look like magic, but it’s actually just a brilliant design.",
          descriptionThree:
            "The system is suitable for ceiling and wall installations and free hanging ones using specific hangers, preferably with exposed track. The system allows tracks curved through 90° or with wide radius centring to be installed. Sliding is guaranteed by selflubricating polyethylene slides or by roller gliders for curtains at the load limit. ",
          descriptionFour:
            "The S.R.D. Safety Release Device mechanism can be fitted to this system, making it the perfect safety device and it does not require a specialist to reset the system once activated.",
          images: [{ name: OneZeroOneSpringOperated, alt: "Separè 458" }],
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_6_Separè_458_Manual_Curtain_Track_System_hand_traverse_V1_0816.pdf",
            },
          ],
        },
        {
          id: "3",
          heading: "Ego 480",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "The system has the feature of being double-faced as both sides can be installed to show the flat or curved aesthetic effect.",
          descriptionTwo:
            "For use with curtains 600cm width max. and 300cm height, weighing up to 6kg. Sliding is guaranteed by self-lubricating polyethylene slides or by roller gliders for curtains at the load limits.",
          descriptionThree: "",
          descriptionFour: "",
          images: [{ name: OneZeroOneSpringOperated, alt: "Ego 480" }],
          techSpecs: [],
          downloads: [],
        },
        {
          id: "4",
          heading: "Arco 490",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "500cm height not weighing more than 12kg. The opening can be central, lateral, multiple or asymmetric.",
          descriptionTwo:
            "The system is suitable for ceiling and wall installations. The running of the operating cords in independent channels allows systems with 90° curves or with wide radius centring to be installed, guaranteeing excellent sliding.",
          descriptionThree: "",
          descriptionFour: "",
          images: [{ name: OneZeroOneSpringOperated, alt: "Arco 490" }],
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_4 Arco 490 Manual Curtain_Track_System_hand_traverse_V1_0816.pdf",
            },
            {
              id: "2",
              name: "Vertilux_9_5_Arco_490_Manual_Curtain_Track_System_cord_operated_V1_0816.pdf",
            },
          ],
        },
        {
          id: "5",
          heading: "Luna",
          product: "Vertilux",
          operation: "Manual",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Luna is our newest curtain track available in 3 colors ( Satin, Black & White ). ",
          descriptionTwo:
            "Specially designed with interconnecting gliders (available in 60mm or 80mm) which are contained within the curtain track to create a seamless ripple effect. It’s hand drawn operation can be set up for central or one way opening for systems up to 6000mm in length.",
          descriptionThree:
            "Please note that this curtain system is a top fix ceiling track with no bends or joins possible.",
          descriptionFour: "",
          images: [{ name: OneZeroOneSpringOperated, alt: "Luna" }],
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "LUNA_Product Data Sheet_Vertilux_08022017.pdf",
            },
          ],
        },
        {
          id: "6",
          heading: "Power 541",
          product: "Vertilux",
          operation: "Motorised",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Power 541 is equipped with Autostart® which allows hand-drawn operating too.",
          descriptionTwo:
            "The actuator consists of a 24 Vdc motor with wide-range switching power supply 90-250 Vac / 24Vdc and electronics that supervise system operation. The sliding speed of the curtain is 12 cm/sec.",
          descriptionThree: "",
          descriptionFour: "",
          images: [{ name: OneZeroOneSpringOperated, alt: "Power 541" }],
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_7_Power_541_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "7",
          heading: "Power 571",
          product: "Vertilux",
          operation: "Motorised",
          details: "VERTILUX SYSTEMS.",
          descriptionOne: "Power 571 can move up to 50kg of curtaining. ",
          descriptionTwo:
            "The actuator consists of a 24 Vdc motor with wide-range switching power supply 90-250 Vac / 24Vdc and electronics that supervise system operation. The sliding speed of the curtain is 25cm/sec. Power is transmitted by a polyurethane toothed belt. Maximum guaranteed dimensions: length 15 metres. Side, symmetrical or asymmetrical centre openings, 90° curves with 35cm radius, or wide radius arches are possible. ",
          descriptionThree:
            "The use of sliders on ball bearings ensures good operation even near the weight limit. The system electronics have been developed to allow use of the Power 571 system in wireless digital networks or by hardwire with bidirectional exchange of data between actuator and control device.",
          descriptionFour: "",
          images: [{ name: OneZeroOneSpringOperated, alt: "1.21 Motorised Operated" }],
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_8_Power_571_Motorised_Curtain_Track_System_rts_motorised_rf_V1_0816.pdf",
            },
          ],
        },
        {
          id: "8",
          heading: "Separè 458",
          product: "Vertilux",
          operation: "Healthcare",
          details: "VERTILUX SYSTEMS.",
          descriptionOne:
            "Separè is the perfect solution for your Healthcare Curtain Track needs. Hand operated and can be used with curtains 800cm width max. and 300cm height, weighing up to 6kg. ",
          descriptionTwo:
            "The system is suitable for ceiling and wall installations and free hanging ones using specific hangers, preferably with exposed track. The system allows tracks curved through 90° or with wide radius centring to be installed.",
          descriptionThree:
            "Sliding is guaranteed by selflubricating polyethylene slides or by roller gliders for curtains at the load limit. The S.R.D. Safety Release Device mechanism can be fitted to this system, making it the perfect safety device and it does not require a specialist to reset the system once activated.",
          descriptionFour: "",
          images: [{ name: OneZeroOneSpringOperated, alt: "Separè 458" }],
          techSpecs: [],
          downloads: [
            {
              id: "1",
              name: "Vertilux_9_6_Separè_458_Manual_Curtain_Track_System_hand_traverse_V1_0816.pdf",
            },
          ],
        },
      ],
      fabrics: [
        {
          id: "1",
          heading: "Coming soon",
          operation: "Motorised/Manual",
          description: "xxxxxxxxxxx",
          images: [],
        },
      ],
      accessories: [],
    },
  },
];

export default ProductsCatalogue;
