import { Canvas } from "@react-three/fiber";
import "bootstrap/dist/css/bootstrap.min.css";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import ConfiguratorOptions from "../../components/configurator-threejs/ConfiguratorOptions";
import Experience from "../../components/configurator-threejs/Experience";
const CustomisationContext = createContext({
  height: {},
  setHeight: () => { },
  width: {},
  setWidth: () => { },
  depth: {},
  setDepth: () => { },
  component: {},
  systems: {},
  setComponent: () => { },
  color: {},
  setColor: () => { },
  showEnv: {},
  setShowEnv: () => { },
  system: {},
  setSystem: () => { },
});

const CustomisationProvider = (props) => {
  const defaultColor = "#5C5C62";
  const [height, setHeight] = useState(500);
  const [width, setWidth] = useState(500);
  const [showEnv, setShowEnv] = useState(false);
  const [depth, setDepth] = useState({ unit: 1, heightHistory: 500 });
  const [component, setComponent] = useState(null);
  const [system, setSystem] = useState(null);
  const [color, setColor] = useState([
    { name: "Fabric", color: defaultColor },
    { name: "Clutch", color: defaultColor },
    { name: "Base Rail", color: defaultColor },
    { name: "Brackets", color: defaultColor },
  ]);

  const systems = useMemo(
    () => [
      {
        id: 1,
        name: "Easydrive BGS Roller Blind System 1.02",
        dimensionLimitations: { minWidth: 430, maxWidth: 2400, minDrop: 500, maxDrop: 3050 },
        componentColors: [
          { name: "Fabric", colors: ["green", "orange", "purple", "default"] },
          { name: "Clutch", colors: ["green", "pink", "blue", "white"] },
          { name: "Base Rail", colors: ["green", "orange", "blue"] },
          { name: "Brackets", colors: ["green", "black", "orange", "blue", "white"] },
        ],
      },
      {
        id: 2,
        name: "Easydrive BGS Roller Blind System 1.03",
        dimensionLimitations: { minWidth: 600, maxWidth: 3400, minDrop: 500, maxDrop: 4500 },
        componentColors: [
          { name: "Fabric", colors: ["green", "orange", "purple", "default"] },
          { name: "Clutch", colors: ["green", "pink", "blue", "white"] },
          { name: "Base Rail", colors: ["green", "orange", "blue"] },
          { name: "Brackets", colors: ["green", "black", "orange", "blue", "white"] },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    if (system === null) setSystem(systems[0]);
  }, [system, systems]);

  return (
    <CustomisationContext.Provider
      value={{
        showEnv,
        setShowEnv,
        height,
        setHeight,
        width,
        setWidth,
        depth,
        setDepth,
        systems,
        system,
        setSystem,
        component,
        setComponent,
        color,
        setColor,
        defaultColor,
      }}
    >
      {props.children}
    </CustomisationContext.Provider>
  );
};

export const useCustomisation = () => {
  const context = useContext(CustomisationContext);
  return context;
};

const ConfiguratorPage2 = () => {
  return (
    <>
      <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
        <CustomisationProvider>
          <Canvas>
            <Experience />
            <ambientLight intensity={0.5} />
          </Canvas>
          <ConfiguratorOptions />
        </CustomisationProvider>
      </div>
    </>
  );
};

export default ConfiguratorPage2;
