import ImageTemplate from "./ImageTemplate";

const ImageCard2 = ({
  heading = "",
  subHeading = "",
  details = "",
  image = [{ name: "", alt: "" }],
}) => {
  return (
    <>
      <div>
        <div className="heading-2">{heading}</div>
        <ImageTemplate className={"image-template-size w-100"} image={image} />
        <div className="d-flex justify-content-between">
          <span className="heading-4">{details}</span>
          <span className="heading-4">
            <u>View</u>
          </span>
        </div>
      </div>
    </>
  );
};

export default ImageCard2;
