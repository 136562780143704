import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import confusedImg from "../../src/images/Asking-confused-emoticon-question-questioning-smiley-support-clipart.png";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ fontSize: "2em" }}>
          Hey, I think you lost your way here. Press <span className="fw-bold">" Ok "</span> to go
          back.
        </p>
        <img
          src={confusedImg}
          style={{ height: "5em", width: "5em", marginBottom: "3em" }}
          alt="confused"
        ></img>
        {/* ALL CREDIT TO RESPECTIVE OWNER: https://clipartix.com/confused-emoticon-image-2894/  */}
        <Button
          onClick={() => navigate(-1)}
          style={{
            backgroundColor: "#3c342c",
            border: "none",
            padding: "0.5em 1em",
            fontSize: "1.5em",
            fontWeight: "600",
            color: "#ffdbb9",
          }}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};
export default NotFoundPage;
