import { Dropdown, Form } from "react-bootstrap";
import { useCustomisation } from "../../pages/configurator/ConfiguratorPage2";
import ColorConfigurator, { ConfiguratorBox } from "./ColorConfigurator";
import RangeHorizontal from "./RangeHorizontal";
import RangeVertical from "./RangeVertical";
import SizeConfigurator from "./SizeConfigurator";

const EnvironmentConfigurator = () => {
  const { showEnv, setShowEnv } = useCustomisation();

  return (
    <ConfiguratorBox>
      <Form.Check
        type="switch"
        id="custom-switch"
        label="Show Background"
        value={showEnv}
        onChange={(e) => setShowEnv(e.target.checked)}
      />
    </ConfiguratorBox>
  );
};

const SystemConfigurator = () => {
  const { systems, system, setSystem } = useCustomisation();

  const sys = system && systems.find((sys) => sys.id === system.id);
  return (
    <ConfiguratorBox>
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          style={{
            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {sys?.name}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ width: "100%" }}>
          {systems.map((s, i) => (
            <Dropdown.Item style={{ whiteSpace: "pre-wrap" }} key={i} onClick={() => setSystem(s)}>
              {s.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </ConfiguratorBox>
  );
};

const Configurator = () => {
  return (
    <div>
      <RangeHorizontal />
      <RangeVertical />
      <div
        className="d-flex gap-3 flex-column"
        style={{ top: "1em", right: "1em", position: "absolute" }}
      >
        <SystemConfigurator />

        <ColorConfigurator />
        <SizeConfigurator />
        <EnvironmentConfigurator />
      </div>
    </div>
  );
};

export default Configurator;
