import { Form } from "react-bootstrap";
import { useCustomisation } from "../../pages/configurator/ConfiguratorPage2";

const RangeVertical = () => {
  const { height, setHeight, system } = useCustomisation();

  let minDrop, maxDrop;

  if (system) {
    const { minDrop: minD, maxDrop: maxD } = system && system?.dimensionLimitations;
    minDrop = minD;
    maxDrop = maxD;
  }
  return (
    <div
      style={{
        position: "absolute",
        bottom: "5vh",
        width: "100vw",
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Form.Range
          className="w-50"
          min={minDrop}
          max={maxDrop}
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>
    </div>
  );
};

export default RangeVertical;
