import { Button } from "react-bootstrap";
import { Slide, toast } from "react-toastify";

const CopyToClipboard = ({ text }) => {
  return (
    <Button
      variant="light"
      className="d-flex gap-3 align-items-center"
      onClick={() => {
        navigator.clipboard.writeText(text);
        toast.success("Copied to clipboard", {
          position: "bottom-center",
          transition: Slide,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }}
    >
      <u className="body-font">{text}</u>
      <i className="bi bi-clipboard" />
    </Button>
  );
};

export default CopyToClipboard;
