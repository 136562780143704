import SectionFour from "./SectionFour";
import SectionOne from "./SectionOne";
import SectionThree from "./SectionThree";
import SectionTwo from "./SectionTwo";

const ProductIndividualFabrics = () => {
  return (
    <>
      <div>
        <SectionOne />
        <hr style={{ margin: "10em 0" }} />

        <SectionTwo />
        <SectionThree />
        <SectionFour />
      </div>
    </>
  );
};

export default ProductIndividualFabrics;
