import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ImageTemplate from "../../../components/image/ImageTemplate";
import ProductsCatalogue from "../product-components/ProductsCatalogue";

const SectionTwo = () => {
  const params = useParams();

  const data = ProductsCatalogue.find((m) => m.type === params.productType)["components"][
    "systems"
  ].find((f) => f.heading === params.productSubType);
  return (
    <>
      <Row className="m-0 w-100">
        <Col md={12} xl={6} className="heading-2" style={{ paddingBottom: "4em" }}>
          <strong>{data.descriptionTwo}</strong>
        </Col>
        <Col />
      </Row>
      <Row className="m-0 section-two">
        <Col md={12} xl={6} className="p-0 h-100">
          <ImageTemplate
            image={{ name: data?.standAloneImg?.name }}
            style={{ width: "100%", height: "100%" }}
          />
        </Col>
        <Col md={12} xl={6} className="section-two-description h-100">
          <div className="heading-2 sub-one">
            <strong>{data.descriptionThree}</strong>
          </div>
          <div className="body-font d-flex flex-fill align-items-center">
            {data.descriptionFour}
          </div>
        </Col>
      </Row>
      <Row className="w-100" style={{ margin: "4em 0" }}>
        <hr className="my-5" />
        <Col md={12} xl={4}>
          <div className="heading-1">{data.details}</div>
        </Col>
        <Col md={12} xl={8} className="d-flex align-items-center">
          <div className="body-font"></div>
        </Col>
        <hr className="my-5" />
      </Row>
    </>
  );
};

export default SectionTwo;
