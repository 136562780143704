import image1 from "../../images/2500_Antracit-31.jpg";

export const residential = [
  {
    id: 1,
    coverImage: image1,

    info: {
      hero_Title: "RESIDENTIAL",
      hero_Description1:
        "Blineco has a special focus on commercial projects for window coverings having completed many award winning projects over the years. ",
      hero_Description2:
        "With extensive experience in automated window coverings solutions , having worked with most Building Management Systems, having completed large projects with automated and integrated window coverings over the years.",
      hero_Description3:
        "Our engineering background places us in a good position to provide a solid reliable solution for any project that requires the integration of window coverings into the building management system.",

      projects_involved: "Projects Involved",
      description: "Project Description",
      products_involved: "Products",
      products_description:
        "Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et veli dipiscing elit. Nunc vulputate libero et velitt interdum, ac aliquet odio mattis.",
      images: [image1],
    },
  },
  {
    id: 2,
    coverImage: image1,

    info: {
      projects_involved: "Sample 1",
      description: "Project Description",
      products_involved: "products Involved",
      products_description: "Products Description",
      images: [image1],
    },
  },
  {
    id: 3,
    coverImage: image1,

    info: {
      projects_involved: "Sample 1",
      description: "Project Description",
      products_involved: "products Involved",
      products_description: "Products Description",
      images: [image1],
    },
  },
  {
    id: 4,
    coverImage: image1,

    info: {
      projects_involved: "Sample 1",
      description: "Project Description",
      products_involved: "products Involved",
      products_description: "Products Description",
      images: [image1],
    },
  },
];
