import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Carousel, { CarouselItem } from "../../../components/image/Carousel";
import useScrollToElement from "../../../hooks/useScrollToElement";
import ProductsCatalogue from "../product-components/ProductsCatalogue";

const SectionOne = () => {
  const params = useParams();
  const scrollTo = useScrollToElement();

  const data = ProductsCatalogue.find((m) => m.type === params.productType)["components"][
    "systems"
  ].find((f) => f.heading === params.productSubType);

  const style = {
    button: {
      borderRadius: "0",
      border: "none",
      background: "#3C342C",
      width: "12.5em",
      height: "100%",
    },
    downloadButton: {
      borderRadius: "0",
      border: "1px dashed gray",
      background: "none",
      width: "12.5em",
      color: "#3C342C",
      marginLeft: "1em",
    },
  };

  return (
    <div className="section-one">
      <div className="section-one-carousel">
        <Carousel autoSlide expandedView>
          {data && data["images"].map((m, i) => <CarouselItem key={i} src={m.name} />)}
        </Carousel>
      </div>
      <div className="section-one-information">
        <header>
          <div>{data.details}</div>
          <div className="heading-1">{params.productSubType}</div>
        </header>
        <div className="body-font">{data.descriptionOne}</div>
        <hr />
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column gap-2">
            <Button style={style.button} onClick={() => scrollTo("specs")}>
              Specifications
            </Button>
            {/* <Button style={style.button}>Configure Yours</Button> */}
          </div>
          <div>
            <Button style={style.downloadButton} onClick={() => scrollTo("specs-download")}>
              <div>Download Specs</div> <i className="bi bi-download" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
