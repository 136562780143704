import { useEffect, useState } from "react";
import "./scrollToTop.scss";

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  const showScroll = () => {
    if (document.body.scrollTop > 800 || document.documentElement.scrollTop > 800) {
      setVisible(true);
    } else setVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", showScroll, false);
    return () => {
      window.removeEventListener("scroll", showScroll, false);
    };
  }, []);

  const handleMove = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // here it goes
  };

  return (
    <button
      id="back-to-top"
      className={visible ? "back-to-top-visible" : null}
      onClick={handleMove}
      title="Go To Top"
    >
      {/* <i className="fas fa-chevron-circle-up"></i> */}
      Scroll To Top
    </button>
  );
};

export default ScrollToTop;
