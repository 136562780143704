import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageCard from "../../../components/image/ImageCard";
import Atag from "../../../components/misc/Atag";
import { ProductContext } from "../ProductsPage";
import ProductIndividualAccessories from "../product-individual-accessories/ProductIndividualAccessories";
import ProductIndividualFabrics from "../product-individual-fabrics/ProductIndividualFabrics";
import ProductIndividualSystems from "../product-individual-systems/ProductIndividualSystems";
import "./ProductComponents.scss";
import ProductTypes from "./ProductTypes";
import ProductsCatalogue from "./ProductsCatalogue";

const ProductComponents = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const { display } = useContext(ProductContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [params]);

  const filteredProducts = useCallback(() => {
    if (params.productType !== undefined) {
      const filteredData = ProductsCatalogue.filter(
        (product) => product.type === params.productType
      );

      const comp = [...filteredData][0].components;

      if (display.vertilux) {
        return [{ ...filteredData[0], components: comp }];
      } else if (!display.vertilux) {
        const syst = comp["systems"].filter((f) => f.product !== "VERTILUX SYSTEMS.");
        const fab = comp["fabrics"].filter((f) => f.product !== "VERTILUX SYSTEMS.");
        const acc = comp["accessories"].filter((f) => f.product !== "ALUPROFF");

        const prod = { fabrics: fab, systems: syst, accessories: acc };

        return [{ ...filteredData[0], components: prod }];
      }
    } else return ProductsCatalogue;
  }, [display.vertilux, params.productType]);

  return (
    <div style={{ paddingTop: "4%", paddingRight: "6%", paddingLeft: "5%" }}>
      <div className={params.productType ? "" : "products-standard-display"}>
        {console.log(window.location.href)}
        {filteredProducts().map((product) => (
          <div key={product.id}>
            {!params.productType && (
              <Atag href={`${product.type}`}>
                <ImageCard
                  {...{
                    heading: product.heading,
                    details: product.details,
                    image: product?.images[0],
                  }}
                />
              </Atag>
            )}
            {params.productType && !params.productSubType && (
              <>
                {product.components.systems.length > 0 && (
                  <div id={"systems-section"} className="pt-4">
                    <ProductTypes
                      setData={setData}
                      product={product}
                      type={"systems"}
                      systemDescription={
                        "With exquisitely engineered, patented designs, Roller Blind Systems are perfectly balanced for widths of up to 3400mm for manual operation and 3900mm for single motorised. Roller Blind Systems can be motorised and operated via remote-control, or programmed to self-operate at pre-designated times. Alternatively, the Roller System can be chain operated, with chain options available in both poly-resin and stainless steel finishes or Solar Operated – the choice is yours."
                      }
                    />
                  </div>
                )}
                {product.components.fabrics.length > 0 && (
                  <div id={"fabrics-section"} className="pt-4">
                    <ProductTypes
                      product={product}
                      type={"fabrics"}
                      systemDescription={
                        "Introducing our stunning collection of blind fabrics that will transform your windows into works of art! Our fabrics are carefully crafted to not only provide privacy and light control but also to add style and sophistication to any room. With a wide range of textures, colors, and patterns to choose from, you'll find the perfect match for your unique style and decor. Our blind fabrics are made with the highest quality materials, ensuring durability and long-lasting beauty. Trust us to elevate the look of your home with our luxurious blind fabrics."
                      }
                    />
                  </div>
                )}
                {product.components.accessories.length > 0 && (
                  <div id={"accessories-section"} className="pt-4">
                    <ProductTypes
                      product={product}
                      type={"accessories"}
                      systemDescription={
                        "Introducing our stunning collection of blind fabrics that will transform your windows into works of art! Our fabrics are carefully crafted to not only provide privacy and light control but also to add style and sophistication to any room. With a wide range of textures, colors, and patterns to choose from, you'll find the perfect match for your unique style and decor. Our blind fabrics are made with the highest quality materials, ensuring durability and long-lasting beauty. Trust us to elevate the look of your home with our luxurious blind fabrics."
                      }
                    />
                  </div>
                )}
              </>
            )}
            {params.productSubType && params.type === "systems" && (
              <ProductIndividualSystems data={data} />
            )}
            {params.productSubType && params.type === "fabrics" && (
              <ProductIndividualFabrics data={data} />
            )}
            {params.productSubType && params.type === "accessories" && (
              <ProductIndividualAccessories data={data} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductComponents;
