import { Button, Col, Row, Spinner } from "react-bootstrap";
import CopyToClipboard from "../../components/misc/CopyToClipboard";
import "./ContactPage.scss";

const ContactPage = () => {
  return (
    <div className="contact-page padding-increase" id="contact-us">
      <header className="heading-1">CONTACT US</header>
      <Row className="m-0">
        <Col sm={12} md={6} className="gap-4 d-flex flex-column body-font">
          <Row>
            Have a question, feedback, or inquiry? We're here to help! Get in touch with our
            dedicated team by filling out our contact form or reaching out to us via phone or email.
            We value your input and look forward to assisting you with any concerns or assistance
            you may need. Don't hesitate to get in touch with us today.
          </Row>
          <Row>
            <div>Location</div>
            <CopyToClipboard text={"4/202 Lorimer Street, Port Melbourne, VIC 3207"} />
          </Row>
          <Row>
            <div>Admin Contact</div>
            <CopyToClipboard text={"admin@blineco.com"} />
          </Row>
          <Row>
            <div>Estimating Contact</div>
            <CopyToClipboard text={"estimating@blineco.com"} />
          </Row>
          <Row>
            <div>Phone</div>
            <CopyToClipboard text={"+61 3 9015 7722"} />
          </Row>
        </Col>
        <Col sm={12} md={6} className="d-flex justify-content-end">
          {/* <ContactPageForm /> */}
        </Col>
      </Row>
      <div className="d-flex align-items-start flex-column w-100 py-5" style={{ marginTop: "8em" }}>
        <header className="heading-1">ALTERNATIVELY</header>
        <div className="body-font">
          Save time with our user-friendly configurator. Customize your options effortlessly,
          tailoring your preferences in a few simple steps.
        </div>
        <Button aria-disabled="true" variant="primary" style={{ margin: "2em 0em" }}>
          Configure Yours
        </Button>
        <div
          className="coming-soon d-flex justify-content-center align-items-center gap-3"
          style={{ height: "500px", width: "100%", fontSize: "2em" }}
        >
          {/* Will be replaced with screenshot of our configurator */}
          <Spinner animation="grow" variant="secondary" size="sm">
            <span className="visually-hidden"></span>
          </Spinner>
          {"Coming Soon..."}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
