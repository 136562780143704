import { Form, InputGroup } from "react-bootstrap";
import { useCustomisation } from "../../pages/configurator/ConfiguratorPage2";
import { ConfiguratorBox } from "./ColorConfigurator";

const SizeConfigurator = () => {
  const { width, system, setWidth, height, setHeight } = useCustomisation();

  let minWidth, maxWidth, minDrop, maxDrop;

  if (system) {
    const {
      minWidth: minW,
      maxWidth: maxW,
      minDrop: minD,
      maxDrop: maxD,
    } = system && system?.dimensionLimitations;
    minWidth = minW;
    maxWidth = maxW;
    minDrop = minD;
    maxDrop = maxD;
  }

  const changeWidthSize = (e) => {
    let value = e.target.value;
    setWidth(value);
  };

  const changeHeightSize = (e) => {
    let value = e.target.value;
    setHeight(value);
  };
  return (
    <>
      {system && (
        <ConfiguratorBox>
          <Form.Label htmlFor="width">Width (mm)</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              min={minWidth}
              max={maxWidth}
              type="number"
              id="width"
              value={width}
              isInvalid={width < minWidth || width > maxWidth}
              onChange={changeWidthSize}
            />
            <Form.Control.Feedback type="invalid">
              Range between {minWidth} - {maxWidth}
            </Form.Control.Feedback>
          </InputGroup>
          <Form.Label htmlFor="height">Height (mm)</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              min={minDrop}
              max={maxDrop}
              type="number"
              id="height"
              value={height}
              isInvalid={height < minDrop || height > maxDrop}
              onChange={changeHeightSize}
            />
            <Form.Control.Feedback type="invalid">
              Range between {minDrop} - {maxDrop}
            </Form.Control.Feedback>
          </InputGroup>
        </ConfiguratorBox>
      )}
    </>
  );
};

export default SizeConfigurator;
