/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.11 public/blinds/scene2.gltf
Author: Pavan Salam (https://sketchfab.com/pavansalam)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/wooden-window-99bff051298f4c75a6fada3dd67d28db
Title: Wooden window
*/

import { useGLTF } from "@react-three/drei";
import React from "react";

export function Window(props) {
  const { nodes } = useGLTF("./blinds/wooden_window/scene.gltf");
  return (
    <group {...props} dispose={null} scale={200}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[-8.66, 0, 0]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.WINDOW_BARDER_0.geometry} />
          <mesh geometry={nodes.WINDOW_BARDER_1.geometry} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./blinds/wooden_window/scene.gltf");
