import { useParams } from "react-router-dom";
import ProductsCatalogue from "../product-components/ProductsCatalogue";

const SectionTwo = () => {
  const params = useParams();

  const data = ProductsCatalogue.find((m) => m.type === params.productType)["components"][
    "fabrics"
  ].find((f) => f.heading === params.productSubType);

  const handleDownload = (path) => {
    const link = document.createElement("a");
    link.href = `/${path}`; // Replace with the actual path to your PDF file
    link.download = `${path}`; // Replace with the desired filename for the downloaded file
    link.target = "_blank"; // Open the PDF in a new tab/window

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
  return (
    <>
      <div className="py-5">
        <div>Downloads</div>
        <span className="d-flex justify-content-between">
          <header className="heading-2 d-inline">
            <strong>Documents</strong>
          </header>
          <span>
            {
              // <u>Download All</u>
            }
          </span>
        </span>
        {data["downloads"].map((download) => (
          <div key={download.id}>
            <hr className="m-1" />
            <div className="d-flex justify-content-between">
              <div style={{ cursor: "pointer" }} onClick={() => handleDownload(download.name)}>
                <i className="bi bi-file-earmark-text" style={{ fontSize: "1.5em" }} />{" "}
                <span className="body-font ps-3">{download.name}</span>
              </div>

              <a href={`/${download.name}`} download={`${download.name}`}>
                <i className="bi bi-download px-2" style={{ fontSize: "1.5em" }}></i>
              </a>
            </div>
          </div>
        ))}
        <hr className="m-1" />
      </div>
    </>
  );
};

export default SectionTwo;
