import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import Modal from "../modal/Modal";

import "./ImageCarousel.scss";

export const CarouselItem = ({ src, style, alt }) => {
  return (
    <img
      src={src}
      alt={alt}
      className="custom-carousel-item w-100 h-100"
      style={{ ...style, objectFit: "cover" }}
    />
  );
};

const Carousel = ({
  children,
  expandedView = false,
  data,
  autoSlide = false,
  duration = 10000,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused && autoSlide !== false && !showImage) {
        updateIndex(activeIndex + 1);
      }
    }, duration);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <>
      <Modal
        show={showImage}
        size={"xl"}
        handleClose={() => setShowImage(false)}
        body={
          <div className="">
            {React.Children.map(children, (child, index) => {
              if (activeIndex === index)
                return <img src={child.props.src} alt={child.props.src} className="w-100 h-100" />;
            })}
          </div>
        }
      />

      <div
        {...handlers}
        className="custom-carousel  w-100 h-100"
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <h3 style={{ display: "flex", justifyContent: "center" }}>
          {data && data["colorImages"][activeIndex]?.alt}
        </h3>
        <div
          className="custom-carousel-inner position-relative w-100 h-100"
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child);
          })}
        </div>

        {expandedView && (
          <Button
            variant="light"
            className="bi bi-arrows-angle-expand custom-carousel-expand-image-button"
            onClick={() => setShowImage(true)}
          />
        )}
        <div className="custom-carousel-indicators">
          <Button
            className="custom-carousel-navigator"
            variant="light"
            onClick={() => {
              updateIndex(activeIndex - 1);
            }}
          >
            <i className="bi bi-chevron-left" />
          </Button>
          {React.Children.map(children, (child, index) => {
            return (
              <Button
                variant="light"
                className={`${index === activeIndex ? "active" : ""}`}
                onClick={() => {
                  updateIndex(index);
                }}
              >
                <img
                  src={child.props.src}
                  alt={child.props.src}
                  className="w-100 h-100"
                  style={{ objectFit: "cover" }}
                />
              </Button>
            );
          })}
          <Button
            className="custom-carousel-navigator"
            variant="light"
            onClick={() => {
              updateIndex(activeIndex + 1);
            }}
          >
            <i className="bi bi-chevron-right" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Carousel;
