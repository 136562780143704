import { Link, useParams } from "react-router-dom";
import ImageCard2 from "../../../components/image/ImageCard2";
import ProductsCatalogue from "../product-components/ProductsCatalogue";

const SectionThree = () => {
  const params = useParams();
  const systemsData = ProductsCatalogue.find((m) => m.type === params.productType)["components"][
    "systems"
  ].sort((a, b) => parseInt(a.id) - parseInt(b.id));
  return (
    <>
      <div className="py-5">
        <header className="heading-2">
          <i>Configured with:</i>
        </header>
        <div className="products-display">
          {systemsData.map((m, index) => (
            <Link
              className="nav-link"
              activeClassName="active"
              key={index}
              to={`/products/${params.productType}/systems/${m.heading}`}
            >
              <ImageCard2
                {...{
                  heading: m.heading,
                  details: m.details,
                  image: m?.standAloneImg,
                }}
              />
            </Link>
          ))}
        </div>
        <span className="d-flex w-100 justify-content-end">
          <u></u>
        </span>
      </div>
      <hr />
    </>
  );
};

export default SectionThree;
