import { NavLink } from "react-router-dom";

const atagStyle = {
  textDecoration: "none",
  color: "black",
};

const Atag = ({ children, style = atagStyle, href = "/" }) => {
  return (
    <NavLink to={href} style={{ ...atagStyle, ...style }}>
      {children}
    </NavLink>
  );
};

export default Atag;
