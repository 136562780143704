import ImageCard3 from "../../components/image/ImageCard3";
import { residential } from "../com-res-data/ResidentialData";

const Residential = () => {
  return (
    <div className="padding-increase" style={{ padding: "0% 9%" }}>
      {residential.map((data) => (
        <div key={data.id}>
          <ImageCard3
            {...{
              image: data.coverImage,
              information: data.info,
            }}
          />
        </div>
      ))}
    </div>
  );
};
export default Residential;
