/**
 * Simple hook to scroll to an element by id.
 * This might be a lot better as it looks like using href will make the page
 * jump to the top and then scroll to the specific element with the id
 * @returns Function to call that will to a specific element
 */
const useScrollToElement = () => {
  /**
   * Scroll to an element by id
   * @param {*} elementId Element id
   */
  const scrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return scrollTo;
};

export default useScrollToElement;
