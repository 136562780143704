import { useParams } from "react-router-dom";
import Carousel, { CarouselItem } from "../../../components/image/Carousel";
import ProductsCatalogue from "../product-components/ProductsCatalogue";

const SectionOne = () => {
  const params = useParams();

  const data = ProductsCatalogue.find((m) => m.type === params.productType)["components"][
    "fabrics"
  ].find((f) => f.heading === params.productSubType);

  return (
    <div className="section-one">
      <div className="section-one-carousel">
        <Carousel data={data} autoSlide expandedView>
          {data &&
            data["colorImages"].map((m, i) => <CarouselItem key={i} src={m.name} alt={m.alt} />)}
        </Carousel>
      </div>
      <div className="section-one-information">
        <header>
          <div>{data?.details}</div>
          <div className="heading-1">{params.productSubType}</div>
        </header>
        <div className="body-font">{data?.descriptionOne}</div>
        <hr />
      </div>
    </div>
  );
};

export default SectionOne;
