import { Form } from "react-bootstrap";
import { useCustomisation } from "../../pages/configurator/ConfiguratorPage2";

const RangeHorizontal = () => {
  const { width, setWidth, system } = useCustomisation();

  let minWidth, maxWidth;

  if (system) {
    const { minWidth: minW, maxWidth: maxW } = system && system?.dimensionLimitations;
    minWidth = minW;
    maxWidth = maxW;
  }

  return (
    <div
      style={{
        position: "absolute",
        bottom: "10vh",
        width: "100vw",
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Form.Range
          className="w-50"
          min={minWidth}
          max={maxWidth}
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        />
      </div>
    </div>
  );
};

export default RangeHorizontal;
