import "./Onboarding.scss";
import ServicesComponents from "./ServicesComponents";

const ServicesOnboarding = () => {
  return (
    <>
      <div className="services-page padding-increase">
        <p id="hero-title">SERVICES</p>

        <div className="services-caption">
          <p style={{ fontSize: "1.5em" }}>
            Our team of experts is dedicated to delivering exceptional service
            and providing customized solutions that are tailored to your
            specific requirements. From consultation and design to
            implementation and support, we offer end-to-end solutions that are
            designed to help you achieve your goals. Browse our services and
            contact us to learn more about how we can help you achieve success.
          </p>
        </div>
        <ServicesComponents />
      </div>
    </>
  );
};

export default ServicesOnboarding;
