import { Link, useParams } from "react-router-dom";
import ImageCard from "../../../components/image/ImageCard";
import ProductsCatalogue from "../product-components/ProductsCatalogue";

const SectionFour = () => {
  const params = useParams();

  const fabricData = ProductsCatalogue.find((m) => m.type === params.productType)["components"][
    "fabrics"
  ];

  return (
    <>
      <div className="py-5">
        <header style={{ marginBottom: "2rem" }} className="heading-2">
          <i>Configured with:</i>
        </header>
        <div className="products-standard-display">
          {fabricData.map((m, index) => (
            <div key={index}>
              <Link
                key={index}
                className="nav-link"
                activeClassName="active"
                to={`/products/${params.productType}/fabrics/${m.heading}`}
              >
                <ImageCard
                  {...{
                    heading: m.heading,
                    details: m.details,
                    image: m?.images[0],
                  }}
                />
              </Link>
            </div>
          ))}
        </div>
        <span className="d-flex w-100 justify-content-end">
          <u></u>
        </span>
      </div>
      <hr />
    </>
  );
};

export default SectionFour;
