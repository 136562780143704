import { useEffect, useRef, useState } from "react";
import Atag from "../misc/Atag";
import OffCanvasComponent from "../offcanvas/OffCanvasComponent";
import "./Navigation.scss";

const Navigation = () => {
  const [show, setShow] = useState({ show: false, search: false });
  const inputRef = useRef(null);
  useEffect(() => {
    setTimeout(() => inputRef.current && show.search && inputRef.current.focus(), 150);
  }, [show]);

  const quickLinks = [];
  const offCanvasOption = {
    scroll: false,
    backdrop: true,
    placement: "start",
  };

  return (
    <div className="navigation-page padding-increase" id="top">
      <div className="icons">
        <OffCanvasComponent
          show={show}
          ref={inputRef}
          close={() => setShow({ show: false, search: false })}
          {...offCanvasOption}
        >
          <div
            className="fa fa-bars center d-flex gap-3"
            onClick={() => setShow({ show: true, search: false })}
          >
            <span>Menu</span>
          </div>
        </OffCanvasComponent>

        <div className="logo">
          <Atag href="/">
            <strong>Bline</strong>co.
          </Atag>
        </div>

        <div
          className="fa fa-search center"
          onClick={() => setShow({ show: true, search: true })}
          style={{ visibility: "hidden" }}
        />
      </div>

      <div className="homepage-quicklinks">
        {window.location.pathname === "/" &&
          quickLinks.map((link) => (
            <a href={`#${link.toLowerCase()}`} key={link}>
              {link}
            </a>
          ))}
      </div>
    </div>
  );
};

export default Navigation;
