import { useState } from "react";
import "./Slider.scss";

const Slider = ({ length, placeholder, style }) => {
  const [imageLength, setImagePosition] = useState(1);

  const formatNumber = (num) => (num < 10 ? `0${num}` : num.toString());

  const line = (style) => {
    return <div style={style} />;
  };

  const box = () => {
    const style = {
      height: "2px",
      background: "#FFDBB9",
    };
    return (
      <div className="arrow-container">
        <div className="top">{line(style)}</div>
        <div className="bottom">{line(style)}</div>
      </div>
    );
  };

  const circle = () => {
    const centerLineStyle = {
      width: "55%",
      height: "2px",
      background: "#FFDBB9",
    };
    return (
      <div className="slider-circle">
        <div id="center">{line(centerLineStyle)}</div>
        <div id="arrow">{box()}</div>
      </div>
    );
  };

  const nextImage = () => {
    setImagePosition((prev) => {
      if (prev < length) return prev + 1;
      else if (prev === length) return 1;
    });
  };

  return (
    <div className="slider gap-3" style={style}>
      {length > 0 && <strong>{`${formatNumber(imageLength)}/${formatNumber(length)}`}</strong>}
      <div style={{ whiteSpace: "nowrap", fontSize: "16px" }}>{placeholder}</div>
      <div className="slider-container" onClick={() => nextImage()}>
        <div className="slider-line">{line({ height: "2px", background: "black" })}</div>
        <div>{circle()}</div>
      </div>
    </div>
  );
};

export default Slider;
