import { Col, Row } from "react-bootstrap";
import estimateImage from "../../../images/Glydea-white-2.jpeg";
import "./WarrantyDefects.scss";

const WarrantyDefects = () => {
  return (
    <>
      <div className="parent-estimate">
        <div className="hero-section">
          <div>
            <p id="estimate-title">WARRANTY / DEFECTS / PRODUCT CARE</p>
            <div className="estimate-description">
              <p>
                Blineco understands that when installation is completed this does not always mean a
                project is complete and we know that we must be ready and able to assist with issues
                that might arise that we must address in a timely manner so that a project is handed
                over to the client clear of any defects or issues. We also understand the need to
                deal with warranty issues with priority. We pride ourselves on being ready and able
                to rectify issues professionally and efficiently.
              </p>
            </div>
          </div>
          <div>
            <img
              src={estimateImage}
              style={{ height: "35em", width: "40em", objectFit: "cover" }}
              alt="blineco"
            ></img>
          </div>
        </div>
        <Row className="col-6">
          <Col className="contact-us">
            <p>CONTACT US</p>
            <p>
              Our team of dedicated experts is available to assist you with any concerns you may
              have and to provide you with the support you need to resolve the issue. Whether you
              require a replacement product or repair service, we are committed to resolving the
              issue as quickly and efficiently as possible.
            </p>
            <p>
              If you encounter any issues with our products or services, please do not hesitate to
              contact us.
            </p>
            <a href="/contact-us" id="pg-bttn">
              Contact Us
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default WarrantyDefects;
