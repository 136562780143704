import useScrollTo from "../../hooks/useScrollTo";
import ProjectsPage from "../projects/ProjectsPage";

import "./AboutPage.scss";

const AboutPage = () => {
  useScrollTo();

  return (
    <div className="about-page padding-increase">
      <div id="about">
        <p>ABOUT US</p>
      </div>

      <div className="d-flex flex-row ">
        <div className="heading-2 py-5 d-flex gap-5 flex-column">
          <div className="col-md-10">
            Blineco is a business dedicated to providing the very best window coverings, with the
            focus not only on aesthetics but on “Green”, sustainable, energy efficient solutions for
            light control the “Intelligent light control solution”. The approach of Bline Automation
            is to provide the “right” solution, rather than just decorative. Our belief is that
            window coverings are there to provide an energy and privacy solution, that reduces costs
            and creates an environment that appeals.
          </div>
          <div className="col-md-10">
            Blineco focus only on the very best environmental window coverings solutions, having a
            background that includes engineering and interior design combined effectively to provide
            “the smart solution”. Having over twenty years experience in client solutions, both
            locally and internationally instils a passion for high levels of service designed to
            meet the needs of any client. Bline Automation is dedicated to the very best, Bline
            Automation was an early adopter of “Greenstar Window Coverings”, well before anyone had
            noticed or cared.
          </div>
          <div className="col-md-10">
            Blineco leads the way in providing “Green” Window coverings solutions and providing
            these solutions cost effectively in a timely manner.
          </div>
        </div>
        <div>
          <img
            style={{ height: "50em", width: "40em" }}
            src={require("../../images/Glydea-white-2.jpeg")}
            alt="logo"
          />
        </div>
      </div>
      <ProjectsPage />
    </div>
  );
};

export default AboutPage;
