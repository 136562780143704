import "./Image.scss";

const ImageInfo = (setting, link) => {
  return (
    <div className="image-info">
      <span>
        <strong>#{setting}</strong>
      </span>
      <span id={"image-info-link"}>
        <a href={`/${link}`}>{link}</a>
      </span>
    </div>
  );
};

export default ImageInfo;
