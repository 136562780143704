import { Modal as BootstrapModal, Button } from "react-bootstrap";

const Modal = (props) => {
  const {
    show,
    size = "md",
    handleClose,
    title = "",
    body,
    isFooter = false,
    isHeader = false,
  } = props;
  return (
    <>
      <BootstrapModal show={show} onHide={handleClose} centered size={size} style={{ height: "100%", width: "100%" }}>
        {isHeader && (
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>{title}</BootstrapModal.Title>
          </BootstrapModal.Header>
        )}
        <BootstrapModal.Body style={{ height: "100%", width: "100%" }}>{body}</BootstrapModal.Body>
        {isFooter && (
          <BootstrapModal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </BootstrapModal.Footer>
        )}
      </BootstrapModal>
    </>
  );
};

export default Modal;
