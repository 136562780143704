import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ImageCard2 from "../../components/image/ImageCard2";
import ImageCard4 from "../../components/image/ImageCard4";
import BlindBlockout from "../../images/products/roller-blinds/blind_blockout.jpg";
import BlindDual from "../../images/products/roller-blinds/blind_dual.jpeg";
import BlindManual from "../../images/products/roller-blinds/blind_manual.jpeg";
import BlindMotorised from "../../images/products/roller-blinds/blind_motorised.jpg";
import BlindMultiLinked from "../../images/products/roller-blinds/blind_multilink.jpg";
import BlindSingle from "../../images/products/roller-blinds/blind_single.jpg";
import BlindTransparent from "../../images/products/roller-blinds/blind_transparent.jpg";
import "./DIYBlindComponent.scss";
import ProductsCatalogue from "./product-components/ProductsCatalogue";
const DIYBlindComponent = ({
  diy,
  setDiy,
  rollType,
  opacity,
  operation,
  setRollType,
  setOpacity,
  setOperation,
}) => {
  const params = useParams();

  const blindProductSystems = ProductsCatalogue.filter((f) => f.type === "Roller Blinds")[0][
    "components"
  ]["systems"];

  const removeData = () => {
    setDiy(false);
    setOperation(null);
    setOpacity(null);
    setRollType(null);
  };
  return (
    <>
      <div>
        <div style={{ float: "right" }}>
          <Button onClick={() => removeData()} className="exit-button">
            X
          </Button>
        </div>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "1rem",
            paddingTop: "2rem",
            paddingBottom: "5rem",
          }}
        >
          What kind of Roller Blinds are you thinking of?
        </h1>
      </div>

      {/* For Single or Dual or Linked*/}
      {diy && !rollType && !opacity && !operation && (
        <div
          className={`products-standard-display`}
          style={{
            display: "flex",
            justifyContent: "center",
            overflow: "auto",
          }}
        >
          <ImageCard4
            setData={setRollType}
            {...{
              heading: "Single",
              details:
                "Single blinds are a versatile window treatment option that offers simplicity and elegance. With a single layer of fabric, these blinds provide effective light control and privacy. Available in various materials, colors, and patterns, they effortlessly complement any interior style.",
              image: { name: BlindSingle, alt: "Single" },
            }}
          />
          <ImageCard4
            setData={setRollType}
            {...{
              heading: "Dual",
              details:
                "Dual blinds, also known as double blinds, present a remarkable combination of style and functionality. With a wide range of fabric options and customizable styles, dual blinds provide a modern and innovative window treatment solution, perfectly tailored to your preferences.",
              image: { name: BlindDual, alt: "Dual" },
            }}
          />
          <ImageCard4
            setData={setRollType}
            setOperation={setOperation}
            {...{
              heading: "Linked",
              details:
                "Linked blinds consist of multiple individual blinds that are interconnected through a linking mechanism, allowing them to operate simultaneously with a single control. When the blinds are raised or lowered, all linked blinds move in unison, creating a coordinated and uniform appearance. ",
              image: { name: BlindMultiLinked, alt: "Linked" },
            }}
          />
        </div>
      )}
      {/* For Blockout or Transparent */}
      {diy && rollType && !opacity && (!operation || operation !== "Linked") && (
        <div
          className={`products-standard-display`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ImageCard4
            setData={setOpacity}
            {...{
              heading: "Blockout",
              details:
                "Blockout blinds are the ultimate choice when it comes to light control and privacy. Crafted with specially designed fabrics, these blinds effectively block out external light, creating a dark and tranquil environment perfect for bedrooms, media rooms, or any space where you desire maximum privacy. ",
              image: { name: BlindBlockout, alt: "Blockout" },
            }}
          />
          <ImageCard4
            setData={setOpacity}
            {...{
              heading: "Transparent",
              details:
                "Transparent blinds offer a unique balance between privacy and natural light. Made with sheer or translucent fabrics, these blinds allow ample sunlight to filter through while maintaining a certain level of privacy during the day. ",
              image: { name: BlindTransparent, alt: "Transparent" },
            }}
          />
        </div>
      )}

      {/* For Manual and Motors */}
      {diy && rollType && opacity && !operation && (
        <div
          className="products-standard-display"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ImageCard4
            setData={setOperation}
            {...{
              heading: "Manual",
              details:
                "Manual blinds provide a classic and hands-on approach to controlling light and privacy in your space. With a simple and intuitive mechanism, manual blinds offer effortless operation through cords, chains, or wands. ",
              image: { name: BlindManual, alt: "Manual" },
            }}
          />
          <ImageCard4
            setData={setOperation}
            {...{
              heading: "Motorised",
              details:
                "Motorized blinds bring modern convenience and automation to your window treatments. With the touch of a button or through a remote control, motorized blinds effortlessly adjust to your desired position, allowing you to effortlessly control natural light, privacy, and ambiance in your home. ",
              image: { name: BlindMotorised, alt: "Motorised" },
            }}
          />
        </div>
      )}

      {/* Suggestions */}
      {diy && rollType && opacity && operation && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4>
              <i>Here are some suggestions that match your criteria!</i>
            </h4>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem",
            }}
          >
            <i>Customize extra accessories and fabrics using our configurator</i>
            <Button style={{ margin: "1rem" }}>Send to Configurator</Button>
          </div>

          <div className="products-display" style={{ margin: "2rem" }}>
            {blindProductSystems
              .filter((f) => f.operation === operation && f.rollType === rollType)
              .map((m, index) => (
                <Link
                  onClick={() => removeData()}
                  className="nav-link"
                  activeClassName="active"
                  key={index}
                  to={`/products/${params.productType}/systems/${m.heading}`}
                >
                  <ImageCard2
                    {...{
                      heading: m.heading,
                      details: m.details,
                      image: m?.images[0],
                    }}
                  />
                </Link>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default DIYBlindComponent;
