import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Atag from "../../components/misc/Atag";
import "./Footer.scss";

const Footer = () => {
  const style = { color: "#fff5ec" };
  const mandatoryLinks = { color: "#8d8074", fontSize: "12px" };
  const params = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [params]);

  return (
    <div className="footer-page">
      <div className="footer-body padding-increase">
        <Row className="d-flex to-column justify-content-center align-items-center h-100 w-100 flex-wrap">
          <Col>
            <div className="add-gap">
              <header className="heading-1">
                <span>
                  <strong>Bline</strong>co
                </span>
              </header>
              <span className="body-font">
                Blineco acknowledges the Traditional Owners of the land where we
                work and live. We pay our respects to Elders past, present and
                emerging. We celebrate the stories, culture and traditions of
                Aboriginal and Torres Strait Islanders of all communities who
                also work and live on this land.
              </span>
            </div>
          </Col>
          <Col className="pages-services-component">
            <div className="responsiveness responsiveness-3">
              <div className="responsiveness  gap-4 py-4">
                <strong className="heading-3">PAGES</strong>
                <div className="responsiveness responsiveness-2 gap-3 body-font">
                  <Atag href="/products" style={style}>Products</Atag>
                  <Atag href="/commercial" style={style}>Commercial</Atag>
                  <Atag href="/residential" style={style}>Residential</Atag>
                  <Atag href="/about-us" style={style}>About Us</Atag>
                  <Atag href="/contact-us" style={style}>Contact Us</Atag>
                </div>
              </div>
              <div className="responsiveness gap-4 py-5 responsiveness-3">
                <strong className="heading-3">SERVICES</strong>
                <div className="responsiveness responsiveness-2 gap-3 body-font">
                  <Atag href="/coming-soon" style={style}>Configurator</Atag>
                  <Atag href="/estimating" style={style}>Estimating</Atag>
                  <Atag href="/design-specify" style={style}>Design / Specifying</Atag>
                  <Atag href="/installation-delivery" style={style}>Installation / Delivery</Atag>
                  <Atag href="/warranty-defects" style={style}>Warranty / Defect Liability</Atag>
                </div>
              </div>
            </div>
            {/* Disabled Social Media Links until further notice  */}
            {/* <div className="responsiveness gap-3 pt-3 center ">
            {renderSocialMediaIcon("fa-brands fa-instagram")}
            {renderSocialMediaIcon("fa-brands fa-square-pinterest")}
            {renderSocialMediaIcon("fa-brands fa-square-facebook")}
          </div> */}
          </Col>
        </Row>
      </div>
      <div className="footer-footer">
        <Atag href="/" style={mandatoryLinks}>2023 Blineco.</Atag>
        <div className="footer-mandatory-links">
          <Atag href="/" style={mandatoryLinks}>Privacy Policy</Atag>
          <Atag href="/" style={mandatoryLinks}>Terms & Conditions</Atag>
        </div>
      </div>
    </div>
  );
};

export default Footer;
