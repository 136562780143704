import { Col, Row } from "react-bootstrap";
import Atag from "../../components/misc/Atag";
import ComDomButton from "../../components/misc/ComDomButton";
import "./AboutPage.scss";

const AboutPageWhoWeAre = () => {
  return (
    <div className="about-page-who-we-are" id="about">
      <Row className="about-container mobile-version">
        <Col>
          <div className="body-text">
            <header className="heading-1 fw-bolder">ABOUT US</header>
            {/* Change the "who we are" to the following paragraph*/}
            <span className="body-font">
              At Blineco, our focus is on providing sustainable and
              energy-efficient window coverings that offer the "right" solution
              for our clients' needs, rather than just being decorative. We
              believe that window coverings should provide an energy and privacy
              solution that reduces costs and creates an appealing environment.
              With our background in engineering and interior design and over 20
              years of experience in client solutions, we are dedicated to
              providing the very best service and cost-effective solutions.
              Blineco is an early adopter of "Greenstar Window Coverings," and
              we continue to lead the way in providing eco-friendly solutions
              that are delivered in a timely manner.
            </span>
          </div>
        </Col>
      </Row>

      <Row className="about-container desktop-version">
        <Col className="pe-4 me-4">
          <div className="body-text">
            <header className="heading-1 fw-bold">ABOUT US</header>
            <span className="body-font">
              Blineco is a business dedicated to providing the very best window
              coverings, with the focus not only on aesthetics but on “Green”,
              sustainable, energy efficient solutions for light control the
              “Intelligent light control solution”.
            </span>
            <span className="body-font">
              The approach of Blineco is to provide the “right” solution, rather
              than just decorative. Our belief is that window coverings are
              there to provide an energy and privacy solution, that reduces
              costs and creates an environment that appeals.
            </span>
          </div>
        </Col>

        <Col className=" desktop-version d-flex justify-content-end">
          <div>
            <ComDomButton>
              <Atag href="/about-us"><button>ABOUT US</button></Atag>
            </ComDomButton>
          </div>
        </Col>

        {/* <Col className="ps-4 ms-4"> */}

        {/* Remove right side paragraph */}

        {/* <div className="body-text">
            <span className="body-font">
              Blineco focus only on the very best environmental window
              coverings solutions, having a background that includes engineering
              and interior design combined effectively to provide “the smart
              solution”. Having over twenty years experience in client
              solutions, both locally and internationally instils a passion for
              high levels of service designed to meet the needs of any client.
            </span>
            <span className="body-font">
              Blineco is dedicated to the very best, Blineco
              was an early adopter of “Greenstar Window Coverings”, well before
              anyone had noticed or cared.
            </span>
            <span className="body-font">
              Blineco leads the way in providing “Green” Window
              coverings solutions and providing these solutions cost effectively
              in a timely manner.
            </span>
          </div> */}

        {/* </Col> */}
      </Row>
    </div>
  );
};

export default AboutPageWhoWeAre;
