import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCustomisation } from "../../pages/configurator/ConfiguratorPage2";

const ColorSelection = ({ color }) => {
  const { setColor, component } = useCustomisation();

  return (
    <OverlayTrigger placement={"bottom"} overlay={<Tooltip>{color}</Tooltip>}>
      <Button
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "100%",
          borderColor: "white",
          backgroundColor: color === "default" ? "" : color,
        }}
        onClick={() =>
          setColor((prev) => [
            ...prev.filter((p) => p.name !== component),
            { name: component, color: color },
          ])
        }
      />
    </OverlayTrigger>
  );
};

export default ColorSelection;
