import { Col, Row } from "react-bootstrap";
import estimateImage from "../../../images/Glydea-white-2.jpeg";
import "./estimation.scss";

const Estimate = () => {
  return (
    <>
      <div className="parent-estimate">
        {/* Hero */}
        <div className="hero-section">
          {/*  */}
          <div>
            <p id="estimate-title">ESTIMATING</p>
            <div className="estimate-description">
              <p>
                We have a team of estimators that can provide fast efficient costings for commercial
                projects from pre-award tendering to live stages. We have built software tools to
                make our estimating clear and efficient.
              </p>
              <p>
                Blineco is focused on commercial projects, we have a team that provides estimates
                for upcoming projects. Blineco has methods available to provide a project budget
                costing.
              </p>
            </div>
          </div>
          {/*  */}
          <div>
            <img
              src={estimateImage}
              style={{ height: "35em", width: "40em", objectFit: "cover" }}
              alt="blineco"
            ></img>
          </div>
        </div>

        {/* Option 1 */}
        <Row className="option d-flex">
          <Col className="col-md">
            <p id="op-1-title">Option 1</p>
            <p id="op-1">
              Blineco has its configurator which can be used to generate an estimate automatically,
              it is a self-serve option, you make the choices for the project, input the data and it
              provides you with a cost estimate.
            </p>
            <p id="op-1">
              It should be said for the best pricing send us a message after you have used the
              configurator and we will review your choices and provide you with a competitive cost
              for your project.
            </p>
            <p id="op-1">
              For the self-serve option, we will offer an additional 5% discount to encourage you to
              use our configurator.
            </p>
          </Col>

          <Col>
            <div className="col-md">
              <p id="op-1-config">Meet our configurator</p>
              <p id="op-1" className="col-8">
                Designers are welcome and encouraged to use our configurator, it is designed to
                provide a visual and technical solution.
              </p>
              <a href="/" id="pg-bttn">
                Configure
              </a>
            </div>
            <div className="col-5">
              <img
                src={estimateImage}
                style={{
                  height: "30em",
                  width: "40em",
                  objectFit: "cover",
                  marginTop: "3em",
                }}
                alt="blineco"
              ></img>
            </div>
          </Col>
        </Row>

        {/* Option 2 */}
        <Row className="option d-flex col-5">
          <Col>
            <p id="op-1-title">Option 2</p>
            <p id="op-1">
              Send us an invite using email with a link to project documents that we can download,
              review and complete markups and take ups from which we can generate and estimate
              specific for the nominated project.
            </p>
            <p id="op-1">
              Blineco has various online tools that we have experience using, send us an invite via
              email to <a href="mailto:estimating@blineco.com">estimating@blineco.com</a> and we
              will get to work.
            </p>
            <div style={{ paddingTop: "2em" }}>
              <a href="/contact-us" id="pg-bttn">
                Contact Us
              </a>
            </div>
          </Col>
        </Row>

        {/* Option 3 */}
        <Row className="option d-flex">
          <Col className="col-md">
            <p id="op-1-title">Option 3</p>
            <p id="op-1">
              Send us an invite using email with a link to project documents that we can download,
              review and complete markups and take ups from which we can generate and estimate
              specific for the nominated project.
            </p>
            <div style={{ paddingTop: "2em" }}>
              <a href="/contact-us" id="pg-bttn">
                Contact Us
              </a>
            </div>
          </Col>

          <Col>
            <div className="kh-ad">
              <p className="kh-logo">
                Konstruct<span>Hub</span>
              </p>
              <p id="op-1" className="col-12">
                Konstructhub is a hub for the construction industry, it is built for Designers,
                Developers, Quantity Surveyors, Manufacturers, Builders, Sub-Contractors, Trades.
              </p>
              <a href="https://konstructhub.com">Learn More</a>
            </div>
          </Col>
        </Row>

        {/* Option 4 */}
        <Row className="option d-flex col-5">
          <Col className="col-md">
            <p id="op-1-title">Option 4</p>
            <p id="op-1">
              We will site-measure and provide costs from what we have viewed, measured, and
              discussed. Contact us to organize a measure and quote. A measure and quote are the
              best methods for buildings that are less than 6 weeks away from completion.
            </p>
            <div style={{ paddingTop: "2em" }}>
              <a href="/contact-us" id="pg-bttn">
                Contact Us
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Estimate;
